import { HttpResponseError, serenityFetch } from 'helpers/api';
import { DateTime } from 'luxon';
import type { EngineUsage } from './types';

interface ApiResult {
  assetId: number;
  totalEngineTimeMinutes: number;
  engineUsage: {
    referenceId: string | null;
    start: string;
    end: string | null;
    startLatitude: number;
    startLongitude: number;
    endLatitude: number | null;
    endLongitude: number | null;
  }[];
}

type ApiResultMultipleAssets = ApiResult[];

export const getEngineUsageForAssets = async (
  organisationId: string,
  assetIds: number[],
  from: number,
  until: number,
): Promise<EngineUsage[]> => {
  const params = new URLSearchParams({
    assetIds: assetIds.join(),
    from: DateTime.fromMillis(from).toUTC().toISO(),
    until: DateTime.fromMillis(until).toUTC().toISO(),
  });
  const response = await serenityFetch('GET', `/v2/organisations/${organisationId}/engine-usage?${params}`, null);
  HttpResponseError.detect(response);
  const result = (await response.json()) as ApiResultMultipleAssets;
  const output: EngineUsage[] = [];
  result.forEach(asset => {
    const { assetId } = asset;
    const engineUsages = asset.engineUsage.map(item => {
      const referenceId = item.referenceId;
      const startTime = DateTime.fromISO(item.start, { zone: 'utc' }).toMillis();
      const endTime = item.end ? DateTime.fromISO(item.end, { zone: 'utc' }).toMillis() : undefined;
      const duration = endTime === undefined ? undefined : endTime - startTime;
      const startLatitude = item.startLatitude;
      const startLongitude = item.startLongitude;
      const endLatitude = item.endLatitude;
      const endLongitude = item.endLongitude;
      return { assetId, referenceId, startTime, endTime, duration, startLatitude, startLongitude, endLatitude, endLongitude } as EngineUsage;
    });
    output.push(...engineUsages);
  });
  return output;
};

export const getEngineUsageForAsset = async (
  organisationId: string,
  assetId: number,
  from: number,
  until: number,
): Promise<EngineUsage[]> => {
  const params = new URLSearchParams({
    from: DateTime.fromMillis(from).toUTC().toISO(),
    until: DateTime.fromMillis(until).toUTC().toISO(),
  });
  const response = await serenityFetch(
    'GET',
    `/organisations/${organisationId}/engine-usage/${assetId}?${params}`,
    null,
  );
  HttpResponseError.detect(response);

  return ((await response.json()) as ApiResult).engineUsage.map(item => {
    const startTime = DateTime.fromISO(item.start, { zone: 'utc' }).toMillis();
    const endTime = item.end ? DateTime.fromISO(item.end, { zone: 'utc' }).toMillis() : undefined;
    return {
      assetId,
      startTime,
      endTime,
      duration: endTime === undefined ? undefined : endTime - startTime,
    };
  });
};
