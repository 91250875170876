import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import type React from 'react';
import { Fragment, type ReactNode } from 'react';
import { useTranslations } from 'use-intl';
import type { DimensionFilterState } from './dimensionState';
import { type DimensionTuple, getBody, getColumnHeaders, getDimensionValue, getGridFromData } from './tableHelpers';
import type { ActivitySummaryDimensionKey, ActivitySummaryMetricKey, ActivitySummaryRow } from './types';

interface ActivitySummaryTableViewProps {
  metrics: ActivitySummaryMetricKey[];
  rowDimension: DimensionFilterState;
  columnDimensions: ActivitySummaryDimensionKey[];
  rows: ActivitySummaryRow[];
  renderColumnDimension: (dimensionKey: ActivitySummaryDimensionKey, value: string) => React.ReactNode;
  renderRowDimension: (
    rowDimensionKey: ActivitySummaryDimensionKey,
    dimensions: PartialRecord<ActivitySummaryDimensionKey, string>,
    assetIds: number[],
  ) => React.ReactNode;
  renderMetricValue: (metricKey: ActivitySummaryMetricKey, row: ActivitySummaryRow | undefined) => React.ReactNode;
  renderMetricTotalValue: (
    item: DimensionTuple[],
    metricKey: ActivitySummaryMetricKey,
    rowDimensionKey: ActivitySummaryDimensionKey,
  ) => React.ReactNode;
}

const MetricHeaderCells = ({ metrics }: { metrics: ActivitySummaryMetricKey[] }) => {
  const t = useTranslations('pages.reporting.summary');
  return (
    <>
      {metrics.map(metric => (
        <TableCell key={metric} align="center" sx={{ whiteSpace: 'nowrap' }}>
          {t(`metric.${metric}.name`)}
        </TableCell>
      ))}
    </>
  );
};

const isDefined = <T,>(value: T | undefined): value is T => value !== undefined;

const ActivitySummaryTableView: React.FC<ActivitySummaryTableViewProps> = ({
  metrics,
  rowDimension,
  columnDimensions,
  rows,
  renderColumnDimension,
  renderRowDimension,
  renderMetricValue,
  renderMetricTotalValue,
}) => {
  const t = useTranslations('pages.reporting.summary');

  const dimensions: ActivitySummaryDimensionKey[] = [rowDimension.dimension, ...columnDimensions];

  const dimensionValues = rows.reduce<PartialRecord<ActivitySummaryDimensionKey, Set<string>>>((acc, row) => {
    for (const dimension of dimensions) {
      if (!(dimension in acc)) acc[dimension] = new Set<ActivitySummaryDimensionKey>();
      // biome-ignore lint/style/noNonNullAssertion: checked above
      const set = acc[dimension]!;
      const value = getDimensionValue(row, dimension);
      if (value !== undefined) set.add(value);
    }
    return acc;
  }, {});

  const grid = getGridFromData(rows, dimensions);
  const columnHeaders = getColumnHeaders(grid);
  const body = getBody(rows, grid);

  return (
    <TableContainer sx={{ maxWidth: '100%' }}>
      <Table>
        <colgroup>
          <col />
          {columnDimensions.reduce<ReactNode>(
            (acc, dimension) => {
              const set = dimensionValues[dimension];
              if (!isDefined(set)) return acc;
              const values = [...set];
              return values.map(value => <Fragment key={value}>{acc}</Fragment>);
            },
            metrics.map((metric, index) =>
              index + 1 === metrics.length ? (
                <Box key={metric} component="col" borderRight="1px solid" borderColor="common.midGrey" />
              ) : (
                <col key={metric} />
              ),
            ),
          )}
        </colgroup>
        <TableHead>
          {columnHeaders.map(({ dimension, cells }) => (
            <TableRow key={dimension}>
              <TableCell sx={{ position: 'sticky', left: 0, bgcolor: 'common.white' }} />
              {cells.map(cell => (
                <TableCell key={cell.key} align="center" colSpan={cell.span * metrics.length}>
                  {renderColumnDimension(dimension, cell.value)}
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow>
            <TableCell sx={{ whiteSpace: 'nowrap', position: 'sticky', left: 0, bgcolor: 'common.white' }}>
              {t(`dimension.${rowDimension.dimension}.name`)}
            </TableCell>
            {columnDimensions.reduce<ReactNode>(
              (acc, dimension) => {
                const set = dimensionValues[dimension];
                if (!isDefined(set)) return acc;
                const values = [...set];
                return values.map(value => <Fragment key={value}>{acc}</Fragment>);
              },
              <MetricHeaderCells metrics={metrics} />,
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {body.map(down => (
            <TableRow key={down.cells[0].dimensions[0][1]}>
              <TableCell
                component="th"
                scope="row"
                sx={{ whiteSpace: 'nowrap', position: 'sticky', left: 0, bgcolor: 'common.white' }}
              >
                {renderRowDimension(
                  rowDimension.dimension,
                  down.cells.find(cell => cell.row)!.row!.dimensions,
                  down.assetIds,
                )}
              </TableCell>
              {down.cells.map(cell => (
                <Fragment key={cell.id}>
                  {metrics.map(metric => (
                    <TableCell key={metric} align="center" sx={{ whiteSpace: 'nowrap' }}>
                      {renderMetricValue(metric, cell.row)}
                    </TableCell>
                  ))}
                </Fragment>
              ))}
            </TableRow>
          ))}
        </TableBody>
        <TableBody>
          <TableRow sx={{ 'th, td': { border: 0 } }}>
            <TableCell
              component="th"
              scope="row"
              variant="head"
              sx={{ whiteSpace: 'nowrap', position: 'sticky', left: 0, bgcolor: 'common.white' }}
            >
              Total
            </TableCell>
            {grid[0].map(item => (
              <Fragment
                key={item
                  .slice(1)
                  .map(v => v[1])
                  .join()}
              >
                {metrics.map(metric => (
                  <TableCell key={metric} align="center">
                    {renderMetricTotalValue(item, metric, rowDimension.dimension)}
                  </TableCell>
                ))}
              </Fragment>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ActivitySummaryTableView;
