import type { UseQueryResult } from '@tanstack/react-query';
import type { EngineUsage } from 'apis/rest/engineUsage/types';
import * as d3 from 'd3';
import type { HttpResponseError } from 'helpers/api';
import { useSize } from 'hooks/useSize';
import type React from 'react';
import { useCallback, useRef, useState } from 'react';
import type { Action } from '../types';
import AssetTimelineGraph from './assetTimelineGraph';
import EnginePopper from './enginePopper';
import TripPopper from './tripPopper';

const MARGIN_BOTTOM = 30;

type EngineUsageQuery = UseQueryResult<EngineUsage[], HttpResponseError>;

interface AssetTripSummaryProps {
  trips: Trip[];
  assets: AssetWithDevice[];
  maxMinTimes: [number, number];
  assetHeights: number[];
  dispatch: React.Dispatch<Action>;
  selectedId: string | undefined;
  timezone: string;
  displayEngineUsage: boolean;
  engineUsageQuery: EngineUsageQuery;
}

export const AssetTripTimelineView: React.FC<AssetTripSummaryProps> = ({
  assetHeights,
  trips,
  assets,
  maxMinTimes,
  dispatch,
  selectedId,
  timezone,
  displayEngineUsage,
  engineUsageQuery,
}) => {
  const container = useRef<HTMLDivElement>(null);
  const size = useSize(container);

  const [tripPopper, setTripPopper] = useState<{ trip?: Trip; element?: SVGElement }>({});
  const [enginePopper, setEnginePopper] = useState<{ engineUsage?: EngineUsage; element?: SVGElement }>({});

  const onTripClick = useCallback(
    (id: string | undefined) => dispatch({ type: 'SET_SELECTED_TRIP', tripId: id }),
    [dispatch],
  );

  const onHoverTrip = useCallback((trip: Trip | undefined, element: SVGElement | undefined) => {
    setTripPopper(state => (element ? { trip, element } : { trip: state.trip }));
  }, []);

  const onHoverEngineUsage = useCallback((engineUsage: EngineUsage | undefined, element: SVGElement | undefined) => {
    setEnginePopper(state => (element ? { engineUsage, element } : { engineUsage: state.engineUsage }));
  }, []);

  return (
    <div
      ref={container}
      style={{
        position: 'relative',
        height: `${d3.sum(Object.values(assetHeights)) + MARGIN_BOTTOM}px`,
        width: '100%',
      }}
    >
      <TripPopper
        trip={tripPopper.trip}
        anchorEl={tripPopper.trip ? tripPopper.element : undefined}
        timezone={timezone}
      />
      <EnginePopper
        engineUsage={enginePopper.engineUsage}
        anchorEl={enginePopper.engineUsage ? enginePopper.element : undefined}
        timezone={timezone}
      />
      {size && (
        <AssetTimelineGraph
          size={size}
          trips={trips}
          assets={assets}
          maxMinTimes={maxMinTimes}
          assetHeights={assetHeights}
          selectedId={selectedId}
          timezone={timezone}
          displayEngineUsage={displayEngineUsage}
          engineUsageQuery={engineUsageQuery}
          onTripClick={onTripClick}
          onHoverTrip={onHoverTrip}
          onHoverEngineUsage={onHoverEngineUsage}
        />
      )}
    </div>
  );
};
