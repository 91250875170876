import { ChevronLeft, ChevronRight, DeleteOutlined, PlayArrow, Stop } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteTripsBetween, useSubmitChangeRequest } from 'apis/rest/trips/hooks';
import { tripQueryKeys } from 'apis/rest/trips/queryKeys';
import { type UserTransitionToAdd, getReportsAfter, getReportsBefore } from 'apis/rest/trips/requests';
import { useNotification } from 'contexts/notification/useNotification';
import useOrganisationId from 'hooks/session/useOrganisationId';
import usePermissions from 'hooks/session/usePermissions';
import useSnackbar from 'hooks/useSnackbar';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import type { Action } from '../types';
import { TripCharts } from './tripCharts';
import { sortUserTransitions, validateUserTransitions } from './userTransitions';

interface Props {
  sourceTrip: Trip;
  sourceAsset: AssetBasic;
  onChangeRequest: (cr: Action['changeRequest']) => void;
  timezone: string;
}

export const TripUpdateDialog = ({ sourceTrip, sourceAsset, onChangeRequest, timezone }: Props) => {
  const addTransitions = useSubmitChangeRequest();
  const deleteTrips = useDeleteTripsBetween();
  const organisationId = useOrganisationId();
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const permissions = usePermissions();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [hoveredReport, setHoveredReport] = useState<number>();
  const [selectedReport, setSelectedReport] = useState<number>();
  const [loadingMoreReports, setLoadingMoreReports] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [trip, setTrip] = useState<Trip>(sourceTrip);
  const [userTransitions, setUserTransitions] = useState<UserTransitionToAdd[]>([]);

  const successHandler = useCallback(() => {
    snackbar.display({ type: 'success', text: 'Trip change request fulfilled', id: 'tripChangeRequestCompleted' });
    queryClient.invalidateQueries({ queryKey: tripQueryKeys.trips(organisationId) });
  }, [snackbar, queryClient, organisationId]);

  const failureHandler = useCallback(() => {
    snackbar.display({ type: 'error', text: 'Trip change request failed', id: 'tripChangeRequestFailed' });
  }, [snackbar]);

  useNotification('tripChangeRequestFinished', successHandler);
  useNotification('tripChangeRequestFailed', failureHandler);

  useEffect(() => {
    setTrip(sourceTrip);
  }, [sourceTrip]);

  const loadEarlierReports = useCallback(() => {
    setLoadingMoreReports(true);
    getReportsBefore(organisationId, sourceAsset.id, Math.min(...trip.reports.map(r => r.timeOfFix))).then(reports => {
      setTrip(oldTrip => {
        const newReports = [...oldTrip.reports, ...reports].sort((a, b) => b.timeOfFix - a.timeOfFix);
        return { ...oldTrip, reports: newReports };
      });
      setLoadingMoreReports(false);
    });
  }, [organisationId, sourceAsset.id, trip.reports]);

  const loadLaterReports = useCallback(() => {
    setLoadingMoreReports(true);
    getReportsAfter(organisationId, sourceAsset.id, Math.max(...trip.reports.map(r => r.timeOfFix))).then(reports => {
      setTrip(oldTrip => {
        const newReports = [...oldTrip.reports, ...reports].sort((a, b) => b.timeOfFix - a.timeOfFix);
        return { ...oldTrip, reports: newReports };
      });
      setLoadingMoreReports(false);
    });
  }, [organisationId, sourceAsset.id, trip.reports]);

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const onChartClick = useCallback(() => {
    if (hoveredReport) {
      setSelectedReport(hoveredReport);
    }
  }, [hoveredReport]);

  const selectedReportFull = useMemo(
    () => trip.reports.find(r => r.id === selectedReport),
    [trip.reports, selectedReport],
  );

  const setSelectedAsTransition = useCallback(
    (toState: 'ACTIVE' | 'INACTIVE') => {
      if (!selectedReportFull) {
        return;
      }
      setSelectedReport(undefined);
      setUserTransitions(prev =>
        sortUserTransitions([
          ...prev,
          { assetId: sourceAsset.id, reportTime: selectedReportFull.timeOfFix, toState, retrospective: false },
        ]),
      );
    },
    [selectedReportFull, sourceAsset],
  );

  const transitionsAreValid = validateUserTransitions(userTransitions);

  const submitTransitions = useCallback(() => {
    if (transitionsAreValid) {
      setSubmitting(true);
      addTransitions.mutate(userTransitions, {
        onSuccess: () => {
          onChangeRequest({ transitions: userTransitions, assetId: sourceAsset.id });
          setSubmitting(false);
          setDialogOpen(false);
        },
        onError: () => {
          setSubmitting(false);
          snackbar.display({ type: 'error', id: 'tripChangeFailed', text: 'Failed to submit change request.' });
        },
      });
    }
  }, [transitionsAreValid, addTransitions, userTransitions, onChangeRequest, sourceAsset.id, snackbar]);

  const deleteTrip = useCallback(() => {
    if (trip.endTime === undefined) return;
    setSubmitting(true);
    deleteTrips.mutate(
      { assetId: sourceAsset.id, start: trip.startTime, end: trip.endTime },
      {
        onSuccess: () => {
          setSubmitting(false);
          setDialogOpen(false);
        },
        onError: () => {
          setSubmitting(false);
          snackbar.display({ type: 'error', id: 'tripChangeFailed', text: 'Failed to submit change request.' });
        },
      },
    );
  }, [deleteTrips, sourceAsset.id, trip.startTime, trip.endTime, snackbar]);

  const removeUserTransitions = useCallback(() => setUserTransitions([]), []);
  const openDialog = useCallback(() => {
    setDialogOpen(true);
    removeUserTransitions();
  }, [removeUserTransitions]);

  const t = useTranslations('pages.reporting');

  return (
    <>
      <div>
        <Button
          size="small"
          variant="outlined"
          color="error"
          onClick={openDialog}
          disabled={trip.status?.startsWith('Approved') || !permissions.canEditAssets}
        >
          {t('trips.fixIncorrect')}
        </Button>
      </div>
      <Dialog open={dialogOpen} fullWidth maxWidth="lg">
        <DialogTitle>{t('trips.update')}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography>{t('trips.editDialogText')}</Typography>
            <Stack direction="row" spacing={2} height="4rem">
              <Button
                variant="outlined"
                disabled={loadingMoreReports}
                onClick={loadEarlierReports}
                startIcon={<ChevronLeft />}
              >
                {t('trips.loadEarlier')}
              </Button>
              <Button
                variant="outlined"
                disabled={loadingMoreReports}
                onClick={loadLaterReports}
                endIcon={<ChevronRight />}
              >
                {t('trips.loadLater')}
              </Button>
              <Button variant="outlined" onClick={removeUserTransitions} endIcon={<DeleteOutlined />}>
                {t('trips.clearEdits')}
              </Button>
              <Box flex={1} />
              {selectedReportFull && (
                <>
                  <Stack alignItems="center" direction="row" spacing={2}>
                    <Typography variant="h5">
                      Mark{' '}
                      {DateTime.fromMillis(selectedReportFull.timeOfFix)
                        .setZone(timezone)
                        .toISOTime({ includeOffset: false, suppressMilliseconds: true })}{' '}
                      as a
                    </Typography>
                  </Stack>
                  <Button
                    variant="outlined"
                    onClick={() => setSelectedAsTransition('ACTIVE')}
                    startIcon={<PlayArrow />}
                  >
                    {t('trips.start')}
                  </Button>
                  <Button variant="outlined" onClick={() => setSelectedAsTransition('INACTIVE')} startIcon={<Stop />}>
                    {t('trips.end')}
                  </Button>
                </>
              )}
            </Stack>
            <Box onClick={onChartClick} sx={{ cursor: 'pointer' }}>
              <TripCharts
                timezone={timezone}
                trip={trip}
                asset={sourceAsset}
                selectedReportId={selectedReport ?? hoveredReport}
                setSelectedReportId={setHoveredReport}
                showSwitches={false}
                userTransitions={userTransitions}
                showTripBoundaries
              />
            </Box>
            <Stack direction="row" spacing={4}>
              <Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                  <svg height={2} width={24}>
                    <line x2={24} stroke="#0c0" strokeWidth={2} />
                  </svg>
                  <Typography>{t('trips.enteredStart')}</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                  <svg height={2} width={24}>
                    <line x2={24} stroke="#f00" strokeWidth={2} />
                  </svg>
                  <Typography>{t('trips.enteredEnd')}</Typography>
                </Stack>
              </Stack>
              <Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                  <svg height={2} width="24">
                    <line x2={24} stroke="#0c0" strokeWidth={2} strokeDasharray="8" />
                  </svg>
                  <Typography>
                    {t('trips.detectedStart')} ({trip.startReason})
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" spacing={2}>
                  {/* biome-ignore lint/a11y/noSvgWithoutTitle: <explanation> */}
                  <svg height={2} width="24">
                    <line x2={24} stroke="#f00" strokeWidth={2} strokeDasharray="8" />
                  </svg>
                  <Typography>
                    {t('trips.detectedEnd')} ({trip.endReason})
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={theme => ({ p: 3, borderTop: theme.border.default })}>
          <Box
            display="grid"
            gridTemplateColumns={{ xs: '1fr max-content max-content' }}
            gridTemplateAreas={{ xs: '"delete cancel submit"' }}
            gap={3}
            height="4rem"
            width="100%"
          >
            <Button
              sx={{ gridArea: 'delete', justifySelf: 'start', minWidth: '10rem' }}
              variant="outlined"
              disabled={submitting}
              onClick={deleteTrip}
              color="error"
            >
              {submitting ? <CircularProgress size="2rem" color="secondary" /> : t('trips.delete')}
            </Button>
            <Button sx={{ gridArea: 'cancel', minWidth: '10rem' }} variant="outlined" onClick={closeDialog}>
              {t('trips.cancel')}
            </Button>
            <Button
              sx={{ gridArea: 'submit', minWidth: '10rem' }}
              variant="contained"
              disabled={!transitionsAreValid || submitting}
              onClick={submitTransitions}
            >
              {submitting ? <CircularProgress size="2rem" color="secondary" /> : t('trips.submit')}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};
