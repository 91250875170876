import type { BaseAssetGroup } from './transforms';
import type { ActivitySummaryDimensionKey } from './types';

interface ActivitySummaryRowDimensionState<T> {
  dimension: ActivitySummaryDimensionKey;
  items: T[];
  has(item: T): boolean;
  add(item: T): ActivitySummaryRowDimensionState<T>;
  remove(item: T): ActivitySummaryRowDimensionState<T>;
}

export class AssetFilterState implements ActivitySummaryRowDimensionState<number> {
  readonly dimension = 'assetId';
  items: number[];
  groupItems: number[];
  constructor(assetIds: number[], assetGroupIds: number[]) {
    this.items = assetIds;
    this.groupItems = assetGroupIds;
  }
  has(assetId: number): boolean {
    return this.items.includes(assetId);
  }
  add(assetId: number): AssetFilterState {
    const items = new Set(this.items);
    items.add(assetId);
    return new AssetFilterState([...items], this.groupItems);
  }
  remove(assetId: number): AssetFilterState {
    const items = new Set(this.items);
    items.delete(assetId);
    return new AssetFilterState([...items], this.groupItems);
  }
  hasGroup(assetGroupId: number): boolean {
    return this.groupItems.includes(assetGroupId);
  }
  addGroup(assetGroupId: number): AssetFilterState {
    const groupItems = new Set(this.groupItems);
    groupItems.add(assetGroupId);
    return new AssetFilterState(this.items, [...groupItems]);
  }
  removeGroup(assetGroupId: number): AssetFilterState {
    const groupItems = new Set(this.groupItems);
    groupItems.delete(assetGroupId);
    return new AssetFilterState(this.items, [...groupItems]);
  }
  getAssetIds(assetGroups: BaseAssetGroup[]): number[] {
    const groupAssetIds = assetGroups.flatMap(group => {
      if (this.hasGroup(group.id)) return group.assets.map(asset => asset.id);
      return [];
    });
    const set = new Set([...groupAssetIds, ...this.items]);
    return [...set].sort();
  }
}

export class AssetGroupFilterState implements ActivitySummaryRowDimensionState<number> {
  readonly dimension = 'assetGroupId';
  items: number[];
  constructor(assetGroupIds: number[]) {
    this.items = assetGroupIds;
  }
  has(assetGroupId: number): boolean {
    return this.items.includes(assetGroupId);
  }
  add(assetGroupId: number): AssetGroupFilterState {
    if (this.has(assetGroupId)) return this;
    return new AssetGroupFilterState([...this.items, assetGroupId]);
  }
  remove(assetGroupId: number): AssetGroupFilterState {
    if (!this.has(assetGroupId)) return this;
    return new AssetGroupFilterState(this.items.filter(item => item !== assetGroupId));
  }
  getAssetIds(assetGroups: BaseAssetGroup[]): number[] {
    const groupAssetIds = assetGroups.flatMap(group => {
      if (this.has(group.id)) return group.assets.map(asset => asset.id);
      return [];
    });
    const set = new Set(groupAssetIds);
    return [...set].sort();
  }
}

interface MakeModel {
  make: string;
  model: string;
}

export class AssetModelFilterState implements ActivitySummaryRowDimensionState<MakeModel> {
  readonly dimension = 'assetModel';
  items: MakeModel[];
  constructor(makeModels: MakeModel[]) {
    this.items = makeModels;
  }
  has(makeModel: MakeModel): boolean {
    return this.items.some(item => item.make === makeModel.make && item.model === makeModel.model);
  }
  add(makeModel: MakeModel): AssetModelFilterState {
    if (this.has(makeModel)) return this;
    return new AssetModelFilterState([...this.items, makeModel]);
  }
  remove(makeModel: MakeModel): AssetModelFilterState {
    if (!this.has(makeModel)) return this;
    const items = this.items.filter(item => !(item.make === makeModel.make && item.model === makeModel.model));
    return new AssetModelFilterState(items);
  }
  get makes() {
    const makes = new Set(this.items.map(item => item.make));
    return [...makes].sort();
  }
  get models() {
    const models = new Set(this.items.map(item => item.model));
    return [...models].sort();
  }
}

export class AssetOwnerFilterState implements ActivitySummaryRowDimensionState<string> {
  readonly dimension = 'ownerId';
  items: string[];
  constructor(owners: string[]) {
    this.items = owners;
  }
  has(owner: string): boolean {
    return this.items.includes(owner);
  }
  add(owner: string): AssetOwnerFilterState {
    if (this.has(owner)) return this;
    return new AssetOwnerFilterState([...this.items, owner]);
  }
  remove(owner: string): AssetOwnerFilterState {
    if (!this.has(owner)) return this;
    return new AssetOwnerFilterState(this.items.filter(item => item !== owner));
  }
}

export class AssetCategoryFilterState implements ActivitySummaryRowDimensionState<string> {
  readonly dimension = 'category';
  items: string[];
  constructor(categories: string[]) {
    this.items = categories;
  }
  has(category: string): boolean {
    return this.items.includes(category);
  }
  add(category: string): AssetCategoryFilterState {
    if (this.has(category)) return this;
    return new AssetCategoryFilterState([...this.items, category]);
  }
  remove(category: string): AssetCategoryFilterState {
    if (!this.has(category)) return this;
    return new AssetCategoryFilterState(this.items.filter(item => item !== category));
  }
}

export class AssetIcaoTypeDesignatorFilterState implements ActivitySummaryRowDimensionState<string> {
  readonly dimension = 'icaoTypeDesignator';
  items: string[];
  constructor(designators: string[]) {
    this.items = designators;
  }
  has(typeDesignator: string): boolean {
    return this.items.includes(typeDesignator);
  }
  add(typeDesignator: string): AssetIcaoTypeDesignatorFilterState {
    if (this.has(typeDesignator)) return this;
    return new AssetIcaoTypeDesignatorFilterState([...this.items, typeDesignator]);
  }
  remove(typeDesignator: string): AssetIcaoTypeDesignatorFilterState {
    if (!this.has(typeDesignator)) return this;
    return new AssetIcaoTypeDesignatorFilterState(this.items.filter(item => item !== typeDesignator));
  }
}

export type DimensionFilterState =
  | AssetFilterState
  | AssetGroupFilterState
  | AssetModelFilterState
  | AssetOwnerFilterState
  | AssetCategoryFilterState
  | AssetIcaoTypeDesignatorFilterState;
