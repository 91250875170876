import React, { useEffect, useMemo, useState } from 'react';
import { Button, CircularProgress, Grid, MenuItem, TextField } from '@mui/material';
import DetailPanel from 'components/shared/DetailPanel';
import { displaySnackbar } from 'slices/app.slice';
import { useAppDispatch } from 'store/useAppDispatch';
import { codes, data as currencyData } from 'currency-codes';
import { v4 as uuidv4 } from 'uuid';
import { useAddOrganisationCurrencyMetadata, useGetOrganisationCurrencyMetadata, useUpdateOrganisationDetails } from 'apis/rest/organisations/hooks';
import useStyles from 'components/pages/organisationSettings/organisation/members/members-styles';
import { useTranslations } from 'use-intl';

export type OrganisationCurrencyMetadataDetails = {'currencyCode': string};

const validateCurrency = (organisationCurrency: Partial<OrganisationCurrencyMetadataDetails>) => 
  (organisationCurrency.currencyCode ? codes().indexOf(organisationCurrency.currencyCode) : false);

const availableCurrencyCodes = currencyData.filter((currency) => ['USD', 'GBP', 'NZD', 'CAD', 'EUR', 'AUD'].includes(currency.code));

interface OrganisationMetadataProps {
  organisationId: string;
  canEdit: boolean;
}

const OrganisationMetadata: React.FC<OrganisationMetadataProps> = ({organisationId, canEdit}) => {
  const classes = useStyles();
  const t = useTranslations('pages.organisationSettings.metadata');
  const dispatch = useAppDispatch();
  const { data: organisationCurrencyMetadata, isPending: currencyPending } = useGetOrganisationCurrencyMetadata();
  const addCurrencyCodeMutation = useAddOrganisationCurrencyMetadata();
  const saveOrganisationMetadataDetails = (organisationCurrencyMetadataDetails: OrganisationCurrencyMetadataDetails) => {
    addCurrencyCodeMutation.mutate({
      id: uuidv4(),
      organisationId: organisationId,
      currency: organisationCurrencyMetadataDetails.currencyCode,
      validFrom: new Date().toISOString()
    },
    {
      onError() {
        dispatch(
          displaySnackbar({
            id: 'currencyMetadataDetailsFailedToSave',
            text: t('failedToSave'),
            type: 'error',
          }),
        );
      },
      onSuccess() {
        displaySnackbar({
          id: 'currencyMetadataDetailsSaved',
          text: t('saved'),
          type: 'success',
        });
      },
    })
  };
  const isSaving = addCurrencyCodeMutation.isPending;
  const [organisationCurrencyMetadataDetails, setOrganisationCurrencyDetails] = useState<OrganisationCurrencyMetadataDetails>({
    currencyCode: organisationCurrencyMetadata ? organisationCurrencyMetadata[0]?.currency : '',
  });
  useEffect(() => {
    setOrganisationCurrencyDetails({
      currencyCode: organisationCurrencyMetadata ? organisationCurrencyMetadata[0]?.currency : '',
    });
  }, [organisationCurrencyMetadata])

  const onOrganisationMetadataDetailsChange = (partial: Partial<OrganisationCurrencyMetadataDetails>) => {
    if (validateCurrency(partial)) {
      setOrganisationCurrencyDetails({ ...organisationCurrencyMetadataDetails, ...partial });
    }
    else {
      setOrganisationCurrencyDetails({currencyCode: 'USD'});
    }
  };
  const canSave = useMemo(
    () => organisationCurrencyMetadataDetails.currencyCode === organisationCurrencyMetadata?.[0]?.currency,
    [organisationCurrencyMetadataDetails.currencyCode, organisationCurrencyMetadata]
  );
  return (
    <DetailPanel px={3} pb={4} pt={4} mb={3}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <TextField
              select
              label={t('currency')}
              value={organisationCurrencyMetadataDetails.currencyCode ?? ''} 
              onChange={e => onOrganisationMetadataDetailsChange({ currencyCode: e.target.value })}
              disabled={currencyPending}
              InputProps={{endAdornment: (currencyPending && (<CircularProgress/>))}}
              fullWidth
            >
              {availableCurrencyCodes.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.currency} ({option.code})
                </MenuItem>
              ))}
            </TextField>
          </Grid>
            <Grid item sm={12} md={12}>
              <Button
                className={classes.inputButton}
                variant="contained"
                onClick={e => {
                  e.preventDefault();
                  saveOrganisationMetadataDetails(organisationCurrencyMetadataDetails);
                }}
                disabled={isSaving || !canEdit || canSave}
                sx={{ minWidth: '10rem' }}
              >
                {t(isSaving ? 'saving' : 'save')}
              </Button>
            </Grid>
        </Grid>
    </DetailPanel>
  );
};

export default OrganisationMetadata;
