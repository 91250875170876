import type React from 'react';
import { useTranslations } from 'use-intl';
import type { ActivitySummaryDimensionKey } from './types';

interface ActivitySummaryColumnHeadingProps {
  dimensionKey: ActivitySummaryDimensionKey;
  value: string;
  owners: { id: string; name: string }[];
  categories: { name: string }[];
}

const ActivitySummaryColumnHeading: React.FC<ActivitySummaryColumnHeadingProps> = ({
  dimensionKey,
  value,
  owners,
  categories,
}) => {
  const t = useTranslations('pages.reporting.summary');

  if (dimensionKey === 'ownerId') {
    // TODO: remove case insensitive matching
    const owner = owners.find(({ id }) => id.toLowerCase() === value.toLowerCase());
    return <>{owner?.name ?? value}</>;
  }

  if (dimensionKey === 'category') {
    const category = categories.find(c => c.name.toLowerCase() === value.toLowerCase());
    return <>{category?.name ?? value}</>;
  }

  if (dimensionKey === 'billable') return <>{t('dimension.billable.columnHeading', { value: value.toLowerCase() })}</>;

  if (dimensionKey === 'icaoTypeDesignator') return <>{value}</>;

  return (
    <>
      {dimensionKey}: {value}
    </>
  );
};

export default ActivitySummaryColumnHeading;
