import { HttpResponseError, serenityFetch } from 'helpers/api';

export const getOrganisations = async (): Promise<Organisation[]> => {
  const response = await serenityFetch('GET', '/organisations', null);
  HttpResponseError.detect(response);
  return (await response.json()).organisations;
}

export const getAllOrganisations = async (): Promise<Organisation[]> => {
  const response = await serenityFetch('GET', '/organisations/all', null);
  HttpResponseError.detect(response);
  return (await response.json()).organisations;
};

export const getMembershipsForCurrentUser = async (): Promise<Membership[]> => {
  const response = await serenityFetch('GET', '/memberships', null);
  HttpResponseError.detect(response);
  return (await response.json()).memberships;
};

export const getMemberships = async (organisationId: string): Promise<Membership[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/memberships`, null);
  HttpResponseError.detect(response);
  return (await response.json()).memberships;
};

export const getMembership = async (organisationId: string, userId: string): Promise<Membership> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/memberships/${userId}`, null);
  HttpResponseError.detect(response);
  return response.json();
};

export const leaveOrganisation = async (organisationId: string, userId: string): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/memberships/${userId}`, null);
  HttpResponseError.detect(response);
};

export const joinOrganisationOrUpdateRole = async (
  organisationId: string,
  userId: string,
  role: MembershipRole,
): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/memberships/${userId}`, { role });
  HttpResponseError.detect(response);
};
