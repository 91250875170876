import { type UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { membershipQueryKeys } from './queryKeys';
import {
  getAllOrganisations,
  getMembership,
  getMemberships, getOrganisations,
  joinOrganisationOrUpdateRole,
  leaveOrganisation,
} from './requests';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetOrganisations = () => {
  const queryKey = membershipQueryKeys.allOrganisations();
  const query = useQuery({
    queryKey,
    queryFn: () => getOrganisations(),
  });
  return { query, queryKey };
}

export const useGetAllOrganisations = () => {
  const queryKey = membershipQueryKeys.allOrganisations();
  const query = useQuery({
    queryKey,
    queryFn: () => getAllOrganisations(),
  });
  return { query, queryKey };
};

export const useGetMemberships = (options?: Options<Membership[], Membership[]>) => {
  const organisationId = useOrganisationId();
  const queryKey = membershipQueryKeys.list(organisationId);
  const query = useQuery({
    queryKey,
    queryFn: () => getMemberships(organisationId),
    ...options,
  });
  return { query, queryKey };
};

export const useGetMembership = (userId: string) => {
  const organisationId = useOrganisationId();
  const queryKey = membershipQueryKeys.detail(organisationId, userId);
  const query = useQuery({
    queryKey,
    queryFn: () => getMembership(organisationId, userId),
  });
  return { query, queryKey };
};

export const useLeaveOrganisation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: Pick<Membership, 'userId' | 'organisationId'>) =>
      leaveOrganisation(value.organisationId, value.userId),
    onMutate: async value => {
      const listsQueryKey = membershipQueryKeys.list(value.organisationId);
      await queryClient.cancelQueries({ queryKey: listsQueryKey });
      const previousMemberships = queryClient.getQueryData<Membership[]>(listsQueryKey);
      if (previousMemberships?.some(g => g.userId === value.userId)) {
        queryClient.setQueryData<Membership[]>(listsQueryKey, () =>
          previousMemberships.filter(g => g.userId !== value.userId),
        );
      }

      return { previousMemberships };
    },
    onError: (_, value, context) => {
      if (context?.previousMemberships) {
        queryClient.setQueryData(membershipQueryKeys.list(value.organisationId), context.previousMemberships);
      }
    },
    onSettled: (_, __, variables) =>
      Promise.all([queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all(variables.organisationId) })]),
  });
};

export const useJoinOrganisationOrUpdateRole = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (value: Pick<Membership, 'userId' | 'role' | 'organisationId'>) =>
      joinOrganisationOrUpdateRole(value.organisationId, value.userId, value.role),
    onMutate: async value => {
      const listsQueryKey = membershipQueryKeys.list(value.organisationId);
      await queryClient.cancelQueries({ queryKey: listsQueryKey });
      const previousMemberships = queryClient.getQueryData<Membership[]>(listsQueryKey);
      if (previousMemberships?.some(g => g.userId === value.userId)) {
        queryClient.setQueryData<Membership[]>(listsQueryKey, () =>
          previousMemberships.map(g => (g.userId === value.userId ? { ...g, ...value } : g)),
        );
      }

      return { previousMemberships };
    },
    onError: (_, value, context) => {
      if (context?.previousMemberships) {
        queryClient.setQueryData(membershipQueryKeys.list(value.organisationId), context.previousMemberships);
      }
    },
    onSettled: (_, __, variables) =>
      Promise.all([queryClient.invalidateQueries({ queryKey: membershipQueryKeys.all(variables.organisationId) })]),
  });
};
