import { HttpResponseError, serenityFetch } from 'helpers/api';
import { DeviceAssetPair, ListResponse, ProvisionState, PublishResponse } from './types';


export const getDevicesAvailableForActivation = async (organisationId: string): Promise<ListResponse<DeviceAssetPair>> => {
  const url = `/organisations/${organisationId}/DeviceProvision/readyToActivate`;
  const response = await serenityFetch('GET', url, null);
  HttpResponseError.detect(response);
  return await response.json() as ListResponse<DeviceAssetPair>;
};

export const activateDevice = async (
  organisationId: string,
  deviceId: number,
): Promise<PublishResponse> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/DeviceProvision/device/${deviceId}/activate`, null);
  HttpResponseError.detect(response);
  return await response.json() as PublishResponse;
};

export const getProvisionState = async (requestId: string): Promise<ProvisionState> => {
  const url = `/deviceProvision/requests/${requestId}`;
  const response = await serenityFetch('GET', url, null);
  HttpResponseError.detect(response);
  return await response.json() as ProvisionState;
};
