import { organisationQueryKeys } from "./queryKeys";

import { useMutation, useQuery, useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import useOrganisationId from "hooks/session/useOrganisationId";
import { addOrganisationCurrencyMetadata, getOrganisationCurrencyMetadata, getOrganisationDetails, updateOrganisationDetails } from "./request";
import { OrganisationUpdateDetails } from "./types";
import { HttpResponseError } from "helpers/api";

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetOrganisationDetails = () => {
  const organisationId = useOrganisationId();
  const queryKey = organisationQueryKeys.details(organisationId);
  const query = useQuery({
    queryKey,
    queryFn: () => getOrganisationDetails(organisationId),
  });
  return { query, queryKey };
};

export const useGetOrganisationCurrencyMetadata = <T = OrganisationCurrency[]>(options: Options<OrganisationCurrency[], T> = {}) => {
  const organisationId = useOrganisationId();
  const queryKey = organisationQueryKeys.metadata(organisationId, 'currency');
  return useQuery({
    queryKey,
    queryFn: () => getOrganisationCurrencyMetadata(organisationId),
    ...options
  });
}

export const useAddOrganisationCurrencyMetadata = () => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (value: OrganisationCurrencyAdd) => addOrganisationCurrencyMetadata(organisationId, value),
    onSuccess: () => queryClient.invalidateQueries({queryKey: organisationQueryKeys.metadata(organisationId, 'currency')}),
    mutationKey: ['addOrganisationMetadata'],
  });
}

export const useUpdateOrganisationDetails = (onSuccess?: () => void, onError?: () => void) => {
  const organisationId = useOrganisationId();
  const queryClient = useQueryClient();
  const query = useMutation({
    mutationFn: (organisation: OrganisationUpdateDetails) => updateOrganisationDetails(organisationId, organisation),
    onMutate: (organisation) => {
      const previousOrganisation = queryClient.getQueryData(organisationQueryKeys.details(organisationId)) as Organisation;
      queryClient.setQueryData(organisationQueryKeys.details(organisationId), (old: Organisation) => ({
        ...old,
        ...organisation,
      }));
      return { previousOrganisation };
    },
    onSuccess: () => {
      onSuccess?.();
    },
    onError: (error, organisation, context) => {
      const { previousOrganisation } = context as { previousOrganisation: Organisation };
      queryClient.setQueryData(organisationQueryKeys.details(organisationId), previousOrganisation);
      onError?.();
    },
  });
  return { query };
};
