import React, { useState } from 'react';
import { Block as BlockIcon, Delete as DeleteIcon } from '@mui/icons-material';
import insensitiveSort from 'utils/insensitiveSort';
import { IconButton, Tooltip, Typography, Box } from '@mui/material';
import useTranslation from 'hooks/useTranslation';
import Select from 'components/shared/forms/inputs/select';
import MaterialTable from '@material-table/core';
import ConfirmDialog from 'components/shared/confirmDialog';
import StaffIndicator from 'components/shared/staffIndicator';
import tableIcons from 'components/shared/icons/tableIcons';
import DetailPanel from 'components/shared/DetailPanel';
import { useCancelInvite, useGetInvites } from 'apis/rest/invites/hook';
import { useGetMemberships, useJoinOrganisationOrUpdateRole, useLeaveOrganisation } from 'apis/rest/memberships/hook';
import useOrganisationId from 'hooks/session/useOrganisationId';
import useStyles from './members-styles';
import { useAppDispatch } from 'store/types';
import { displaySnackbar } from 'slices/app.slice';
import { User } from '@tracplus/serenity-client';

/* case insensitive search/filter for the rows, using fields on memberRow */
const customFilterAndSearch = (term: any, memberRow: any, fieldA: any, fieldB: any) => {
  let field;
  if (memberRow.user) {
    field = memberRow.user[fieldA];
  } else {
    field = memberRow[fieldB];
  }
  if (!field) field = '';
  return field.toLowerCase().indexOf(term.toLowerCase()) > -1;
};

/* sorts on role, comparing against a fixed value if the row contains a non user object */
const customRoleSort = (memberRowA: any, memberRowB: any, nonRoleText: any) => {
  let valueA = nonRoleText;
  let valueB = nonRoleText;

  if (memberRowA?.role) {
    valueA = memberRowA.role;
  }
  if (memberRowB?.role) {
    valueB = memberRowB.role;
  }

  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
};

/* filters on role, comparing against a fixed value if the row contains a non user object */
const customRoleFilterSearch = (term: any, memberRow: any, nonRoleText: any) => {
  let value = nonRoleText;
  if (memberRow?.role) {
    value = memberRow.role;
  }
  return value.toLowerCase().indexOf(term.toLowerCase()) > -1;
};

const deleteAction = (member: Membership, organisation: Organisation, t: any, doRemoveMember: any, showDialog: any) => (
  <Tooltip title={t('deleteButton')} placement="top">
    <span>
      <IconButton
        aria-label="delete"
        size="small"
        disabled={!member.role}
        onClick={() => {
          showDialog(t('removeUserFromOrganisation'), t('removeUserDialogText', { userName: member.name, organisationName: organisation.name }), () => {
            doRemoveMember(member.userId);
          });
        }}
      >
        <DeleteIcon />
      </IconButton>
    </span>
  </Tooltip>
);

const cancelInviteAction = (member: Membership, organisation: Organisation, t: any, doCancelInvite: any, showDialog: any) => (
  <Tooltip title={t('cancelInviteButton')} placement="top">
    <IconButton
      aria-label="cancel"
      size="small"
      onClick={() => {
        showDialog(t('cancelInviteToOrganisation'), t('cancelInviteDialogText', { name: member.name, email: member.email, organisationName: organisation.name }), () => {
          doCancelInvite(member.inviteId);
        });
      }}
    >
      <BlockIcon />
    </IconButton>
  </Tooltip>
);

type OrganisationMembersProps = {
  user: User;
  organisation: Organisation;
  permissions: {
    canEditPermissions: boolean;
  };
};

const OrganisationMembers = ({
  user,
  organisation,
  permissions,
}: OrganisationMembersProps) => {
  const dispatch = useAppDispatch();
  const t = useTranslation('pages.organisationSettings');
  const classes = useStyles();
  const getMemberships = useGetMemberships().query;
  const getInvites = useGetInvites().query;
  const organisationId = useOrganisationId();

  //
  // Update Role
  //
  const updateRole = useJoinOrganisationOrUpdateRole();

  const doUpdateRole = (userId: string, role: MembershipRole) => {
    updateRole.mutate(
      { userId, role, organisationId },
      {
        onError: () => {
          dispatch(displaySnackbar({ id: 'roleUpdateError', text: t('roleUpdateError'), type: 'error' }));
        },
        onSuccess: () => {
          dispatch(displaySnackbar({ id: 'roleUpdated', text: t('roleUpdated'), type: 'success' }));
        }
      }
    );
  };

  //
  // Remove User
  //
  const removeMember = useLeaveOrganisation();

  const doRemoveMember = (userId: string) => {
    removeMember.mutate(
      { userId, organisationId },
      {
        onError: () => {
          dispatch(displaySnackbar({ id: 'userRemovedError', text: t('userRemovedError'), type: 'error' }));
        },
        onSuccess: () => {
          dispatch(displaySnackbar({ id: 'userRemoved', text: t('userRemoved'), type: 'success' }));
        }
      }
    );
  };

  //
  // Cancel Invite
  //
  const cancelInvite = useCancelInvite();
  const doCancelInvite = (inviteId: string) => cancelInvite.mutate(
    { inviteId },
    {
      onError: () => {
        dispatch(displaySnackbar({ id: 'userRemovedError', text: t('userRemovedError'), type: 'error' }));
      },
      onSuccess: () => {
        dispatch(displaySnackbar({ id: 'userRemoved', text: t('userRemoved'), type: 'success' }));
      }
    }
  );

  // variables for confirm dialog
  const [dialog, setDialog] = useState({
    show: false,
    title: '',
    message: '',
    confirmFunc: null
  });

  const showConfirmDialog = (title: string, message: string, confirmFunc: () => void) => {
    setDialog(
      {
        ...dialog,
        message,
        title,
        confirmFunc,
        show: true
      }
    );
  };

  return (
    <>
      <DetailPanel pt={0} pb={0} className={classes.materialTable}>
        <MaterialTable<Membership>
          icons={tableIcons}
          isLoading={getMemberships.isLoading || getInvites.isLoading}
          data={getMemberships.isSuccess && getInvites.isSuccess ? getMemberships.data.concat(getInvites.data).filter(m => m.email) : []}
          options={{
            draggable: false,
            showTitle: false,
            search: true,
            actionsColumnIndex: -1,
            searchFieldStyle: {
              borderRadius: '4px',
              paddingLeft: '18px',
              paddingRight: '10px'
            },
            searchFieldVariant: 'outlined',
            thirdSortClick: false,
            emptyRowsWhenPaging: false,
            headerStyle: { position: 'sticky', top: 0 },
            maxBodyHeight: '550px',
            paging: false,
          }}
          // every part of the table supports localization
          localization={{
            body: {
              emptyDataSourceMessage: t('noUsersFound')
            },
            toolbar: {
              searchTooltip: t('search'),
              searchPlaceholder: t('search')
            },
            pagination: {
              labelRows: t('rows'),
              labelDisplayedRows: ` {from}-{to} ${t('of')} {count}`,
              firstTooltip: t('firstPage'),
              previousTooltip: t('previousPage'),
              nextTooltip: t('nextPage'),
              lastTooltip: t('lastPage')
            }
          }}
          columns={[
            {
              title: t('tableName'),
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              render: m => (
                <div>
                  {m.name}
                  <StaffIndicator user={m} overrideStyle={{ width: '1.6rem', height: '1.6rem' }} />
                </div>
              ),
              searchable: true,
              customFilterAndSearch: (term: any, m: any) => customFilterAndSearch(term, m, 'name', 'name'),
              customSort: (a: any, b: any) => insensitiveSort(a.name, b.name),
            },
            ...(permissions.canEditPermissions ? [{
              title: t('emailLabel'),
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              defaultSort: 'asc',
              render: (m: Membership) => (
                <div>{m.email}</div>
              ),
              searchable: true,
              customFilterAndSearch: (term: any, m: any) => customFilterAndSearch(term, m, 'email', 'email'),
              customSort: (a: any, b: any) => insensitiveSort(a.email, b.email)
            }] : []),
            {
              title: t('tableRoles'),
              headerStyle: { textAlign: 'left' },
              cellStyle: { textAlign: 'left' },
              render: (m: Membership) => {
                if (!m.userId) return (<div>{t('pending')}</div>);
                if (!permissions.canEditPermissions || m.userId === user.id) return (<Typography>{m.role}</Typography>);
                return (
                  <Select
                    id={organisation.id}
                    value={m.role}
                    disabled={!m.role}
                    options={[{ id: 'Administrator', label: t('Administrator') }, { id: 'ViewOnly', label: t('ViewOnly') }]}
                    onChange={(orgId: string, roleId: string) => doUpdateRole(m.userId, roleId)}
                  />
                );
              },
              searchable: true,
              customFilterAndSearch: (term: any, m: any) => customRoleFilterSearch(term, m, t('pending')),
              customSort: (a: any, b: any) => customRoleSort(a, b, t('pending'))
            },
            ...(permissions.canEditPermissions ? [{
              title: t('tableActions'),
              headerStyle: { textAlign: 'center' },
              cellStyle: { textAlign: 'center' },
              searchable: false,
              sorting: false,
              render: (m: Membership) => {
                if (m.userId === user.id) return;
                return m.userId
                  ? deleteAction(m, organisation, t, doRemoveMember, showConfirmDialog)
                  : cancelInviteAction(m, organisation, t, doCancelInvite, showConfirmDialog);
              }
            }] : [])
          ]}
        />
      </DetailPanel>
      <ConfirmDialog okButtonText={t('okButton')} cancelButtonText={t('cancelButton')} onConfirm={dialog.confirmFunc} open={dialog.show} setOpen={value => setDialog({ ...dialog, show: value })} title={dialog.title} message={dialog.message} />
    </>
  );
};

export default OrganisationMembers;
