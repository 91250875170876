import React from 'react';
import { useTranslations } from 'use-intl';
import { Stack, Typography } from '@mui/material';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel from 'components/shared/assetLabel';
import type { ActivitySummaryDimensionKey } from './types';
import {getAllowedAssetIDCount} from "components/pages/reporting/ActivitySummary/tableHelpers";
import {FeatureAssetsResult} from "contexts/featureAssets/useFeatureAssets";

interface ActivitySummaryRowHeadingProps {
  dimensionKey: ActivitySummaryDimensionKey
  dimensions: PartialRecord<ActivitySummaryDimensionKey, string>
  assetIds: number[]
  assetsById: Record<number, AssetBasic> | undefined
  assetGroups: { id: number, name: string }[] | undefined
  ownersById: Record<string, { id: string, name: string }> | undefined
  categories: { name: string }[]
  featureAssets: FeatureAssetsResult
}

const ActivitySummaryRowHeading: React.FC<ActivitySummaryRowHeadingProps> = (
  {
    dimensionKey,
    dimensions,
    assetIds,
    assetsById,
    assetGroups,
    ownersById,
    categories ,
    featureAssets,
  }) => {
  const t = useTranslations('pages.reporting.summary');

  if (dimensionKey === 'assetId') {
    const asset = assetsById?.[dimensions.assetId as unknown as number];
    return (
      <Stack direction="row" spacing={2} alignItems="center">
        {asset && <AssetColourMarker assetId={asset.id} />}
        <Stack>
          <Typography><AssetLabel asset={asset} fallback={`ID: ${dimensions.assetId}`} /></Typography>
          {asset && <Typography variant="body3">{asset.make} {asset.model} {asset.variant}</Typography>}
        </Stack>
      </Stack>
    );
  }

  if (dimensionKey === 'assetGroupId') {
    const assetGroup = assetGroups?.find(g => g.id.toString() === dimensions.assetGroupId);
    return (
      <Stack>
        <Typography>{assetGroup?.name}</Typography>
        <Typography variant="body3">{t('nAssets', { n: getAllowedAssetIDCount(assetIds, featureAssets) })}</Typography>
      </Stack>
    );
  }

  if (dimensionKey === 'assetModel') {
    return (
      <Stack>
        <Typography>{dimensions.assetMake} {dimensions.assetModel}</Typography>
        <Typography variant="body3">{t('nAssets', { n: getAllowedAssetIDCount(assetIds, featureAssets) })}</Typography>
      </Stack>
    );
  }

  if (dimensionKey === 'ownerId') {
    // TODO: remove case insensitive matching
    const ownerId = dimensions.ownerId ?? dimensions.OwnerId;
    const owner = Object.values(ownersById ?? {}).find(o => o.id.toLowerCase() === ownerId.toLowerCase());
    return (
      <Stack>
        <Typography>{owner?.name ?? ownerId}</Typography>
        <Typography variant="body3">{t('nAssets', { n: getAllowedAssetIDCount(assetIds, featureAssets) })}</Typography>
      </Stack>
    );
  }

  if (dimensionKey === 'category') {
    // TODO: remove case insensitive matching
    const cat = dimensions.category ?? dimensions.Category;
    const category = categories.find(c => c.name.toLowerCase() === cat.toLowerCase());
    return (
      <Stack>
        <Typography>{category?.name ?? dimensions.category}</Typography>
        <Typography variant="body3">{t('nAssets', { n: getAllowedAssetIDCount(assetIds, featureAssets) })}</Typography>
      </Stack>
    );
  }

  return (
    <Stack>
      <Typography>{dimensions[dimensionKey]}</Typography>
      <Typography variant="body3">{t('nAssets', { n: getAllowedAssetIDCount(assetIds, featureAssets) })}</Typography>
    </Stack>
  );
};

export default ActivitySummaryRowHeading;
