import { InsightMetricKey, InsightRow } from 'apis/rest/insights/types';
import { Children, PureComponent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, Rectangle, ResponsiveContainer, Text, Tooltip, Treemap, XAxis, YAxis } from 'recharts';
import { DimensionFilterState } from './dimensionState';
import { Box, ButtonGroup, IconButton, Stack, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import useDistance from 'hooks/units/useDistance';
import useDuration from 'hooks/units/useDuration';
import { AccountTreeRounded, BarChartRounded, LineAxis, PieChartRounded, X } from '@mui/icons-material';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import { margin } from '@mui/system';
import { ActivitySummaryAssetCategory, ActivitySummaryColumnDimensions } from './types';
import { AssetGroup } from 'apis/rest/assetGroups/types';

interface ActivityGraphProps {
  dimensions: {
    row: DimensionFilterState
    column: ActivitySummaryColumnDimensions
  }
  metric: InsightMetricKey
  rows: InsightRow[]
  categories: ActivitySummaryAssetCategory[]
  removeEmpty?: boolean
  assets: AssetBasic[]
  assetGroups: AssetGroup[]
  owners: {
    id: string, name: string
  }[]
}

type DataGroup = {
  key: string, items: InsightRow[]
}

export default function ActivityGraph({ dimensions, metric, rows, categories, removeEmpty, assets, assetGroups, owners }: ActivityGraphProps) {

  const [graphType, setGraphType] = useState<string>("bar");

  const t = useTranslations('pages.reporting.summary')
  const distance = useDistance();
  const duration = useDuration();
  const isDark = useUiSettings().darkMode ?? false;

  const styles = useMemo(() => (isDark ? {
    Tooltip: {
      background: "#2A323C",
      item: "inherit",
      label: "inherit",
    },
    Graph: {
      axis: "white",
      grid: "#BBBDF6",
      active: "#797A9E",
      primary: "#9893DA",
      activeBackgroup: "#2A323C",
      colors: ["#9893DA", "#6A66A3", "#542E71", "#315659", "#2978A0", "#C6E0FF", "#6CCFF6", "#DDD8B8", "#BCAB79", "#B3CBB9", "#84A9C0", "#BBBDF6"],
      activeColors: ["#9893DA", "#6A66A3", "#542E71", "#315659", "#2978A0", "#C6E0FF", "#6CCFF6", "#DDD8B8", "#BCAB79", "#B3CBB9", "#84A9C0", "#BBBDF6"]
    }
  } : {
    Tooltip: {
      background: "white",
      item: "inherit",
      label: "inherit",
    },
    Graph: {
      axis: "gray",
      grid: "gray",
      active: "#4766ba",
      primary: "#8ea6e8",
      activeBackgroup: "gray",
      colors: ["#BBBDF6", "#9893DA", "#6A66A3", "#542E71", "#315659", "#2978A0", "#C6E0FF", "#6CCFF6", "#DDD8B8", "#BCAB79", "#B3CBB9", "#84A9C0"],
      activeColors: ["#BBBDF6", "#9893DA", "#6A66A3", "#542E71", "#315659", "#2978A0", "#C6E0FF", "#6CCFF6", "#DDD8B8", "#BCAB79", "#B3CBB9", "#84A9C0"]
    }
  }), [isDark])

  // List of all possible groups (per row) based on row / column dimentions
  const groups = useMemo(() => {
    const array = [{ name: "Uncategorized", category: "Unknown", billable: "Unknown", owner: "" }]
    const hasBillable = !!dimensions.column.find(x => x === "billable")
    if (hasBillable) {
      array.push({ name: "BillableUncategorized", category: "Unknown", billable: "True", owner: "" })
    }
    if (dimensions.column.find(x => x === "category")) {
      array.push(...categories.flatMap(x => [
        { name: `${hasBillable ? "NotSet" : ""}${x.name}`, category: x.name, billable: "Unknown", owner: "" },
        ...(hasBillable ? [{ name: `Billable${x.name}`, category: x.name, billable: "True", owner: "" }] : [])
      ]))
    }
    if (dimensions.column.find(x => x === "ownerId")) {
      array.push(...owners.flatMap(x => [
        { name: `${hasBillable ? "NotSet" : ""}${x.id}`, category: "Unknown", billable: "Unknown", owner: x.id },
        ...(hasBillable ? [{ name: `Billable${x.name}`, category: "Unknown", billable: "True", owner: x.id }] : [])
      ]))
    }
    return array
  }, [dimensions.column, categories])

  const getName = useCallback((row: InsightRow) => {
    switch (dimensions.row.dimension) {
      case "assetId":
        return assets.find(x => `${x.id}` === row.dimensions.assetId)?.name ?? row.dimensions.assetId;
      case "assetGroupId":
        return assetGroups.find(x => `${x.id}` === row.dimensions.assetGroupId)?.name ?? row.dimensions.assetGroupId;
      case "assetModel":
        return row.dimensions.assetModel;
      case "category":
        return row.dimensions.category;
      case "ownerId":
        return owners.find(x => x.id === row.dimensions.ownerId)?.name ?? row.dimensions.ownerId;
      default:
        return "";
    }
  }, [dimensions.row.dimension])

  const getValues = useCallback((rows: InsightRow[],) => {
    const checkCategory = !!dimensions.column.find(x => x === "category")
    const checkOwner = !!dimensions.column.find(x => x === "ownerId")
    const checkBillable = !!dimensions.column.find(x => x === "billable")
    return groups.reduce((r, c) => {
      const row = rows.find(x =>
        ((!checkCategory && c.category === "Unknown") || (x.dimensions.category === c.category))
        &&
        ((!checkOwner && !c.owner) || (x.dimensions.ownerId === c.owner))
        &&
        ((!x.dimensions.billable && c.billable === "Unknown") || (x.dimensions.billable === c.billable))
      )
      r[c.name] = row?.metrics[metric] ?? 0
      if (row) {

      }
      return r;
    }, {} as { [key: string]: number | undefined })
  }, [dimensions.column, categories, metric])

  const getValueArray = useCallback((rows: InsightRow[],) => {
    const checkCategory = !!dimensions.column.find(x => x === "category")
    const checkOwner = !!dimensions.column.find(x => x === "ownerId")
    const checkBillable = !!dimensions.column.find(x => x === "billable")
    return groups.reduce((r, c) => {

      const row = rows.find(x =>
        ((!checkCategory && c.category === "Unknown") || (x.dimensions.category === c.category))
        &&
        ((!checkOwner && !c.owner) || (x.dimensions.ownerId === c.owner))
        &&
        ((!x.dimensions.billable && c.billable === "Unknown") || (x.dimensions.billable === c.billable))
      )
      if (row) {
        r.push({ name: c.name, value: row.metrics[metric] ?? 0 })
      }
      return r;
    }, [] as { name: string, value: number }[])
  }, [dimensions.column, categories, metric])

  const getValueSum = useCallback((rows: InsightRow[],) => {
    return rows.reduce((sum, row) => {
      return sum + (row.metrics[metric] ?? 0);
    }, 0)
  }, [dimensions.column, categories, metric])

  const formatValue = useCallback((value: ValueType, name?: NameType) => {
    const nameValue = name ? formatValuePropName(name.toString()) : t(`metric.${metric}.name`);
    switch (metric) {
      case "distance":
        const d = distance.create((value.valueOf() as number) * 1000)
        return {
          label: d.format(d => t('numberWithUnit', {
            value: d.unitValue,
            unitLabel: d.unitLabel,
          })),
          number: d.format(d => t('number', {
            value: d.unitValue,
            unitLabel: d.unitLabel,
          })),
          name: nameValue,
          unit: distance.unitLabel
        };
      case "duration":
        return {
          label: duration.fromMillis((value.valueOf() as number)),
          number: duration.fromMillis((value.valueOf() as number), 1, 'h'),
          name: nameValue,
          unit: "hr"
        };
      case "activityCount":
      case "tripCount":
      default:
        return { label: `${value}`, number: `${value}`, name: nameValue, unit: '' };
    }
  }, [metric, t, distance, duration])

  const pieLabel = useCallback((input: any) => {
    return <text x={input.x} y={input.y} fill={styles.Graph.primary}
      textAnchor={input.x > input.cx ? 'start' : 'end'} dominantBaseline="central">{input.name}</text>
  }, [styles.Graph.primary])

  // Data grouped into distict rows
  const dataGroups = useMemo(() => rows
    .map(x => ({ name: getName(x) ?? "", row: x }))
    .filter(x => !removeEmpty || !!x.row.metrics[metric])
    .reduce((r, c) => {
      const group = r.find(x => x.key === c.name);
      if (group) { group.items.push(c.row) }
      else { r.push({ key: c.name, items: [c.row] }) }
      return r;
    }, [] as DataGroup[])
    , [metric, rows, removeEmpty])

  const data = useMemo(() =>
    dataGroups.map(x => {
      return {
        name: x.key,
        values: getValues(x.items)
      }
    }), [dataGroups, getValues, styles, distance.unit, duration.unit])
  // includes styles and units so that the data changes and causes a graph redraw when theme or units change. 

  const treeData = useMemo(() => {
    return dataGroups.map(x => ({
      name: x.key,
      children: getValueArray(x.items)
    }))
  }, [dataGroups, getValueArray])

  // Removed category groups for graph types that can't handle it 
  const aggrigateData = useMemo(() =>
    dataGroups.map(x => ({
      name: x.key,
      value: getValueSum(x.items)
    })), [dataGroups, getValueSum])

  // Helper to get groups that have actual data so all groups aren't displayed in legend
  const groupsWithData = useMemo(() => groups.filter(g => data.reduce((sum, row) => sum + (row.values[g.name] ?? 0), 0) > 0), [groups, data])

  // Draw actual graph depending on selected type
  const graph = useMemo(() => {
    if (groupsWithData.length === 0) {
      return (<Text>No Data</Text>)
    }
    switch (graphType) {
      case "line":
        return (<AreaChart data={data} margin={{ top: 10, left: 30, bottom: 40, right: 20 }}>
          <Tooltip
            itemStyle={{ color: styles.Tooltip.item }}
            labelStyle={{ color: styles.Tooltip.label }}
            contentStyle={{ backgroundColor: styles.Tooltip.background }}
            formatter={(v, n, p) => { const f = formatValue(v, n); return [f.label, f.name] }} />
          <CartesianGrid stroke={styles.Graph.grid} strokeDasharray="3,3" />
          <XAxis dataKey="name" angle={60} name="Assets" tickMargin={20} tickFormatter={(v) => v.length > 5 ? `${v.substring(0, 5)}..` : v} stroke={styles.Graph.axis} />
          <YAxis tickFormatter={v => { const f = formatValue(v); return `${f.number}${f.unit}` }} stroke={styles.Graph.axis} />
          {groupsWithData.length > 1 && (
            <Legend align="right" verticalAlign="middle" layout="vertical" height={36} wrapperStyle={{ top: 0 }}
              formatter={(v, e, i) => formatValuePropName(v)} />
          )}
          {groupsWithData.map((x, i) => (
            <Area key={`area-${x.name}`} type="monotone" dataKey={`values.${x.name}`} stackId="1"
              fill={styles.Graph.colors[i % styles.Graph.colors.length]} strokeWidth={2} stroke={styles.Graph.colors[i % styles.Graph.colors.length]} />
          ))}
        </AreaChart>);
      case "bar":
        return (<BarChart data={data} margin={{ top: 10, left: 30, bottom: 40, right: 20 }}>
          <Tooltip
            itemStyle={{ color: styles.Tooltip.item }}
            labelStyle={{ color: styles.Tooltip.label }}
            contentStyle={{ backgroundColor: styles.Tooltip.background }}
            formatter={(v, n, p) => { const f = formatValue(v, n); return [f.label, f.name] }} />

          <CartesianGrid stroke={styles.Graph.grid} strokeDasharray="3,3" vertical={false} />
          <XAxis dataKey="name" angle={60} name="Assets" tickMargin={20} tickFormatter={(v) => v.length > 5 ? `${v.substring(0, 5)}..` : v} stroke={styles.Graph.axis} />
          <YAxis tickFormatter={v => { const f = formatValue(v); return `${f.number}${f.unit}` }} stroke={styles.Graph.axis} />
          {groupsWithData.length > 1 && (
            <Legend align="right" verticalAlign="middle" layout="vertical" height={36} wrapperStyle={{ top: 0 }}
              formatter={(v, e, i) => formatValuePropName(v)} />
          )}
          {groupsWithData.map((x, i) => (
            <Bar key={`bar-${x.name}`} dataKey={`values.${x.name}`} stackId="a"
              fill={styles.Graph.colors[i % styles.Graph.colors.length]}
              activeBar={<Rectangle fill={styles.Graph.activeColors[(i) % styles.Graph.activeColors.length]} />} />
          ))}
        </BarChart>);
      case "pie":
        return (<PieChart >
          <Tooltip
            itemStyle={{ color: styles.Tooltip.item }}
            labelStyle={{ color: styles.Tooltip.label }}
            contentStyle={{ backgroundColor: styles.Tooltip.background }}
            formatter={(v, n, p) => { const f = formatValue(v); return [f.label, p.payload.name] }} />

          <Pie data={aggrigateData} dataKey="value" nameKey="name" outerRadius={150} innerRadius={100} cx="50%" cy="50%" label={pieLabel}  >
            {data.map((x, i) => (
              <Cell key={`cell=${i}`} fill={styles.Graph.colors[i % styles.Graph.colors.length]} />
            ))}
          </Pie>
        </PieChart>)
      case "tree":
        return (<Treemap data={treeData} dataKey="value" fill={styles.Graph.primary} stroke={styles.Graph.grid}
          style={{ margin: { top: 10, left: 40, bottom: 40, right: 40 } }} aspectRatio={1} content={<CustomTree colors={styles.Graph.activeColors} />}>
          <Tooltip
            itemStyle={{ color: styles.Tooltip.item }}
            labelStyle={{ color: styles.Tooltip.label }}
            contentStyle={{ backgroundColor: styles.Tooltip.background }}
            formatter={(v, n, p) => { const f = formatValue(v); return [f.label, p.payload.name] }} /></Treemap>)

    }
  }, [graphType, data, aggrigateData, groupsWithData])

  return (
    <div className="flex flex-col items-center">
      <Typography variant='h2' className='my-2 ml-8'>{t(`metric.${metric}.name`)}</Typography>
      <ButtonGroup variant='outlined'>
        <IconButton size='large' onClick={() => setGraphType("bar")} disabled={graphType === "bar"}>
          <BarChartRounded />
        </IconButton>
        <IconButton size='large' onClick={() => setGraphType("line")} disabled={graphType === "line"}>
          <LineAxis />
        </IconButton>
        <IconButton size='large' onClick={() => setGraphType("pie")} disabled={graphType === "pie"}>
          <PieChartRounded />
        </IconButton>
        <IconButton size='large' onClick={() => setGraphType("tree")} disabled={graphType === "tree"}>
          <AccountTreeRounded />
        </IconButton>
      </ButtonGroup>
      <ResponsiveContainer width={graphType === "tree" ? "80%" : "100%"} height={groupsWithData.length === 0 ? 50 : 400}>
        {graph ?? (<div>None</div>)}
      </ResponsiveContainer>
    </div>
  );
}

const formatValuePropName = (value: string) => {
  const name = value.replace(/values\.(.*)/g, '$1')
  return name.replace(/([a-z])([A-Z])/g, '$1 $2');
}

const CustomTree = (props: any) => {
  const { root, depth, x, y, width, height, index, payload, colors, rank, name } = props;
  const color = colors[index % colors.length]
  return (<g>
    <Rectangle x={x} y={y} width={width} height={height}
      style={{ fill: depth <= 1 ? color : "#00000000" }}
    />
    {depth === 1 ? (
      <Text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fill="#fff" fontSize={14}>
        {name}
      </Text>
    ) : null}
  </g>)
}
