import useDistance from 'hooks/units/useDistance';
import useDuration from 'hooks/units/useDuration';
import type React from 'react';
import { useTranslations } from 'use-intl';
import type { ActivitySummaryDimensionKey, ActivitySummaryMetricKey, ActivitySummaryRow } from './types';

interface ActivitySummaryMetricValueProps {
  metric: ActivitySummaryMetricKey;
  rowDimensionKey: ActivitySummaryDimensionKey;
  metrics: ActivitySummaryRow['metrics'] | undefined;
  isTotal?: boolean;
}

const ActivitySummaryMetricValue: React.FC<ActivitySummaryMetricValueProps> = ({
  metric,
  rowDimensionKey,
  metrics,
  isTotal,
}) => {
  const t = useTranslations('pages.reporting.summary');
  const distance = useDistance();
  const duration = useDuration();

  const value = metrics?.[metric] ?? 0;
  let text = '';

  if (metric === 'activityCount' && (isTotal || rowDimensionKey === 'assetGroupId')) {
    text = t('notAvailable');
  } else if (metric === 'distance') {
    text = distance.create(value * 1000).format(d =>
      t('numberWithUnit', {
        value: d.unitValue,
        unitLabel: d.unitLabel,
      }),
    );
  } else if (metric === 'duration' || metric === 'airborneTime' || metric === 'movementTime') {
    text = duration.fromMillis(value);
  } else {
    text = t('number', { value });
  }

  return <>{text}</>;
};

export default ActivitySummaryMetricValue;
