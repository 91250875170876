import React, { useCallback, useState } from 'react';
import { CircularProgress, MenuItem, Stack, Typography } from '@mui/material';
import { useTranslations } from 'use-intl';
import { DateTime } from 'luxon';
import { sortBy } from 'lodash';
import { Input, stringify as csvStringify } from 'csv-stringify/browser/esm/sync';
import { useAssetLabel } from 'components/shared/assetLabel';
import useDistance from 'hooks/units/useDistance';
import {
  useFetchSupplementaryDataForTrips,
  useFetchTrips,
  useSupplementaryDataFields,
} from 'apis/rest/trips/hooks';
import { downloadCSV } from 'utils/download';
import { FieldAccessor } from 'helpers/supplementaryData';
import useDuration from 'hooks/units/useDuration';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';

export const useDownload = (tripAsset: AssetWithDevice) => {
  const t = useTranslations('pages.reporting.dailyFlightSummary.download');
  const assetLabel = useAssetLabel();

  const supplementaryDataFeatureAssets = useFeatureAssets('reporting.supplementaryData');
  const supplementaryDataEnabled = supplementaryDataFeatureAssets.hasAssetId(tripAsset.id);

  const fetchTrips = useFetchTrips();
  const fetchSupplementaryData = useFetchSupplementaryDataForTrips();

  const distance = useDistance();
  const duration = useDuration();

  const suppDataFields = useSupplementaryDataFields({ enabled: supplementaryDataEnabled }).data;

  return useCallback(async (
    asset: AssetWithDevice,
    from: number,
    until: number,
    timezone: string,
  ) => {
    const trips = await fetchTrips([asset.id], from, until, 60000);

    const suppData = await fetchSupplementaryData(trips.map(trip => trip.id), 60000);

    const usedSuppDataFields = suppDataFields?.filter(field => (
      Object.values(suppData).some(item => item?.fields.some(populatedField => populatedField.name === field.fieldName) ?? false)
    )) ?? [];

    const records: Input = sortBy(trips, 'startTime').map(trip => {
      const startReport = trip.reports.at(0);
      const endReport = trip.reports.at(-1);
      return {
        asset,
        assetLabel: assetLabel(asset),
        from: trip.start,
        to: trip.end,
        takeoff: {
          event: startReport?.events[0],
          time: DateTime.fromMillis(trip.startTime).setZone(timezone).toISO({ suppressMilliseconds: true }),
          coordinates: startReport ? `${startReport.coords[1].toFixed(3)}, ${startReport.coords[0].toFixed(3)}` : undefined,
        },
        landing: {
          event: trip.endTime ? endReport?.events[0] : undefined,
          time: trip.endTime ? DateTime.fromMillis(trip.endTime).setZone(timezone).toISO({ suppressMilliseconds: true }) : undefined,
          coordinates: trip.endTime && endReport ? `${endReport.coords[1].toFixed(5)}, ${endReport.coords[0].toFixed(5)}` : undefined,
        },
        flightDuration: duration.fromMillis(trip.duration),
        distance: trip.distance ? distance.create(trip.distance * 1000).format(d => +d.unitValue.toFixed(3)) : undefined,
        approvalStatus: trip.status,
        suppData: usedSuppDataFields.reduce<Record<string, string | number | boolean>>((acc, fieldType) => {
          const matchingField = suppData[trip.id]?.fields.find(f => f.name === fieldType.fieldName);
          if (matchingField) {
            acc[fieldType.fieldName] = matchingField[FieldAccessor[matchingField.dataType]];
            if (matchingField.dataType === 'Boolean') {
              if (acc[fieldType.fieldName] === 'True') {
                acc[fieldType.fieldName] = 'Yes';
              }
              if (acc[fieldType.fieldName] === 'False') {
                acc[fieldType.fieldName] = 'No';
              }
            }
          }
          return acc;
        }, {})
      };
    });

    const csvString = csvStringify(records, {
      header: true,
      columns: [
        { key: 'assetLabel', header: t('columns.asset') },
        { key: 'asset.make', header: t('columns.make') },
        { key: 'asset.model', header: t('columns.model') },
        { key: 'from', header: t('columns.from') },
        { key: 'to', header: t('columns.to') },
        { key: 'takeoff.event', header: t('columns.takeoffEvent') },
        { key: 'takeoff.time', header: t('columns.takeoffTime') },
        { key: 'takeoff.coordinates', header: t('columns.takeoffCoordinates') },
        { key: 'landing.event', header: t('columns.landingEvent') },
        { key: 'landing.time', header: t('columns.landingTime') },
        { key: 'landing.coordinates', header: t('columns.landingCoordinates') },
        { key: 'flightDuration', header: t('columns.flightDuration') },
        { key: 'approvalStatus', header: t('columns.approvalStatus') },
        { key: 'distance', header: t('columns.distance', { unit: distance.unitLabel }) },
        ...usedSuppDataFields.map(f => ({ key: `suppData.${f.fieldName}`, header: f.displayName }))
      ],
    });

    const day = DateTime.fromMillis(from).setZone(timezone);

    const exportFilename = `${t('filename.summary')} ${assetLabel(asset)} ${day.toISODate()}`.trim();

    downloadCSV(exportFilename, csvString);
  }, [fetchTrips, fetchSupplementaryData, suppDataFields, t, distance, assetLabel, duration]);
};

interface DownloadFlightSummaryMenuItemProps {
  asset: AssetWithDevice
  from: number | undefined
  until: number | undefined
  timezone: string
  close: () => void
  disabled?: boolean
}

const DownloadFlightSummaryMenuItem = ({ asset, from, until, timezone, close, disabled: disabledProp = false }: DownloadFlightSummaryMenuItemProps) => {
  const t = useTranslations('pages.reporting.dailyFlightSummary.download');
  const [isLoading, setIsLoading] = useState(false);

  const download = useDownload(asset);

  const disabled = disabledProp || !from || !until || isLoading;

  const onClick = async () => {
    if (disabled) return;
    setIsLoading(true);
    try {
      await download(asset, from, until, timezone);
    } finally {
      setIsLoading(false);
      close();
    }
  };

  return (
    <MenuItem disabled={disabled} onClick={onClick}>
      <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" minWidth="12rem">
        <Typography>{t('button.summary')}</Typography>
        {isLoading && <CircularProgress size="1.5rem" />}
      </Stack>
    </MenuItem>
  );
};

export default DownloadFlightSummaryMenuItem;
