import useFeatureFlag from 'hooks/useFeatureFlag';
import { useEffect, useMemo } from 'react';
import type { SetURLSearchParams } from 'react-router';
import {
  AssetCategoryFilterState,
  AssetFilterState,
  AssetGroupFilterState,
  AssetIcaoTypeDesignatorFilterState,
  AssetModelFilterState,
  AssetOwnerFilterState,
  type DimensionFilterState,
} from './dimensionState';
import type {
  ActivitySummaryColumnDimensionKey,
  ActivitySummaryColumnDimensions,
  ActivitySummaryMetricKey,
} from './types';

const METRICS: ActivitySummaryMetricKey[] = ['tripCount', 'duration', 'distance', 'activityCount'];
// TODO: put into above array when retiring `frontendAirborneMovementTime` FF
const TIME_METRICS: ActivitySummaryMetricKey[] = ['airborneTime', 'movementTime'];
const COLUMNS: ActivitySummaryColumnDimensionKey[] = ['billable', 'ownerId', 'category'];
const ICAO_TYPE_DESIGNATOR_COLUMN = 'icaoTypeDesignator';

export const useInitialUrlState = (searchParams: URLSearchParams, setSearchParams: SetURLSearchParams) => {
  // biome-ignore lint/correctness/useExhaustiveDependencies: only run on initial mount
  useEffect(() => {
    setSearchParams(
      params => {
        const row = params.get('row');
        const metrics = params.getAll('metric');
        if (row && metrics.length) return params;

        const next = new URLSearchParams(params);
        if (!row) next.set('row', 'assetOwner');
        if (!metrics.length) {
          for (const m of METRICS) {
            next.append('metric', m);
          }
        }
        return next;
      },
      { replace: true },
    );
  }, []);
};

// TODO: remove `enableIcaoType` when retiring `frontendAssetIcaoTypeDesignator`
export const getRowDimensionFromSearchParams = (
  searchParams: URLSearchParams,
  enableIcaoType = false,
): DimensionFilterState => {
  const row = searchParams.get('row');
  if (row === 'assetId') {
    return new AssetFilterState(
      searchParams.getAll('assetId').map(id => Number.parseInt(id, 10)),
      searchParams.getAll('assetGroupId').map(id => Number.parseInt(id, 10)),
    );
  }
  if (row === 'ownerId') {
    return new AssetOwnerFilterState(searchParams.getAll('ownerId'));
  }
  if (row === 'category') {
    return new AssetCategoryFilterState(searchParams.getAll('category'));
  }
  if (row === 'assetGroupId') {
    return new AssetGroupFilterState(searchParams.getAll('assetGroupId').map(id => Number.parseInt(id, 10)));
  }
  if (row === 'assetModel') {
    return new AssetModelFilterState([]);
  }
  if (enableIcaoType && row === 'icaoTypeDesignator') {
    return new AssetIcaoTypeDesignatorFilterState(searchParams.getAll('icaoTypeDesignator'));
  }
  return new AssetOwnerFilterState([]);
};

export const useRowDimension = (searchParams: URLSearchParams) => {
  const enableIcaoTypeFlag = useFeatureFlag('frontendAssetIcaoTypeDesignator');
  return useMemo(
    () => getRowDimensionFromSearchParams(searchParams, enableIcaoTypeFlag),
    [searchParams, enableIcaoTypeFlag],
  );
};

// TODO: remove `enableTimeMetrics` when retiring `frontendAirborneMovementTime` FF
export const getMetricsFromSearchParams = (
  searchParams: URLSearchParams,
  enableTimeMetrics = false,
): ActivitySummaryMetricKey[] =>
  searchParams
    .getAll('metric')
    .filter(
      (m): m is ActivitySummaryMetricKey =>
        (METRICS as string[]).includes(m) || (enableTimeMetrics && (TIME_METRICS as string[]).includes(m)),
    );

export const useMetrics = (searchParams: URLSearchParams) => {
  const enableTimeMetrics = useFeatureFlag('frontendAirborneMovementTime');
  return useMemo(() => getMetricsFromSearchParams(searchParams, enableTimeMetrics), [searchParams, enableTimeMetrics]);
};

// TODO: remove `enableIcaoType` when retiring `frontendAssetIcaoTypeDesignator`
export const getColumnDimensionsFromSearchParams = (
  searchParams: URLSearchParams,
  enableIcaoType = false,
): ActivitySummaryColumnDimensions => {
  const result = searchParams
    .getAll('column')
    .filter(
      (d): d is ActivitySummaryColumnDimensionKey =>
        (COLUMNS as string[]).includes(d) || (enableIcaoType && d === ICAO_TYPE_DESIGNATOR_COLUMN),
    );
  return result.slice(0, 2) as ActivitySummaryColumnDimensions;
};

export const useColumnDimensions = (searchParams: URLSearchParams) => {
  const enableIcaoTypeFlag = useFeatureFlag('frontendAssetIcaoTypeDesignator');
  return useMemo(
    () => getColumnDimensionsFromSearchParams(searchParams, enableIcaoTypeFlag),
    [searchParams, enableIcaoTypeFlag],
  );
};

export const setUrlState =
  (setSearchParams: SetURLSearchParams) =>
  ({
    rowDimension,
    columnDimensions,
    metrics,
  }: {
    rowDimension: DimensionFilterState;
    columnDimensions: ActivitySummaryColumnDimensions;
    metrics: ActivitySummaryMetricKey[];
  }) => {
    const params: [string, string][] = [['row', rowDimension.dimension]];

    if (rowDimension.dimension === 'assetId') {
      params.push(...rowDimension.items.map<[string, string]>(id => ['assetId', id.toString()]));
      params.push(...rowDimension.groupItems.map<[string, string]>(id => ['assetGroupId', id.toString()]));
    } else if (rowDimension.dimension === 'category') {
      params.push(...rowDimension.items.map<[string, string]>(id => ['category', id]));
    } else if (rowDimension.dimension === 'ownerId') {
      params.push(...rowDimension.items.map<[string, string]>(id => ['ownerId', id]));
    } else if (rowDimension.dimension === 'assetGroupId') {
      params.push(...rowDimension.items.map<[string, string]>(id => ['assetGroupId', id.toString()]));
    } else if (rowDimension.dimension === 'icaoTypeDesignator') {
      params.push(...rowDimension.items.map<[string, string]>(id => ['icaoTypeDesignator', id.toString()]));
    }

    params.push(...columnDimensions.map<[string, string]>(d => ['column', d]));
    params.push(...metrics.map<[string, string]>(m => ['metric', m]));

    setSearchParams(params);
  };

export const useSetUrlState = (setSearchParams: SetURLSearchParams) =>
  useMemo(() => setUrlState(setSearchParams), [setSearchParams]);
