import { Button, CircularProgress, Grid, Stack, TextField, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import { useGetIcaoTypeDesignators } from 'apis/aircraftTypes/hooks';
import { useArchiveAsset, useGetAssetsList } from 'apis/rest/assets/hooks';
import clsx from 'clsx';
import DetailPanel from 'components/shared/DetailPanel';
import { useAssetLabel, useAssetLabelKey } from 'components/shared/assetLabel';
import SettingsSection from 'components/shared/settingsSection';
import useFeatureFlag from 'hooks/useFeatureFlag';
import useSnackbar from 'hooks/useSnackbar';
import type React from 'react';
import { useMemo } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router';
import { useTranslations } from 'use-intl';
import { IcaoTypeDesignatorInput } from '../common/IcaoTypeDesignatorInput';
import { validateIcaoTypeDesignator } from '../helpers';
import useStyles from './asset-styles';
import AssetEditCallsign from './assetEditCallsign-view';

export type AssetDetails = Pick<
  AssetBasic,
  | 'name'
  | 'category'
  | 'make'
  | 'model'
  | 'variant'
  | 'icaoTypeDesignator'
  | 'messagingHandle'
  | 'watchlistGroup'
  | 'callSign'
  | 'tailNumber'
>;

const validateAssetDetails = (assetDetails: Partial<AssetDetails>) =>
  !(
    (assetDetails.name?.length ?? 0) > 50 ||
    (assetDetails.make?.length ?? 0) > 50 ||
    (assetDetails.model?.length ?? 0) > 50 ||
    (assetDetails.variant?.length ?? 0) > 50
  );

interface AssetDetailsProps {
  localAsset: AssetBasic;
  readOnly: boolean;
  categories: Category[];
  saveAssetDetails: (assetDetails: AssetDetails) => void;
  isSaving: boolean;
}

const AssetDetailsView: React.FC<AssetDetailsProps> = ({
  localAsset,
  readOnly,
  categories,
  saveAssetDetails,
  isSaving,
}) => {
  const t = useTranslations('pages.assetView');
  const classes = useStyles();
  const assetLabel = useAssetLabel();
  const assetLabelKey = useAssetLabelKey();

  const assetsQuery = useGetAssetsList().query;
  const assets = assetsQuery.isSuccess ? assetsQuery.data : [];
  const archiveAsset = useArchiveAsset();
  const snackbar = useSnackbar();

  const groupedCategories = useMemo(
    () =>
      Object.values(
        categories
          .filter(c => c?.id !== 'System')
          .reduce<Record<string, { name: string; children: Category[] }>>((acc, obj) => {
            const key = obj.group;
            if (!acc[key]) {
              acc[key] = {
                name: key.charAt(0).toUpperCase() + key.slice(1), // capitalize group name
                children: [],
              };
            }
            acc[key].children.push(obj);
            acc[key].children.sort((a, b) => a.label.toUpperCase().localeCompare(b.label.toUpperCase()));
            return acc;
          }, {}),
      ).toSorted((a, b) => a.name.localeCompare(b.name)),
    [categories],
  );

  const [assetDetails, setAssetDetails] = useState<AssetDetails>({
    name: localAsset.name || '',
    category: localAsset.category,
    make: localAsset.make || '',
    model: localAsset.model || '',
    variant: localAsset.variant || '',
    icaoTypeDesignator: localAsset.icaoTypeDesignator || '',
    messagingHandle: localAsset.messagingHandle || '',
    watchlistGroup: localAsset.watchlistGroup || '',
    callSign: localAsset.callSign || '',
    tailNumber: localAsset.tailNumber || '',
  });
  useEffect(() => {
    setAssetDetails({
      name: localAsset.name || '',
      category: localAsset.category,
      make: localAsset.make || '',
      model: localAsset.model || '',
      variant: localAsset.variant || '',
      icaoTypeDesignator: localAsset.icaoTypeDesignator,
      messagingHandle: localAsset.messagingHandle || '',
      watchlistGroup: localAsset.watchlistGroup || '',
      callSign: localAsset.callSign || '',
      tailNumber: localAsset.tailNumber || '',
    });
  }, [localAsset]);

  const onAssetDetailsChange = (partial: Partial<AssetDetails>) => {
    if (validateAssetDetails(partial)) {
      setAssetDetails({ ...assetDetails, ...partial });
    }
  };

  const enableIcaoTypeFlag = useFeatureFlag('frontendAssetIcaoTypeDesignator');
  const enableIcaoTypeDesignator = useMemo(
    () => (enableIcaoTypeFlag ?? false) && ['Aircraft', 'Helicopter'].includes(assetDetails.category),
    [enableIcaoTypeFlag, assetDetails.category],
  );
  const typeDesignators = useGetIcaoTypeDesignators({ enabled: enableIcaoTypeDesignator });

  const toggleArchive = () => {
    archiveAsset.mutate(
      { assetId: localAsset.id, archived: !localAsset.archived },
      {
        onSuccess: () => {
          snackbar.display({
            id: 'archiving',
            text: t('archivalUpdated', { name: assetLabel(localAsset) }),
            type: 'success',
          });
        },
      },
    );
  };

  const valid = useMemo(
    () =>
      validateAssetDetails(assetDetails) &&
      validateIcaoTypeDesignator(
        enableIcaoTypeDesignator,
        assetDetails.icaoTypeDesignator,
        assetDetails,
        typeDesignators.data,
      ),
    [assetDetails, enableIcaoTypeDesignator, typeDesignators.data],
  );

  return (
    <SettingsSection title={t('generalTitle')} description={t('generalDescription')}>
      <DetailPanel p={3}>
        <Alert severity="info" sx={{ mb: 3 }}>
          {t.rich('assetLabelMessage', {
            assetLabelKey,
            link: chunks => <Link to="/settings/general">{chunks}</Link>,
            break: () => <br />,
          })}
        </Alert>
        <Grid container spacing={3}>
          <Grid item sm={12} md={enableIcaoTypeFlag ? 6 : 12}>
            <TextField
              label={t('name')}
              value={assetDetails.name}
              onChange={e => onAssetDetailsChange({ name: e.target.value })}
              disabled={readOnly || isSaving}
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              select
              label={t('category')}
              value={assetDetails.category}
              onChange={e => onAssetDetailsChange({ category: e.target.value })}
              disabled={readOnly || isSaving}
              SelectProps={{
                native: true,
              }}
              variant="outlined"
              fullWidth
            >
              {groupedCategories.map(group => (
                <optgroup key={group.name} label={group.name}>
                  {group.children.map(c => (
                    <option key={c.id} value={c.id}>
                      {c.label}
                    </option>
                  ))}
                </optgroup>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={12} md={6}>
            <Autocomplete
              freeSolo
              options={assets
                .map(a => a.make?.toString() || '') // get makes of all assets
                .filter((asset, index, array) => asset && array.indexOf(asset) === index) // filter undefined & duplicates
                .sort((a, b) => a?.localeCompare(b))} // sort alphabetical
              getOptionLabel={option => option}
              value={assetDetails.make}
              onChange={(_, value) => onAssetDetailsChange({ make: value })}
              onInputChange={(_, value) => onAssetDetailsChange({ make: value })}
              renderInput={params => <TextField {...params} variant="outlined" label={t('make')} fullWidth />}
              disabled={readOnly || isSaving}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Autocomplete
              freeSolo
              options={assets
                .map(a => a.model?.toString() || '') // get models of all assets
                .filter((asset, index, array) => asset && array.indexOf(asset) === index) // filter undefined & duplicates
                .sort((a, b) => a?.localeCompare(b))} // sort alphabetical
              getOptionLabel={option => option}
              value={assetDetails.model}
              onChange={(_, value) => onAssetDetailsChange({ model: value })}
              onInputChange={(_, value) => onAssetDetailsChange({ model: value })}
              renderInput={params => <TextField {...params} variant="outlined" label={t('model')} fullWidth />}
              disabled={readOnly || isSaving}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Autocomplete
              freeSolo
              options={assets
                .map(a => a.variant?.toString() || '') // get variants of all assets
                .filter((asset, index, array) => asset && array.indexOf(asset) === index) // filter undefined & duplicates
                .sort((a, b) => a?.localeCompare(b))} // sort alphabetical
              getOptionLabel={option => option}
              value={assetDetails.variant}
              onChange={(_, value) => onAssetDetailsChange({ variant: value })}
              onInputChange={(_, value) => onAssetDetailsChange({ variant: value })}
              renderInput={params => <TextField {...params} variant="outlined" label={t('variant')} fullWidth />}
              disabled={readOnly || isSaving}
            />
          </Grid>
          {enableIcaoTypeFlag && (
            <Grid item sm={12} md={6}>
              <IcaoTypeDesignatorInput
                asset={assetDetails}
                value={!enableIcaoTypeDesignator ? null : assetDetails.icaoTypeDesignator}
                onChange={value => onAssetDetailsChange({ icaoTypeDesignator: value })}
                disabled={readOnly || isSaving || !enableIcaoTypeDesignator}
              />
            </Grid>
          )}
          <Grid item sm={12} md={6}>
            <TextField
              label={t('tailNumber')}
              value={assetDetails.tailNumber}
              onChange={e => onAssetDetailsChange({ tailNumber: e.target.value })}
              disabled={readOnly || isSaving}
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              label={t('callSign')}
              value={assetDetails.callSign}
              onChange={e => onAssetDetailsChange({ callSign: e.target.value })}
              disabled={readOnly || isSaving}
              InputProps={{
                endAdornment: readOnly ? <AssetEditCallsign asset={localAsset} /> : undefined,
              }}
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              label={t('messagingHandle')}
              value={assetDetails.messagingHandle}
              onChange={e => onAssetDetailsChange({ messagingHandle: e.target.value })}
              disabled={readOnly || isSaving}
              className={clsx([classes.readOnly, classes.helperText])}
              helperText="@msg.tracplus.com"
              fullWidth
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Autocomplete
              freeSolo
              id="watchlistGroup-select"
              options={assets
                .map(a => a.watchlistGroup?.toString() ?? '') // get watchlist groups of all assets
                .filter((watchlistGroup, index, array) => watchlistGroup && array.indexOf(watchlistGroup) === index) // filter undefined & duplicates
                .sort((a, b) => a.localeCompare(b))} // sort alphabetical
              getOptionLabel={option => option}
              value={assetDetails.watchlistGroup}
              disabled={readOnly || isSaving}
              onChange={(_, value) => onAssetDetailsChange({ watchlistGroup: value })}
              onInputChange={(_, value) => onAssetDetailsChange({ watchlistGroup: value })}
              renderInput={params => <TextField {...params} variant="outlined" label={t('watchlistGroup')} fullWidth />}
            />
          </Grid>
          {!readOnly && (
            <Grid item sm={12} md={12} className={classes.inputButtonWrapper}>
              <Button
                className={classes.inputButton}
                variant="contained"
                onClick={e => {
                  e.preventDefault();
                  saveAssetDetails(assetDetails);
                }}
                disabled={isSaving || !valid}
                sx={{ minWidth: '10rem' }}
              >
                {t(isSaving ? 'saving' : 'save')}
              </Button>
            </Grid>
          )}
        </Grid>
      </DetailPanel>
      {!readOnly && (
        <DetailPanel p={3} mt={3}>
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" height="4rem">
              <Stack justifyContent="center" spacing={1}>
                <Typography variant="h2">{t('archival')}</Typography>
                <Typography>{localAsset.archived ? t('archivedDescription') : t('archiveDescription')}</Typography>
              </Stack>
              <Button
                variant="outlined"
                size="large"
                color={localAsset.archived ? 'success' : 'error'}
                sx={{ minWidth: '10rem', height: '100%s' }}
                onClick={() => toggleArchive()}
              >
                {archiveAsset.isPending ? (
                  <CircularProgress size="2rem" color={localAsset.archived ? 'success' : 'error'} />
                ) : localAsset.archived ? (
                  t('unarchive')
                ) : (
                  t('archive')
                )}
              </Button>
            </Stack>
          </Stack>
        </DetailPanel>
      )}
    </SettingsSection>
  );
};

export default AssetDetailsView;
