import { serenityFetch } from "helpers/api";
import { HttpResponseError } from 'helpers/api';


export const registerClient = async (uid: string, clientId: string, fcmToken: string): Promise<void> => {
  const response = await serenityFetch('POST', `/user/${uid}/client`, {
    clientId: clientId,
    fcmToken: fcmToken,
  });
  HttpResponseError.detect(response);
};

export const unregisterClient = async (uid: string, clientId: string): Promise<void> => {
  const response = await serenityFetch('DELETE', `/user/${uid}/client/${clientId}`, {});
  HttpResponseError.detect(response);
};
