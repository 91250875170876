import { ErrorOutlined, ExpandMore } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItem,
  ListItemButton,
  Menu,
  MenuItem,
  Popover,
  Radio,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import type { AssetGroup } from 'apis/rest/assetGroups/types';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel from 'components/shared/assetLabel';
import { FeatureFlag } from 'components/shared/featureFlag';
import type { FeatureAssetsResult } from 'contexts/featureAssets/useFeatureAssets';
import useFeatureFlag from 'hooks/useFeatureFlag';
import type React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslations } from 'use-intl';
import ActivitySummaryDateRangeView from './ActivitySummaryDateRange.view';
import {
  AssetCategoryFilterState,
  AssetFilterState,
  AssetGroupFilterState,
  AssetIcaoTypeDesignatorFilterState,
  AssetModelFilterState,
  AssetOwnerFilterState,
  type DimensionFilterState,
} from './dimensionState';
import { getAllowedAssetCount, getAllowedAssetIDCount } from './tableHelpers';
import type {
  ActivitySummaryAssetCategory,
  ActivitySummaryAssetMakeModel,
  ActivitySummaryAssetOwner,
  ActivitySummaryColumnDimensionKey,
  ActivitySummaryColumnDimensions,
  ActivitySummaryDateRange,
  ActivitySummaryDimensionKey,
  ActivitySummaryIcaoTypeDesignator,
  ActivitySummaryMetricKey,
  ActivitySummaryRowDimensionKey,
} from './types';

interface ActivitySummaryFiltersViewProps {
  rowDimension: DimensionFilterState;
  columnDimensions: ActivitySummaryColumnDimensions;
  metrics: ActivitySummaryMetricKey[];
  onChangeFilters: (
    rowDimension: DimensionFilterState,
    columnDimensions: ActivitySummaryColumnDimensions,
    metrics: ActivitySummaryMetricKey[],
  ) => void;
  timezone: string;
  dateRange: ActivitySummaryDateRange;
  onChangeDateRange: (dateRange: ActivitySummaryDateRange) => void;
  assets: AssetBasic[];
  categories: ActivitySummaryAssetCategory[];
  ownersById: Record<string, ActivitySummaryAssetOwner>;
  assetGroups: AssetGroup[];
  makeModels: ActivitySummaryAssetMakeModel[];
  icaoTypeDesignators: ActivitySummaryIcaoTypeDesignator[];
  featureAssets: FeatureAssetsResult;
  graph: {
    show: boolean;
    excludeEmpty: boolean;
    onChange: (show: boolean) => void;
    onChangeExcludeEmpty: (value: boolean) => void;
  };
}

interface RowDimensionOption {
  id: ActivitySummaryRowDimensionKey;
  disabled?: boolean;
  buildState(): DimensionFilterState;
}

interface ColumnDimensionOption {
  id: ActivitySummaryColumnDimensionKey;
  allowedForRowDimensions: ActivitySummaryDimensionKey[];
  disabled?: boolean;
}

const ROW_DIMENSION_OPTIONS: RowDimensionOption[] = [
  { id: 'assetId', buildState: () => new AssetFilterState([], []) },
  // { id: 'assetModel', buildState: () => new AssetModelFilterState([]) },
  { id: 'category', buildState: () => new AssetCategoryFilterState([]) },
  { id: 'ownerId', buildState: () => new AssetOwnerFilterState([]) },
  { id: 'assetGroupId', buildState: () => new AssetGroupFilterState([]) },
];
// TODO: put into above list when retiring `frontendAssetIcaoTypeDesignator` FF
const ICAO_TYPE_DESIGNATOR_ROW_DIMENSION_OPTION: RowDimensionOption = {
  id: 'icaoTypeDesignator',
  buildState: () => new AssetIcaoTypeDesignatorFilterState([]),
};

const COLUMN_DIMENSION_OPTIONS: ColumnDimensionOption[] = [
  { id: 'billable', allowedForRowDimensions: ['assetId', 'category', 'ownerId', 'assetGroupId', 'icaoTypeDesignator'] },
  { id: 'ownerId', allowedForRowDimensions: ['category', 'assetGroupId', 'icaoTypeDesignator'] },
  { id: 'category', allowedForRowDimensions: ['ownerId', 'assetGroupId', 'icaoTypeDesignator'] },
];
// TODO: put into above list when retiring `frontendAssetIcaoTypeDesignator` FF
const ICAO_TYPE_DESIGNATOR_COLUMN_DIMENSION_OPTION: ColumnDimensionOption = {
  id: 'icaoTypeDesignator',
  allowedForRowDimensions: ['ownerId', 'assetGroupId', 'category'],
};

interface MetricOption {
  id: ActivitySummaryMetricKey;
  disabled?: boolean;
}

const METRIC_OPTIONS: MetricOption[] = [
  { id: 'tripCount' },
  { id: 'duration' },
  { id: 'distance' },
  { id: 'activityCount' },
];
// TODO: put into above array when retiring `frontendAirborneMovementTime` FF
const TIME_METRIC_OPTIONS: MetricOption[] = [{ id: 'airborneTime' }, { id: 'movementTime' }];

interface FilteredGroupState {
  id: number;
  state: 'FULL' | 'PARTIAL' | 'EMPTY';
  missingAssets?: AssetBasic[];
}

interface GroupFilterByProps {
  initialState: DimensionFilterState;
  onApply: (value: DimensionFilterState) => void;
  onCancel: () => void;
  assets: AssetBasic[];
  categories: ActivitySummaryAssetCategory[];
  ownersById: Record<string, ActivitySummaryAssetOwner>;
  assetGroups: AssetGroup[];
  makeModels: ActivitySummaryAssetMakeModel[];
  icaoTypeDesignators: ActivitySummaryIcaoTypeDesignator[];
  featureAssets: FeatureAssetsResult;
}

const GroupFilterBy = ({
  initialState,
  onApply,
  onCancel,
  assets,
  categories,
  ownersById,
  assetGroups,
  makeModels,
  icaoTypeDesignators,
  featureAssets,
}: GroupFilterByProps) => {
  const t = useTranslations('pages.reporting.summary');
  const tFeatures = useTranslations('labs.features');
  const [value, setValue] = useState<DimensionFilterState>(initialState);

  const filteredAssets = useMemo(
    (): AssetBasic[] => assets.filter(asset => featureAssets.hasAssetId(asset.id)),
    [assets, featureAssets],
  );

  const enableIcaoTypeFlag = useFeatureFlag('frontendAssetIcaoTypeDesignator');
  // TODO: remove when retiring `frontendAssetIcaoTypeDesignator` FF
  const rowDimensionOptions = useMemo(
    () =>
      enableIcaoTypeFlag
        ? ROW_DIMENSION_OPTIONS.concat(ICAO_TYPE_DESIGNATOR_ROW_DIMENSION_OPTION)
        : ROW_DIMENSION_OPTIONS,
    [enableIcaoTypeFlag],
  );

  const addGroupTooltip = useCallback(
    (groupState?: FilteredGroupState) => (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {groupState?.state !== 'FULL' && (
          <Tooltip
            placement="right"
            title={
              <>
                <div>
                  {tFeatures('tooltips.assetsDisabled', {
                    featureName: tFeatures('reporting.activitySummaryReport.title'),
                  })}
                </div>
                {groupState?.missingAssets?.map(asset => (
                  <div key={`${asset.id}-tooltip`}>{asset.name}</div>
                ))}
              </>
            }
          >
            {groupState?.state === 'EMPTY' ? (
              <Chip
                icon={<ErrorOutlined />}
                label={tFeatures('warnings.noAssetsEnabled')}
                variant="outlined"
                color="error"
              />
            ) : (
              <Chip
                icon={<ErrorOutlined />}
                label={tFeatures('warnings.nAssetsDisabled', { n: groupState?.missingAssets?.length ?? 0 })}
                variant="outlined"
                color="warning"
              />
            )}
          </Tooltip>
        )}
      </>
    ),
    [tFeatures],
  );

  const getGroupState = useCallback(
    (groupAssetIds: number[]) => {
      const missingAssetIds = groupAssetIds.filter(assetId => !featureAssets.hasAssetId(assetId));
      const missingAssets = assets.filter(asset => missingAssetIds.includes(asset.id));

      return addGroupTooltip({
        state:
          missingAssets.length === 0 ? 'FULL' : missingAssets.length === groupAssetIds.length ? 'EMPTY' : 'PARTIAL',
        missingAssets,
        id: -1, // unused
      });
    },
    [addGroupTooltip, assets, featureAssets],
  );

  return (
    <Stack p={3} minWidth={800}>
      <Stack direction="row" spacing={3}>
        <Box>
          <FormLabel>{t('groupBy')}</FormLabel>
          <List sx={{ p: 0 }}>
            {rowDimensionOptions.map(option => (
              <ListItem key={option.id} sx={{ p: 0 }}>
                <ListItemButton disabled={option.disabled} onClick={() => setValue(option.buildState())}>
                  <Radio checked={value.dimension === option.id} />
                  <Typography>{t(`dimension.${option.id}.name`)}</Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box flex="1">
          {value.dimension === 'assetId' && (
            <FormLabel>
              {t('dimension.assetId.filterLabel', { selected: value.getAssetIds(assetGroups).length })}
            </FormLabel>
          )}
          {value.dimension === 'assetGroupId' && (
            <FormLabel>{t('dimension.assetGroupId.filterLabel', { selected: value.items.length })}</FormLabel>
          )}
          {value.dimension === 'category' && (
            <FormLabel>{t('dimension.category.filterLabel', { selected: value.items.length })}</FormLabel>
          )}
          {value.dimension === 'ownerId' && (
            <FormLabel>{t('dimension.ownerId.filterLabel', { selected: value.items.length })}</FormLabel>
          )}
          {value.dimension === 'assetModel' && (
            <FormLabel>{t('dimension.assetModel.filterLabel', { selected: value.items.length })}</FormLabel>
          )}
          {value.dimension === 'icaoTypeDesignator' && (
            <FormLabel>{t('dimension.icaoTypeDesignator.filterLabel', { selected: value.items.length })}</FormLabel>
          )}

          <Box width="100%" maxHeight={300} overflow="auto">
            {value.dimension === 'assetId' && (
              <List sx={{ p: 0 }}>
                {assetGroups.map(group => (
                  <ListItem key={`group-${group.id}`} sx={{ p: 0 }}>
                    <Grid container alignItems="center">
                      <Grid item xs={8}>
                        <ListItemButton
                          onClick={() =>
                            setValue(current => {
                              if (current.dimension !== 'assetId') return new AssetFilterState([], [group.id]);
                              return current.hasGroup(group.id)
                                ? current.removeGroup(group.id)
                                : current.addGroup(group.id);
                            })
                          }
                          disabled={!getAllowedAssetCount(group.assets, featureAssets)}
                        >
                          <Checkbox checked={value.hasGroup(group.id)} />
                          <Typography>
                            {group.name} ({t('nAssets', { n: getAllowedAssetCount(group.assets, featureAssets) })})
                          </Typography>
                        </ListItemButton>
                      </Grid>
                      <Grid item xs={4}>
                        {getGroupState(group.assets.map(asset => asset.id))}
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
                {filteredAssets.map(asset => (
                  <ListItem key={`asset-${asset.id}`} sx={{ p: 0 }}>
                    <ListItemButton
                      onClick={() =>
                        setValue(current => {
                          if (current.dimension !== 'assetId') return new AssetFilterState([asset.id], []);
                          return current.has(asset.id) ? current.remove(asset.id) : current.add(asset.id);
                        })
                      }
                    >
                      <Checkbox checked={value.has(asset.id)} />
                      <Stack direction="row" spacing={2} alignItems="center">
                        <AssetColourMarker assetId={asset.id} />
                        <Stack>
                          <Typography>
                            <AssetLabel asset={asset} fallback={`ID: ${asset.id}`} />
                          </Typography>
                          <Typography variant="body3">
                            {asset.make} {asset.model} {asset.variant}
                          </Typography>
                        </Stack>
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}

            {value.dimension === 'assetGroupId' && (
              <List sx={{ p: 0 }}>
                {assetGroups.map(group => (
                  <ListItem key={group.id} sx={{ p: 0 }}>
                    <Grid container alignItems="center">
                      <Grid item xs={8}>
                        <ListItemButton
                          onClick={() =>
                            setValue(current => {
                              if (current.dimension !== 'assetGroupId') return new AssetGroupFilterState([group.id]);
                              return current.has(group.id) ? current.remove(group.id) : current.add(group.id);
                            })
                          }
                          disabled={!getAllowedAssetCount(group.assets, featureAssets)}
                        >
                          <Checkbox checked={value.has(group.id)} />
                          <Typography>
                            {group.name} ({t('nAssets', { n: getAllowedAssetCount(group.assets, featureAssets) })})
                          </Typography>
                        </ListItemButton>
                      </Grid>
                      <Grid item xs={4}>
                        {getGroupState(group.assets.map(asset => asset.id))}
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            )}

            {value.dimension === 'category' && (
              <List sx={{ p: 0 }}>
                {categories.map(category => (
                  <ListItem key={category.name} sx={{ p: 0 }}>
                    <Grid container alignItems="center">
                      <Grid item xs={8}>
                        <ListItemButton
                          onClick={() =>
                            setValue(current => {
                              if (current.dimension !== 'category')
                                return new AssetCategoryFilterState([category.name]);
                              return current.has(category.name)
                                ? current.remove(category.name)
                                : current.add(category.name);
                            })
                          }
                          disabled={getAllowedAssetIDCount(category.assetIds, featureAssets) === 0}
                        >
                          <Checkbox checked={value.has(category.name)} />
                          <Typography>
                            {category.name} (
                            {t('nAssets', { n: getAllowedAssetIDCount(category.assetIds, featureAssets) })})
                          </Typography>
                        </ListItemButton>
                      </Grid>
                      <Grid item xs={4}>
                        {getGroupState(category.assetIds)}
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            )}

            {value.dimension === 'ownerId' && (
              <List sx={{ p: 0 }}>
                {Object.values(ownersById).map(owner => (
                  <ListItem key={owner.id} sx={{ p: 0 }}>
                    <Grid container alignItems="center">
                      <Grid item xs={8}>
                        <ListItemButton
                          onClick={() =>
                            setValue(current => {
                              if (current.dimension !== 'ownerId') return new AssetOwnerFilterState([owner.id]);
                              return current.has(owner.id) ? current.remove(owner.id) : current.add(owner.id);
                            })
                          }
                          disabled={getAllowedAssetIDCount(owner.assetIds, featureAssets) === 0}
                        >
                          <Checkbox checked={value.has(owner.id)} />
                          <Typography>
                            {owner.name} ({t('nAssets', { n: getAllowedAssetIDCount(owner.assetIds, featureAssets) })})
                          </Typography>
                        </ListItemButton>
                      </Grid>
                      <Grid item xs={4}>
                        {getGroupState(owner.assetIds)}
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            )}

            {value.dimension === 'assetModel' && (
              <List sx={{ p: 0 }}>
                {makeModels.map(({ make, model, assetIds }) => (
                  <ListItem key={`${make}:${model}`} sx={{ p: 0 }}>
                    <Grid container alignItems="center">
                      <Grid item xs={8}>
                        <ListItemButton
                          onClick={() =>
                            setValue(current => {
                              const item = { make, model };
                              if (current.dimension !== 'assetModel') return new AssetModelFilterState([item]);
                              return current.has(item) ? current.remove(item) : current.add(item);
                            })
                          }
                          disabled={getAllowedAssetIDCount(assetIds, featureAssets) === 0}
                        >
                          <Checkbox checked={value.has({ make, model })} />
                          <Typography>
                            {make} {model} ({t('nAssets', { n: getAllowedAssetIDCount(assetIds, featureAssets) })})
                          </Typography>
                        </ListItemButton>
                      </Grid>
                      <Grid item xs={4}>
                        {getGroupState(assetIds)}
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            )}

            {value.dimension === 'icaoTypeDesignator' && (
              <List sx={{ p: 0 }}>
                {icaoTypeDesignators.map(({ icaoTypeDesignator, assetIds }) => (
                  <ListItem key={icaoTypeDesignator} sx={{ p: 0 }}>
                    <Grid container alignItems="center">
                      <Grid item xs={8}>
                        <ListItemButton
                          onClick={() =>
                            setValue(current => {
                              if (current.dimension !== 'icaoTypeDesignator')
                                return new AssetIcaoTypeDesignatorFilterState([icaoTypeDesignator]);
                              return current.has(icaoTypeDesignator)
                                ? current.remove(icaoTypeDesignator)
                                : current.add(icaoTypeDesignator);
                            })
                          }
                          disabled={getAllowedAssetIDCount(assetIds, featureAssets) === 0}
                        >
                          <Checkbox checked={value.has(icaoTypeDesignator)} />
                          <Typography>
                            {icaoTypeDesignator} ({t('nAssets', { n: getAllowedAssetIDCount(assetIds, featureAssets) })}
                            )
                          </Typography>
                        </ListItemButton>
                      </Grid>
                      <Grid item xs={4}>
                        {getGroupState(assetIds)}
                      </Grid>
                    </Grid>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Box>
      </Stack>
      <Stack
        direction="row"
        spacing={3}
        justifyContent="flex-end"
        pt={2}
        borderTop="1px solid"
        borderColor="common.midGrey"
      >
        {value.dimension === 'assetGroupId' && (
          <Alert severity="info" sx={{ flex: 1 }}>
            At least one asset group must be chosen
          </Alert>
        )}
        <Button
          sx={{ minHeight: '4rem', minWidth: '20ch' }}
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => onCancel()}
        >
          {t('cancel')}
        </Button>
        <Button
          sx={{ minHeight: '4rem', minWidth: '20ch' }}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => onApply(value)}
          disabled={value.dimension === 'assetGroupId' && !value.items.length}
        >
          {t('applyFilters')}
        </Button>
      </Stack>
    </Stack>
  );
};

const ActivitySummaryFiltersView: React.FC<ActivitySummaryFiltersViewProps> = ({
  rowDimension,
  columnDimensions,
  metrics: storedMetrics,
  onChangeFilters,
  timezone,
  dateRange,
  onChangeDateRange,
  assets,
  categories,
  ownersById,
  assetGroups,
  makeModels,
  icaoTypeDesignators,
  featureAssets,
  graph,
}) => {
  const t = useTranslations('pages.reporting.summary');
  const [dropdownId, setDropdownId] = useState<'rowDimension' | 'columnDimension' | 'metrics'>();
  const [pendingColumns, setPendingColumns] = useState<ActivitySummaryColumnDimensions | undefined>();
  const [pendingMetrics, setPendingMetrics] = useState<ActivitySummaryMetricKey[] | undefined>();

  const rowDimensionButtonAnchor = useRef<HTMLButtonElement>(null);
  const columnDimensionButtonAnchor = useRef<HTMLButtonElement>(null);
  const metricsButtonAnchor = useRef<HTMLButtonElement>(null);

  const columns = pendingColumns ?? columnDimensions;
  const metrics = pendingMetrics ?? storedMetrics;

  const enableIcaoTypeFlag = useFeatureFlag('frontendAssetIcaoTypeDesignator');
  // TODO: remove when retiring `frontendAssetIcaoTypeDesignator` FF
  const columnDimensionOptions = useMemo(
    () =>
      enableIcaoTypeFlag
        ? COLUMN_DIMENSION_OPTIONS.concat(ICAO_TYPE_DESIGNATOR_COLUMN_DIMENSION_OPTION)
        : COLUMN_DIMENSION_OPTIONS,
    [enableIcaoTypeFlag],
  );

  const enableTimeMetrics = useFeatureFlag('frontendAirborneMovementTime');
  // TODO: just use `METRIC_OPTIONS` after retiring `frontendAirborneMovementTime` FF
  const metricOptions = useMemo(
    () => (enableTimeMetrics ? METRIC_OPTIONS.concat(TIME_METRIC_OPTIONS) : METRIC_OPTIONS),
    [enableTimeMetrics],
  );

  return (
    <>
      <Stack
        direction={{ sx: 'column', sm: 'row' }}
        spacing={3}
        m={3}
        alignItems="stretch"
        justifyContent="space-between"
        sx={{ flexWrap: 'wrap' }}
      >
        <Stack direction="column" spacing={3}>
          <Stack direction={{ sx: 'column', sm: 'row' }} spacing={3} alignItems="stretch" sx={{ flexWrap: 'wrap' }}>
            <Button
              ref={rowDimensionButtonAnchor}
              variant="outlined"
              onClick={() => setDropdownId(value => (value === 'rowDimension' ? undefined : 'rowDimension'))}
              endIcon={<ExpandMore />}
              sx={{ minHeight: '4rem' }}
            >
              <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap" useFlexGap minWidth="20ch">
                {t(`dimension.${rowDimension.dimension}.name`)}
              </Stack>
            </Button>

            <Button
              ref={columnDimensionButtonAnchor}
              variant="outlined"
              onClick={() => setDropdownId(value => (value === 'columnDimension' ? undefined : 'columnDimension'))}
              endIcon={<ExpandMore />}
              sx={{ minHeight: '4rem' }}
            >
              <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap" useFlexGap minWidth="20ch">
                {columns.length === 0 && t('noColumnGrouping')}
                {columns.map(dimension => t(`dimension.${dimension}.name`)).join(', ')}
              </Stack>
            </Button>

            <Button
              ref={metricsButtonAnchor}
              variant="outlined"
              onClick={() => setDropdownId(value => (value === 'metrics' ? undefined : 'metrics'))}
              endIcon={<ExpandMore />}
              sx={{ minHeight: '4rem' }}
            >
              <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap" useFlexGap>
                <Chip variant="filled" color="primary" label={metrics.length} />
                <span>{t('metrics', { count: metrics.length })}</span>
              </Stack>
            </Button>
          </Stack>
          <FeatureFlag
            feature="tpcActivitySummaryGraphs"
            enabled={
              <Stack direction={{ sx: 'column', sm: 'row' }} spacing={3} alignItems="stretch" sx={{ flexWrap: 'wrap' }}>
                <FormControlLabel
                  control={<Switch checked={graph.show} onChange={x => graph.onChange(x.target.checked)} />}
                  label="Show graphs"
                />
                <FormControlLabel
                  control={
                    <Switch checked={graph.excludeEmpty} onChange={x => graph.onChangeExcludeEmpty(x.target.checked)} />
                  }
                  label="Exclude zeros in graphs"
                />
              </Stack>
            }
          />
        </Stack>

        <ActivitySummaryDateRangeView zone={timezone} dateRange={dateRange} onChange={onChangeDateRange} />
      </Stack>

      <Popover
        anchorEl={rowDimensionButtonAnchor.current}
        open={dropdownId === 'rowDimension'}
        sx={{ mt: 1 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        BackdropProps={{ sx: { bgcolor: 'rgba(0, 0, 0, 0.5)' } }}
      >
        <GroupFilterBy
          initialState={rowDimension}
          onApply={value => {
            onChangeFilters(
              value,
              columns.filter(d =>
                columnDimensionOptions
                  .find(option => option.id === d)
                  ?.allowedForRowDimensions.includes(value.dimension),
              ) as ActivitySummaryColumnDimensions,
              metrics,
            );
            setDropdownId(undefined);
          }}
          onCancel={() => setDropdownId(undefined)}
          assets={assets}
          categories={categories}
          ownersById={ownersById}
          assetGroups={assetGroups}
          makeModels={makeModels}
          icaoTypeDesignators={icaoTypeDesignators}
          featureAssets={featureAssets}
        />
      </Popover>

      <Menu
        anchorEl={columnDimensionButtonAnchor.current}
        open={dropdownId === 'columnDimension'}
        onClose={() => {
          setDropdownId(undefined);
          if (pendingColumns) {
            onChangeFilters(rowDimension, pendingColumns, metrics);
            setPendingColumns(undefined);
          }
        }}
        sx={{ mt: 1 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {columnDimensionOptions.map(option => {
          const checked = (columns as ActivitySummaryDimensionKey[]).includes(option.id);
          return (
            <MenuItem
              key={option.id}
              onClick={() =>
                setPendingColumns(value => {
                  const set = new Set<ActivitySummaryDimensionKey>(value ?? columns);
                  if (!set.delete(option.id)) set.add(option.id);
                  return [...set] as ActivitySummaryColumnDimensions;
                })
              }
              disabled={
                option.disabled ||
                !option.allowedForRowDimensions.includes(rowDimension.dimension) ||
                (!checked && columns.length > 1)
              }
            >
              <Checkbox checked={checked} />
              <Typography>{t(`dimension.${option.id}.name`)}</Typography>
            </MenuItem>
          );
        })}
      </Menu>

      <Menu
        anchorEl={metricsButtonAnchor.current}
        open={dropdownId === 'metrics'}
        onClose={() => {
          setDropdownId(undefined);
          if (pendingMetrics) {
            onChangeFilters(rowDimension, columns, pendingMetrics);
            setPendingMetrics(undefined);
          }
        }}
        sx={{ mt: 1 }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {metricOptions.map(option => (
          <MenuItem
            key={option.id}
            onClick={() =>
              setPendingMetrics(value => {
                const set = new Set(value ?? metrics);
                if (!set.delete(option.id)) set.add(option.id);
                return [...set];
              })
            }
            disabled={option.disabled}
          >
            <Checkbox checked={metrics.includes(option.id)} />
            <Box>
              <Typography>{t(`metric.${option.id}.name`)}</Typography>
              <Typography variant="caption">{t(`metric.${option.id}.description`)}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ActivitySummaryFiltersView;
