import { Button, CircularProgress, Grid, InputAdornment, TextField } from '@mui/material';
import clsx from 'clsx';
import DetailPanel from 'components/shared/DetailPanel';
import SettingsSection from 'components/shared/settingsSection';
import type React from 'react';
import { useMemo } from 'react';
import { useGetAssetCostMetadata, useAddAssetCostMetadata } from 'apis/rest/assets/hooks';
import { useEffect, useState } from 'react';
import { useTranslations } from 'use-intl';
import useStyles from './asset-styles';
import { v4 as uuidv4 } from 'uuid';
import { displaySnackbar } from 'slices/app.slice';
import { useAppDispatch } from 'store/types';
import { codes } from 'currency-codes';
import { useAddOrganisationCurrencyMetadata, useGetOrganisationCurrencyMetadata } from 'apis/rest/organisations/hooks';
export type AssetCostMetadataDetails = {'costPerHour': number};
export type OrganisationCurrencyMetadataDetails = {'currencyCode': string};

const validateCost = (assetCost: Partial<AssetCostMetadataDetails>) =>
  !(isNaN(assetCost.costPerHour ?? 0));

interface AssetMetadataProps {
  localAsset: AssetBasic;
  readOnly: boolean;
}

const AssetMetadataView: React.FC<AssetMetadataProps> = ({
  localAsset,
  readOnly
}) => {
  const { data: organisationCurrencyMetadata, isPending: currencyPending } = useGetOrganisationCurrencyMetadata();
  const dispatch = useAppDispatch();
  const updateAssetCostMutation = useAddAssetCostMetadata(localAsset.id);
  const saveAssetMetadataDetails = (assetMetadataDetails: AssetCostMetadataDetails) => {
    updateAssetCostMutation.mutate({
      id: uuidv4(),
      assetId: localAsset.id,
      cost: assetMetadataDetails.costPerHour,
      validFrom: new Date().toISOString()
    },
    {
      onError() {
        dispatch(
          displaySnackbar({
            id: 'costMetadataDetailsFailedToSave',
            text: t('metadataDetailedFailedToSave'),
            type: 'error',
          }),
        );
      },
      onSuccess() {
        displaySnackbar({
          id: 'costMetadataDetailsSaved',
          text: t('metadataDetailsSaved'),
          type: 'success',
        });
      },
    })
  };
  const isSaving = updateAssetCostMutation.isPending;
  const t = useTranslations('pages.assetView.metadata');
  const { data: assetCostMetadata, isPending } = useGetAssetCostMetadata(localAsset.id);
  const classes = useStyles();
  const [assetDetails, setAssetCostDetails] = useState<AssetCostMetadataDetails>({
    costPerHour: assetCostMetadata ? assetCostMetadata[0]?.cost : 0,
  });
  const [organisationCurrencyMetadataDetails, setOrganisationCurrencyDetails] = useState<OrganisationCurrencyMetadataDetails>({
    currencyCode: organisationCurrencyMetadata ? organisationCurrencyMetadata[0]?.currency : 'USD',
  });
  useEffect(() => {
    setAssetCostDetails({
      costPerHour: assetCostMetadata ? assetCostMetadata[0]?.cost : 0,
    });
  }, [assetCostMetadata]);

  useEffect(() => {
    setOrganisationCurrencyDetails({
      currencyCode: organisationCurrencyMetadata ? organisationCurrencyMetadata[0]?.currency : 'USD',
    });
  }, [organisationCurrencyMetadata])

  const onAssetMetadataDetailsChange = (partial: Partial<AssetCostMetadataDetails>) => {
    if (validateCost(partial)) {
      setAssetCostDetails({ ...assetDetails, ...partial });
    }
    else {
      setAssetCostDetails({costPerHour: 0});
    }
  };
  const canSave = useMemo(() => assetDetails.costPerHour !== assetCostMetadata?.[0].cost, [assetDetails.costPerHour, assetCostMetadata])

  return (
    <SettingsSection title={t('title')} description={t('generalDescription')}>
      <DetailPanel p={3}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6}>
            <TextField
              label={t('cost')}
              value={assetDetails.costPerHour === 0 ? "": assetDetails.costPerHour}
              inputProps={{ inputMode: 'numeric' }}
              onChange={e => onAssetMetadataDetailsChange({ costPerHour: Number.parseFloat(e.target.value) ?? 0 })}
              disabled={isSaving || readOnly}
              InputProps={{
                endAdornment: (isPending && (<CircularProgress/>)),
                startAdornment:(<InputAdornment position='start'>{organisationCurrencyMetadataDetails.currencyCode ?? 'USD'}</InputAdornment>)
              }}
              className={clsx([classes.readOnly, classes.helperText])}
              fullWidth
            />
          </Grid>
            <Grid item sm={12} md={12} className={classes.inputButtonWrapper}>
              <Button
                className={classes.inputButton}
                variant="contained"
                onClick={e => {
                  e.preventDefault();
                  saveAssetMetadataDetails(assetDetails);
                }}
                disabled={isSaving || !canSave || isPending || readOnly}
                sx={{ minWidth: '10rem' }}
              >
                {t(isSaving ? 'saving' : 'save')}
              </Button>
            </Grid>
        </Grid>
      </DetailPanel>
    </SettingsSection>
  );
};

export default AssetMetadataView;

