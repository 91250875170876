import type { FeatureAssetsResult } from 'contexts/featureAssets/useFeatureAssets';
import type { ActivitySummaryDimensionKey, ActivitySummaryRow } from './types';

export const getDimensionValue = (row: ActivitySummaryRow, dimension: ActivitySummaryDimensionKey) => {
  // TODO: remove this filthy camelCase + PascalCase workaround
  const pascalCaseDimension = `${dimension[0].toUpperCase()}${dimension.slice(1)}` as ActivitySummaryDimensionKey;
  return row.dimensions[dimension] ?? row.dimensions[pascalCaseDimension];
};

export const getAllowedAssetIDCount = (usedAssetIds: number[], featureAssets: FeatureAssetsResult): number =>
  usedAssetIds.filter(id => featureAssets.hasAssetId(id)).length;
export const getAllowedAssetCount = (usedAssets: AssetBasic[], featureAssets: FeatureAssetsResult): number =>
  usedAssets.filter(asset => featureAssets.hasAssetId(asset.id)).length;

export const getRowId = (row: ActivitySummaryRow, dimensions: ActivitySummaryDimensionKey[]) =>
  dimensions.map(d => getDimensionValue(row, d)).join();

export const getUniqueDimensionValues = (rows: ActivitySummaryRow[]) =>
  rows.reduce<PartialRecord<ActivitySummaryDimensionKey, string[]>>((acc, row) => {
    const entries = Object.entries(row.dimensions) as [ActivitySummaryDimensionKey, string][];
    for (const [dimension, value] of entries) {
      if (dimension in acc) {
        const set = new Set(acc[dimension]);
        set.add(value);
        acc[dimension] = [...set];
        continue;
      }
      acc[dimension] = [value];
    }
    return acc;
  }, {});

export type DimensionTuple = Readonly<[ActivitySummaryDimensionKey, string]>;
export type Grid = DimensionTuple[][][];

export const getGridFromData = (data: ActivitySummaryRow[], dimensionKeys: ActivitySummaryDimensionKey[]) => {
  const [rowDimensionKey, ...columnDimensionKeys] = dimensionKeys;

  const dimensionValues = getUniqueDimensionValues(data);
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  const seed = dimensionValues[rowDimensionKey]!.map(v => [[[rowDimensionKey, v] as const]]);

  return columnDimensionKeys.reduce<Grid>((acc, dimensionKey) => {
    // biome-ignore lint/style/noNonNullAssertion: <explanation>
    const values = dimensionValues[dimensionKey]!;
    return acc.map(a => a.flatMap(b => values.map(c => [...b, [dimensionKey, c] as const])));
  }, seed);
};

export interface TableBodyRow {
  dimension: ActivitySummaryDimensionKey;
  assetIds: number[];
  cells: {
    id: string;
    dimensions: DimensionTuple[];
    row: ActivitySummaryRow | undefined;
  }[];
}

export const getBody = (rows: ActivitySummaryRow[], grid: Grid): TableBodyRow[] => {
  const dimensionKeys = grid[0][0].map(item => item[0]);

  const rowsById = rows.reduce<Record<string, ActivitySummaryRow>>((acc, row) => {
    acc[getRowId(row, dimensionKeys)] = row;
    return acc;
  }, {});

  return grid.map<TableBodyRow>(down => {
    const ids = down.map(a => a.map(b => b[1]).join());
    const cells = ids.map<{ id: string; row: ActivitySummaryRow | undefined; dimensions: DimensionTuple[] }>(
      (id, index) => ({
        id,
        dimensions: down[index],
        row: rowsById[id],
      }),
    );
    const assetIds = cells.flatMap(cell => cell.row?.assetIds ?? []);

    return {
      dimension: dimensionKeys[0],
      cells,
      assetIds: [...new Set(assetIds)],
    };
  });
};

export interface TableColumnHeader {
  dimension: ActivitySummaryDimensionKey;
  cells: {
    key: number;
    span: number;
    value: string;
  }[];
}

export const getColumnHeaders = (grid: Grid): TableColumnHeader[] => {
  const dimensionKeys = grid[0][0].map(item => item[0]).slice(1);

  return dimensionKeys.map<TableColumnHeader>((dimension, dIndex) => {
    const cells = grid[0].reduce<{ key: number; value: string; span: number }[]>((acc, item, index) => {
      const current = acc.at(-1);
      const value = item[dIndex + 1][1];
      if (!current || current.value !== value) {
        acc.push({ key: index, value, span: 1 });
      } else {
        current.span++;
      }
      return acc;
    }, []);
    return { dimension, cells };
  });
};
