import useOrganisationId from 'hooks/session/useOrganisationId';
import { useMutation, useQuery } from '@tanstack/react-query';
import { activateDevice, getDevicesAvailableForActivation, getProvisionState } from './requests';

export const deviceProvisionQueryKeys = {
  readyToActivate: (org: string) => [org, 'devicesReadyToActivate'] as const,
  requestState: (requestId?: string) => ['provisionRequest', requestId] as const,
};


export const useGetDevicesAvailableForActivation = (active: boolean) => {
  const organisationId = useOrganisationId();
  const queryKey = deviceProvisionQueryKeys.readyToActivate(organisationId);

  const query = useQuery({
    queryKey,
    queryFn: () => getDevicesAvailableForActivation(organisationId),
    enabled: () => active,
  });
  return { query, queryKey };
};

export const useGetProvisionState = (requestId: string | undefined) => {

  const queryKey = deviceProvisionQueryKeys.requestState(requestId);

  const query = useQuery({
    queryKey,
    queryFn: () => getProvisionState(requestId ?? ""),
    refetchInterval: 10000,
    enabled: () => !!requestId
  });
  return { query, queryKey };
};

export const mutateActivateDevice = () => {
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (deviceId: number) => activateDevice(organisationId, deviceId),
    mutationKey: ['activateDevice'],
  });
};
