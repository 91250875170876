import { Alert, Paper, Stack } from '@mui/material';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useGetActiveDays, useGetInsightsSummaryOld } from 'apis/rest/insights/hooks';
import type { InsightDimensionKey, InsightMetricKey, InsightsRequest } from 'apis/rest/insights/types';
import { useGetAllOrganisations } from 'apis/rest/memberships/hook';
import { FeatureFlag } from 'components/shared/featureFlag';
import FeaturePageStaffAccessAlert from 'components/shared/pageStaffAccessAlert/feature';
import useFeatureAssets from 'contexts/featureAssets/useFeatureAssets';
import { useStaff } from 'hooks/session/useStaff';
import useTimezone from 'hooks/session/useTimezone';
import _ from 'lodash';
import { DateTime } from 'luxon';
import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router';
import { useTranslations } from 'use-intl';
import ActivityGraph from './ActivityGraph';
import ActivitySummaryColumnHeading from './ActivitySummaryColumnHeading.view';
import ActivitySummaryFiltersView from './ActivitySummaryFilters.view';
import ActivitySummaryMetricValue from './ActivitySummaryMetricValue.view';
import ActivitySummaryRowHeading from './ActivitySummaryRowHeading.view';
import ActivitySummaryTableView from './ActivitySummaryTable.view';
import type { DimensionFilterState } from './dimensionState';
import { type BaseAssetGroup, transformAssetRowsToGroups, transformRowsToTotals } from './transforms';
import type {
  ActivitySummaryAssetCategory,
  ActivitySummaryAssetMakeModel,
  ActivitySummaryAssetOwner,
  ActivitySummaryColumnDimensions,
  ActivitySummaryDateRange,
  ActivitySummaryIcaoTypeDesignator,
  ActivitySummaryMetricKey,
} from './types';
import { useColumnDimensions, useInitialUrlState, useMetrics, useRowDimension, useSetUrlState } from './urlState';

const selectAssetsById = (data: AssetBasic[]) =>
  data.reduce<Record<number, AssetBasic>>((acc, asset) => {
    acc[asset.id] = asset;
    return acc;
  }, {});

const useInsightsRequest = (
  options: {
    timezone: string;
    dateRange: ActivitySummaryDateRange;
    rowDimension: DimensionFilterState;
    columnDimensions: InsightDimensionKey[];
    metrics: ActivitySummaryMetricKey[];
  },
  assetGroups: BaseAssetGroup[] | undefined,
  isStaff: boolean,
) =>
  useMemo(() => {
    let filter: PartialRecord<InsightDimensionKey, string[]> | undefined;
    let dimensions: InsightDimensionKey[] = [];
    const { rowDimension, columnDimensions, metrics, timezone, dateRange } = options;

    if (rowDimension.dimension === 'assetId') {
      dimensions = ['assetId'];
      const assetIds = rowDimension.getAssetIds(assetGroups ?? []).map(id => id.toString());
      if (assetIds.length) filter = { assetId: assetIds };
    } else if (rowDimension.dimension === 'assetGroupId') {
      dimensions = ['assetId'];
      const assetIds = rowDimension.getAssetIds(assetGroups ?? []).map(id => id.toString());
      if (assetIds.length) filter = { assetId: assetIds };
    } else if (rowDimension.dimension === 'assetModel') {
      dimensions = ['assetMake', 'assetModel'];
      const { makes, models } = rowDimension;
      if (makes.length || models.length) {
        filter = {};
        if (makes.length) filter.assetMake = makes;
        if (models.length) filter.assetModel = models;
      }
    } else if (rowDimension.dimension === 'ownerId') {
      dimensions = ['ownerId'];
      if (rowDimension.items.length) filter = { ownerId: [...rowDimension.items].sort() };
    } else if (rowDimension.dimension === 'category') {
      dimensions = ['category'];
      if (rowDimension.items.length) filter = { category: [...rowDimension.items].sort() };
    } else if (rowDimension.dimension === 'icaoTypeDesignator') {
      dimensions = ['icaoTypeDesignator'];
      if (rowDimension.items.length) filter = { icaoTypeDesignator: [...rowDimension.items].sort() };
    }

    dimensions.push(...columnDimensions);
    dimensions.sort();

    return {
      from: DateTime.fromMillis(dateRange.from, { zone: timezone }).startOf('day'),
      to: DateTime.fromMillis(dateRange.until, { zone: timezone }).endOf('day'),
      dimensions,
      metrics,
      filter,
      isStaff,
    };
  }, [options, assetGroups, isStaff]);

const ActivitySummaryOld: React.FC = () => {
  const t = useTranslations('pages.reporting.summary');

  const [searchParams, setSearchParams] = useSearchParams();
  useInitialUrlState(searchParams, setSearchParams);
  const rowDimension = useRowDimension(searchParams);
  const metrics = useMetrics(searchParams);
  const columnDimensions = useColumnDimensions(searchParams);
  const setUrlState = useSetUrlState(setSearchParams);

  const featureAssets = useFeatureAssets('reporting.activitySummaryReport');

  const isStaff = useStaff();

  const getAssets = useGetAssetsList({
    select: useCallback((data: AssetBasic[]) => data.filter(a => !a.archived), []),
  }).query;
  // Converts the org id / name in assets to distict id / name array for all orgs user has access to through assets 
  const organisationNames = useMemo(() => {
    const array = (getAssets.data?.map(x => ({ orgId: x.ownerId, orgName: x.ownerName })) ?? []);
    const ids = new Set<string>();
    return array.filter(i => {
      if (!ids.has(i.orgId)) {
        ids.add(i.orgId);
        return true;
      }
      return false;
    });
  }, [getAssets.data])

  const setFilters = useCallback(
    (r: DimensionFilterState, c: ActivitySummaryColumnDimensions, m: InsightMetricKey[]) => {
      setUrlState({ rowDimension: r, columnDimensions: c, metrics: m });
    },
    [setUrlState],
  );

  const timezone = useTimezone();

  const [dateRange, setDateRange] = useState<ActivitySummaryDateRange>(() => {
    const to = DateTime.now().setZone(timezone).startOf('day');
    const from = to.minus({ days: 6 });
    return { from: from.toMillis(), until: to.toMillis() };
  });
  const [showGraphs, setShowGraphs] = useState<boolean>(true);
  const [excludeEmpty, setExcludeEmpty] = useState<boolean>(true);

  const assetGroupsQuery = useGetAssetGroupsForOrganisation();
  const rawAssetGroups = assetGroupsQuery.data;

  const request = useInsightsRequest(
    {
      timezone,
      dateRange,
      rowDimension,
      columnDimensions,
      metrics,
    },
    rawAssetGroups,
    isStaff,
  );
  const activeDaysQuery = useGetActiveDays({ from: request.from, to: request.to, dimensions: request.dimensions });
  const query = useGetInsightsSummaryOld(request);

  // Get list of assets relevant to the selected timeframe
  const availableAssetsRequest: InsightsRequest = useMemo(
    () => ({
      dimensions: ['assetId', 'ownerId', 'category', 'icaoTypeDesignator'],
      metrics: ['tripCount'],
      from: DateTime.fromMillis(dateRange.from, { zone: timezone }).startOf('day'),
      to: DateTime.fromMillis(dateRange.until, { zone: timezone }).endOf('day'),
      isStaff: isStaff,
    }),
    [dateRange.from, dateRange.until, timezone, isStaff],
  );

  const availableAssetsQuery = useGetInsightsSummaryOld(availableAssetsRequest);

  const { query: assetsQuery } = useGetAssetsList({
    select: selectAssetsById,
  });

  const availableAssetsRows = useMemo(() => availableAssetsQuery.data?.rows ?? [], [availableAssetsQuery.data?.rows]);
  const bakedAssetIds = useMemo(
    () => availableAssetsQuery.data?.rows?.flatMap(row => row.dimensions?.assetId) ?? [],
    [availableAssetsQuery.data?.rows],
  );

  const assetsById = assetsQuery.data;

  // full assets list (pre-filter)
  const assets = useMemo(
    () => Object.values(assetsById ?? {}).filter(asset => bakedAssetIds.includes(asset.id.toString(10))),
    [assetsById, bakedAssetIds],
  );

  // uses current asset groups. Filters out non-baked asset ids and groups that do not contain a baked asset
  const assetGroups = useMemo(() => {
    const reducedGroups = rawAssetGroups?.map(group => ({
      ...group,
      assets: group.assets.filter(asset => bakedAssetIds.includes(asset.id.toString(10))),
    }));
    return reducedGroups?.filter(group => group.assets.length);
  }, [bakedAssetIds, rawAssetGroups]);

  const ownersById = useMemo(
    () => availableAssetsRows.reduce<Record<string, ActivitySummaryAssetOwner>>((acc, row) => {
      const { ownerId } = row.dimensions;
      const assetId = row.assetIds.find(id => id !== undefined);
      if (ownerId === undefined || assetId === undefined) return acc;
      const ownerName = organisationNames.find(org => org.orgId === ownerId)?.orgName ?? ownerId;
      if (!(ownerId in acc)) acc[ownerId] = { id: ownerId, name: ownerName, assetIds: [] };
      if (!acc[ownerId].assetIds.includes(assetId)) {
        acc[ownerId].assetIds.push(assetId);
      }
      return acc;
    }, {}),
    [availableAssetsRows, organisationNames],
  );

  const categories = useMemo(() => {
    const dictionary = availableAssetsRows.reduce<Record<string, ActivitySummaryAssetCategory>>((acc, row) => {
      const { category } = row.dimensions;
      const assetId = row.assetIds.find(id => id !== undefined);
      if (category === undefined || assetId === undefined) return acc;
      if (!(category in acc)) acc[category] = { name: category, assetIds: [] };

      if (!acc[category].assetIds.includes(assetId)) {
        acc[category].assetIds.push(assetId);
      }
      return acc;
    }, {});
    return Object.values(dictionary).sort((a, b) => (a.name > b.name ? 1 : -1));
  }, [availableAssetsRows]);

  const activeDays = useMemo(() => {
    // activeDaysQuery data is in time zone UTC, so we need to convert it to the local time zone
    const listOfSegments = activeDaysQuery.data?.activeDays?.map(fm => ({
      ...fm,
      activeDaysUtc: fm.activeDaysUtc?.map(d => ({
        start: DateTime.fromISO(d.start).setZone(timezone),
        end: DateTime.fromISO(d.end).setZone(timezone),
      })),
    }));

    // given the selected local date range and the converted local segments
    // count across days in the dateRange and compare
    const firstDay = DateTime.fromMillis(dateRange.from).setZone(timezone).startOf('day');
    const lastDay = DateTime.fromMillis(dateRange.until).setZone(timezone).endOf('day');

    return listOfSegments?.map(segment => {
      const { dimensions, activeDaysUtc } = segment;
      let initial = 0;
      let currentDay = firstDay;
      while (currentDay <= lastDay) {
        const day = currentDay;
        if (activeDaysUtc?.some(s => s.start <= day && day <= s.end)) {
          initial++;
        }
        currentDay = currentDay.plus({ days: 1 });
      }

      return { dimensions, count: initial };
    });
  }, [activeDaysQuery.data, dateRange, timezone]);

  const makeModels = useMemo(() => {
    const dictionary = assets.reduce<Record<string, ActivitySummaryAssetMakeModel>>((acc, asset) => {
      const id = `${asset.make ?? null}_${asset.model ?? null}`;
      if (!(id in acc))
        acc[id] = { make: (asset.make ?? 'null').toString(), model: (asset.model ?? 'null').toString(), assetIds: [] };
      acc[id].assetIds.push(asset.id);
      return acc;
    }, {});
    return Object.values(dictionary);
  }, [assets]);

  const icaoTypeDesignators = useMemo(() => {
    const dictionary = availableAssetsRows.reduce<Record<string, ActivitySummaryIcaoTypeDesignator>>((acc, row) => {
      const { icaoTypeDesignator } = row.dimensions;
      const assetId = row.assetIds.find(id => id !== undefined);
      if (icaoTypeDesignator === undefined || assetId === undefined) return acc;
      if (!(icaoTypeDesignator in acc)) acc[icaoTypeDesignator] = { icaoTypeDesignator, assetIds: [] };
      acc[icaoTypeDesignator].assetIds.push(assetId);
      return acc;
    }, {});
    return Object.values(dictionary).sort((a, b) => a.icaoTypeDesignator.localeCompare(b.icaoTypeDesignator));
  }, [availableAssetsRows]);

  const rawRows = query.data?.rows ?? [];
  const populatedRows = useMemo(
    () => rawRows.filter(row => row.assetIds?.length > 0 && row.assetIds?.length !== row.disabledAssetIds?.length),
    [rawRows],
  );

  const rows = useMemo(() => {
    if (rowDimension.dimension === 'assetGroupId') {
      const filteredAssetGroups = assetGroups?.filter(g => rowDimension.items.includes(g.id));
      if (!populatedRows || !filteredAssetGroups) return undefined;
      return transformAssetRowsToGroups(populatedRows, filteredAssetGroups);
    }

    return populatedRows;
  }, [populatedRows, assetGroups, rowDimension]);

  const totalsById = useMemo(
    () => (populatedRows ? transformRowsToTotals(populatedRows, columnDimensions, metrics) : {}),
    [populatedRows, columnDimensions, metrics],
  );

  return (
    <Stack spacing={3} mb={8} width="100%">
      <FeaturePageStaffAccessAlert feature="reporting.activitySummaryReport" />
      <Paper elevation={0}>
        <ActivitySummaryFiltersView
          rowDimension={rowDimension}
          columnDimensions={columnDimensions}
          onChangeFilters={setFilters}
          metrics={metrics}
          timezone={timezone}
          dateRange={dateRange}
          onChangeDateRange={setDateRange}
          assets={assets}
          categories={categories}
          ownersById={ownersById}
          assetGroups={assetGroups ?? []}
          makeModels={makeModels}
          icaoTypeDesignators={icaoTypeDesignators}
          featureAssets={featureAssets}
          graph={{
            show: showGraphs,
            excludeEmpty: excludeEmpty,
            onChange: setShowGraphs,
            onChangeExcludeEmpty: setExcludeEmpty,
          }}
        />
      </Paper>
      <FeatureFlag
        feature="tpcActivitySummaryGraphs"
        enabled={
          showGraphs && (
            <Paper>
              <div className="grid grid-cols-1 md:grid-cols-2 gap 4">
                {metrics.map(x => (
                  <ActivityGraph
                    key={`graph-${x}`}
                    dimensions={{ row: rowDimension, column: columnDimensions }}
                    metric={x}
                    rows={rows ?? []}
                    removeEmpty={excludeEmpty}
                    assets={assets}
                    assetGroups={assetGroupsQuery.data ?? []}
                    categories={categories}
                    owners={Object.values(ownersById)}
                  />
                ))}
              </div>
            </Paper>
          )
        }
      />
      <Paper elevation={0} sx={{ width: '100%' }}>
        {query.isLoading || activeDaysQuery.isLoading ? (
          <Alert severity="info" sx={{ m: 3 }}>
            {t('loading')}
          </Alert>
        ) : query.isError ? (
          <Alert severity="error" sx={{ m: 3 }}>
            {t('dataError')}
          </Alert>
        ) : (
          rows &&
          !rows.length && (
            <Alert severity="info" sx={{ m: 3 }}>
              {t('noData')}
            </Alert>
          )
        )}
        {rows && rows.length > 0 && (
          <ActivitySummaryTableView
            rowDimension={rowDimension}
            columnDimensions={columnDimensions}
            metrics={metrics}
            rows={rows}
            renderColumnDimension={(dimensionKey, value) => (
              <ActivitySummaryColumnHeading
                dimensionKey={dimensionKey}
                value={value}
                owners={Object.values(ownersById)}
                categories={categories}
              />
            )}
            renderRowDimension={(dimensionKey, dimensions, assetIds) => (
              <ActivitySummaryRowHeading
                dimensionKey={dimensionKey}
                dimensions={dimensions}
                assetIds={assetIds}
                assetsById={assetsById}
                ownersById={ownersById}
                categories={categories}
                assetGroups={assetGroups}
                featureAssets={featureAssets}
              />
            )}
            renderMetricValue={(metric, row) => {
              if (metric === 'activityCount') {
                const activeDaysCount = activeDays?.find(d => _.isEqual(d.dimensions, row?.dimensions));
                return (
                  <ActivitySummaryMetricValue
                    metric={metric}
                    metrics={{ activityCount: activeDaysCount?.count ?? 0 }}
                    rowDimensionKey={rowDimension.dimension}
                  />
                );
              }
              return (
                <ActivitySummaryMetricValue
                  metric={metric}
                  metrics={row?.metrics}
                  rowDimensionKey={rowDimension.dimension}
                />
              );
            }}
            renderMetricTotalValue={(item, metric) => {
              const id = item
                .slice(1)
                .map(v => v[1])
                .join();
              const totals = totalsById[id];

              return (
                <ActivitySummaryMetricValue
                  metric={metric}
                  metrics={totals?.metrics}
                  rowDimensionKey={rowDimension.dimension}
                  isTotal
                />
              );
            }}
          />
        )}
      </Paper>
    </Stack>
  );
};

export default ActivitySummaryOld;
