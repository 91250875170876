import { useMutation } from '@tanstack/react-query';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { messagingQueryKeys } from 'apis/rest/messaging/queryKeys';
import { registerClient, unregisterClient } from 'apis/rest/messaging/requests';
import useAllFeatureAssets from 'contexts/featureAssets/useAllFeatureAssets';
import { di } from 'di';
import { getToken, onMessage } from 'firebase/messaging';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { useUser } from 'hooks/session/useUser';
import useSnackbar from 'hooks/useSnackbar';
import { useEffect } from 'react';

const firebaseDep = di.depend('firebaseService');

export const FirebaseNotificationView = () => {
  const allFeatures = useAllFeatureAssets();
  const pushFeature = allFeatures.getFeature('push_notifications');
  const snackbar = useSnackbar();
  const assets = useGetAssetsList();
  const { messaging } = firebaseDep.inject();
  const orgId = useOrganisationId();
  const user = useUser();


  const { mutate: registerMutation } = useMutation({
    mutationKey: messagingQueryKeys.registerClient(user!.id),
    mutationFn: (token: string) => registerClient(user!.id, token, token)
  });

  const { mutate: unregisterMutation } = useMutation({
    mutationKey: messagingQueryKeys.registerClient(user!.id),
    mutationFn: (token: string) => unregisterClient(user!.id, token)
  });


  useEffect(() => {
    if (!pushFeature.some) return;
    let token = '';
    // register web client with backend
    const register = async () => {

      const permission = await Notification.requestPermission();
      if (permission === 'granted') {

        token = await getToken(messaging, {
          vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
        });
        console.log('VAPID KEY', import.meta.env.VITE_FIREBASE_VAPID_KEY);
        console.log('Registering client with token', token);
        registerMutation(token);
      } else {
        console.log('Permission not granted');
      }
    };

    register();
    return () => {
      console.log('Unregistering client');
      unregisterMutation(token);
    };
  }, [pushFeature]);


  useEffect(() => {
    if (!pushFeature.some) return;
    console.log('---------- Registering message listener');
    const messageListener = onMessage(messaging, message => {
      console.log('---------- Message received. ', message);
      const messageData = message.data as unknown as FirebaseMessage;
      const stringIds = messageData.Assets ?? messageData.Devices ?? '';
      const ids = stringIds.split(',').map(x => x.trim());
      // only show notifications for the current organisation
      if (messageData.OrgId !== orgId) return;

      //convert asset ids to names
      const assetNames = assets.query.data?.filter(x => ids.includes(x.id.toString())).map(x => x.name).join(', ');
      const deviceNames = assets.query.data?.filter(x => ids.includes(x.deviceId?.toString() ?? '')).map(x => x.name).join(', ');

      snackbar.display({
        type: 'info',
        text: `${messageData.Event} - ${assetNames ?? deviceNames}`,
        id: message.messageId,
      });
    });

    return () => {
      console.log('---------- Unregistering message listener');
      messageListener();
    };
  }, [assets.query.data, pushFeature]);

  return null;
};
