import type {
  ApiBaseAssetAssignment,
  Base,
  BaseAssetAssignment,
  BaseRequest,
  BaseResponse,
} from 'apis/rest/bases/bases-types';
import { HttpResponseError, serenityFetch } from 'helpers/api';
import { DateTime } from 'luxon';

export const getBases = async (organisationId: string): Promise<Base[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/bases`, null);
  HttpResponseError.detect(response);
  const baseResponses = (await response.json()).bases as BaseResponse[];
  return baseResponses.map<Base>(base => ({
    ...base,
    assignments: base.assignments.map(a => {
      const startTime = DateTime.fromISO(a.startTime, { zone: 'utc' });
      const endTime = a.endTime ? DateTime.fromISO(a.endTime, { zone: 'utc' }) : null;

      if (!startTime.isValid) {
        throw new Error(`${a.startTime} is invalid`);
      }

      if (endTime !== null && !endTime.isValid) {
        throw new Error(`${a.endTime} is invalid`);
      }

      return {
        id: a.id,
        assetId: a.assetId,
        startTime,
        endTime,
      } satisfies BaseAssetAssignment;
    }),
  }));
};

export const createBase = async (organisationId: string, base: BaseRequest): Promise<void> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/bases/${base.id}`, base);
  HttpResponseError.detect(response);
};

export const updateBase = async (organisationId: string, base: BaseRequest): Promise<void> => {
  const response = await serenityFetch('PUT', `/organisations/${organisationId}/bases/${base.id}`, base);
  HttpResponseError.detect(response);
};

export const deleteBase = async (organisationId: string, baseId: string): Promise<void> => {
  const response = await serenityFetch('DELETE', `/organisations/${organisationId}/bases/${baseId}`, null);
  HttpResponseError.detect(response);
};

export const updateBaseAssignment = async (
  organisationId: string,
  baseId: string,
  baseAssignment: ApiBaseAssetAssignment,
): Promise<void> => {
  const response = await serenityFetch(
    'POST',
    `/organisations/${organisationId}/bases/${baseId}/assignments/assets/${baseAssignment.assetId}`,
    baseAssignment,
  );
  HttpResponseError.detect(response);
};
