import { Typography } from '@mui/material';
import { useVirtualizer } from '@tanstack/react-virtual';
import { type CSSProperties, type FC, useRef } from 'react';
import QueryResult from '../queryResult';
import useStyles from '../results-styles';
import type { ActiveQuery, BucketHeader, BucketResult } from './types';

interface GroupedResultsListProps {
  bucketList: (BucketHeader | BucketResult)[];
  activeQuery: ActiveQuery;
  selectAsset: (asset: AssetBasic) => void;
}

const GroupedResultsList: FC<GroupedResultsListProps> = ({ bucketList, activeQuery, selectAsset }) => {
  const classes = useStyles();

  const parentRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: bucketList.length,
    getScrollElement: () => parentRef.current,
    estimateSize: index => bucketList[index].height,
    getItemKey: index => {
      const row = bucketList[index];
      if (row.type === 'header') return `header_${row.bucket.replaceAll('', '-')}`;
      return index;
    },
    overscan: 10,
  });

  return (
    <div
      ref={parentRef}
      style={{
        height: '100%',
        overflowY: 'auto',
      }}
    >
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {virtualizer.getVirtualItems().map(virtualItem => {
          const bucketElement = bucketList[virtualItem.index];

          const style: CSSProperties = {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: `${bucketElement.height}px`,
            transform: `translateY(${virtualItem.start}px)`,
          };

          if (bucketElement.type === 'header') {
            return (
              <div key={virtualItem.key} style={style} className={classes.groupHeaderRow}>
                <Typography className={classes.groupHeaderTitle}>{bucketElement.bucket}</Typography>
                {activeQuery?.name === 'Assets' && bucketElement.visibilityIcon}
              </div>
            );
          }

          return (
            <div key={virtualItem.key} style={style} className={classes.assetRow}>
              <QueryResult selectAsset={selectAsset} {...bucketElement} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GroupedResultsList;
