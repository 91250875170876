import { HttpResponseError, serenityFetch } from 'helpers/api';
import { OrganisationUpdateDetails } from './types';

export const getOrganisationDetails = async (id: string): Promise<Organisation> => {
  const response = await serenityFetch('GET', `/organisations/${id}`, null);
  HttpResponseError.detect(response);
  return (await response.json()) as Organisation;
};

export const updateOrganisationDetails = async (id: string, organisation: OrganisationUpdateDetails): Promise<Organisation> => {
  const response = await serenityFetch('PUT', `/organisations/${id}`, organisation);
  HttpResponseError.detect(response);
  return (await response.json()) as Organisation;
};

export const getOrganisationCurrencyMetadata = async (organisationId: string): Promise<OrganisationCurrency[]> => {
  const response = await serenityFetch('GET', `/organisations/${organisationId}/metadata/currency`, null);
  HttpResponseError.detect(response);
  return (await response.json()) as OrganisationCurrency[];
}

export const addOrganisationCurrencyMetadata = async (organisationId: string, addRequest: OrganisationCurrencyAdd): Promise<OrganisationCurrencyAdd> => {
  const response = await serenityFetch('POST', `/organisations/${organisationId}/metadata/currency`, addRequest);
  HttpResponseError.detect(response);
  return addRequest;
}
