import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Switch,
  FormControlLabel,
  Paper,
  Typography,
  TableBody,
  TableHead,
  Table,
  TableRow,
  TableCell,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stack,
  Skeleton,
  CircularProgress,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import { ClassNameMap } from '@mui/styles';
import GetApp from '@mui/icons-material/GetApp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { uniqBy } from 'lodash';
import { useSelector } from 'react-redux';
import { legEventTypes, preProcessLegs, processLegs } from 'helpers/legs';
import { isAssetWithDevice } from 'helpers/assets';
import { fetchEventReportsForDevice } from 'apis/trackstar/serenity';
import { mapU1ReportToReport } from 'apis/trackstar/maps';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useGetGeonamesForReports } from 'apis/rest/geocoding/hooks';
import AssetLabel from 'components/shared/assetLabel';
import AssetColourMarker from 'components/shared/assetColourMarker';
import OpenStreetMapCredit from 'components/shared/openStreetMapCredit';
import useDuration from 'hooks/units/useDuration';
import { selectDevicesById } from 'components/pages/sharing/helpers';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import useStyles from '../reporting-styles';
import Form, { Query } from './form';
import { VisibleLeg } from './types';
import useCSVExport from './export';
import useTimezone from "hooks/session/useTimezone";
import { updateSetting } from 'slices/settings.slice';
import { useAppDispatch } from 'store/useAppDispatch';
import { setAssetLegs } from 'slices/legs.slice';
import { displaySnackbar } from 'slices/app.slice';

interface Snack { id: string, text: string, type: string }

const NO_ASSETS: never[] = [];

const FormattedDateTime = ({ value, tz }: { value?: number, tz: string }): JSX.Element | null => {
  if (value === undefined) return null;
  const dt = DateTime.fromSeconds(value).setZone(tz);

  return (
    <>
      {dt.toFormat('dd MMM yyyy')}
      <br />
      {dt.toFormat('HH:mm:ss ZZZ')}
    </>
  );
};

const selectAssetsWithDevices = (assets: AssetBasic[]) => assets.filter(isAssetWithDevice);

const TripReports = (): JSX.Element => {
  const classes: ClassNameMap = useStyles();
  const t = useTranslations('pages.reporting');
  const duration = useDuration();
  const existingLegs = useSelector<ReduxState, Record<number, Leg[]>>(state => state.legs);

  const tableSettings = useSelector<ReduxState, ReduxState['settings']['tripReportsTable']>(state => state.settings.tripReportsTable);
  const { query, displayUTC } = tableSettings;

  const timezone = useTimezone();
  const displayTZ = displayUTC ? 'etc/UTC' : timezone;
  const dispatch = useAppDispatch();

  const setDisplayUTC = (value: boolean) => dispatch(updateSetting({ category: 'tripReportsTable', field: 'displayUTC', value }));
  const [displayGeonames, setDisplayGeonames] = useState(false);

  const setQuery = (newQuery: Query | null) => dispatch(updateSetting({ category: 'tripReportsTable', field: 'query', value: newQuery }));

  const assetListQuery = useGetAssetsList({ select: selectAssetsWithDevices }).query;

  const devicesByIdQuery = useGetDevicesList({ select: selectDevicesById }).query;

  const assetGroupsQuery = useGetAssetGroupsForOrganisation();

  useEffect(() => {
    if (assetListQuery.isError) {
      dispatch(displaySnackbar({
        id: 'getAssetListFailedSnackbar',
        text: t('getAssetListFailed'),
        type: 'error',
      }));
    }
  }, [assetListQuery, t]);

  const assets = assetListQuery.data ?? NO_ASSETS;

  const relevantAssets = useMemo(() => (
    assets.filter(asset => query?.assets.includes(asset.id))
  ), [assets, query?.assets]);

  const relevantAssetIds = useMemo(() => relevantAssets.map(a => a.id), [relevantAssets]);

  const isNow = (query?.until ?? 0) > DateTime.now().toSeconds();

  const eventReportsQuery = useQuery({
    queryKey: ['getEventReports', relevantAssetIds, query?.from, query?.until],
    queryFn: () => {
      if (!query || !relevantAssets.length) return [];

      const start = DateTime.fromSeconds(query.from).toUTC().toISO();
      const end = DateTime.fromSeconds(query.until).toUTC().toISO();

      console.log('getting legs for', relevantAssetIds, start, end);

      return Promise.all(
        relevantAssets.map(asset => (
          fetchEventReportsForDevice(asset.deviceId.toString(), query.from, query.until, legEventTypes)
            .then(result => preProcessLegs(result.map(mapU1ReportToReport), existingLegs[asset.id], asset.category, asset.deviceMake))
            .then(preProcessed => preProcessed.reportsAsc.length > 0
              ? processLegs(preProcessed.legStringMapped, preProcessed.legIndices, preProcessed.reportsAsc, existingLegs[asset.id], asset.category)
              : [])
            .then(legs => dispatch(setAssetLegs({ id: asset.id.toString(), legs })))
        ))
      );
    },
    enabled: relevantAssets.length > 0,
    staleTime: isNow ? 5000 : Infinity,
  });

  useEffect(() => {
    if (eventReportsQuery.isError) {
      displaySnackbar({
        id: 'getEventReportsFailedSnackbar',
        text: t('getEventReportsFailed'),
        type: 'error',
      });
    }
  }, [eventReportsQuery.isError, displaySnackbar, t]);


  const relevantLegs = useMemo(() => {
    if (!query || !relevantAssets) return [];

    return relevantAssets.flatMap(asset => {
      if (!existingLegs[asset.id] || !asset) return [];
      return existingLegs[asset.id].filter(leg => leg.reports.start.received > query.from && (leg.reports.end === undefined || leg.reports.end.received < query.until));
    });
  }, [existingLegs, relevantAssets, query]);

  const geonameReports = useMemo(
    () => uniqBy(relevantLegs.flatMap(leg => Object.values(leg.reports).filter((r: Report | undefined): r is Report => r !== undefined)), 'id'),
    [relevantLegs],
  );

  const geonamesQuery = useGetGeonamesForReports(
    geonameReports,
    { enabled: !!displayGeonames && geonameReports.length > 0 },
  ).query;

  const geonamesForReports = displayGeonames ? geonamesQuery.data : undefined;

  const visibleLegs = useMemo<VisibleLeg[]>(() => {
    if (!query || !relevantAssets) return [];

    return relevantAssets
      .reduce<VisibleLeg[]>((acc, asset) => {
        if (!existingLegs[asset.id] || !asset) return acc;
        return [
          ...acc,
          ...existingLegs[asset.id]
            .filter(leg => leg.reports.start.received > query.from && (leg.reports.end === undefined || leg.reports.end.received < query.until))
            .map(leg => {
              const geonames = {
                start: geonamesForReports?.[leg.reports.start.id],
                end: geonamesForReports?.[leg.reports.end.id],
              };
              return ({
                ...leg,
                from: geonames.start?.name ?? leg.from,
                to: geonames.end?.name ?? leg.to,
                geonames,
                asset,
                duration: ((leg.reports.end?.received ?? DateTime.now().toSeconds()) - leg.reports.start.received) * 1000,
                flightDuration: leg.reports.takeoff && leg.reports.landing ? (leg.reports.landing.received - leg.reports.takeoff.received) * 1000 : undefined,
              });
            })
            .sort((a, b) => a.reports.start.received - b.reports.start.received)
        ];
      }, []);
  }, [query, relevantAssets, existingLegs, geonamesForReports]);

  const legsByAsset = useMemo(() => visibleLegs.reduce<Record<VisibleLeg['asset']['id'], VisibleLeg[]>>((acc, leg) => {
    if (acc[leg.asset.id]) {
      acc[leg.asset.id].push(leg);
    } else {
      acc[leg.asset.id] = [leg];
    }
    return acc;
  }, {}), [visibleLegs]);

  const csvExport = useCSVExport();
  const downloadCSV = () => {
    if (!query) return;
    csvExport(legsByAsset, relevantAssets, displayTZ, query);
  };

  const devicesById = devicesByIdQuery.data;
  const assetGroups = assetGroupsQuery.data;

  return (
    <div className={classes.materialTable}>
      <Paper sx={theme => ({ marginBottom: theme.spacing(3) })}>
        <Box sx={theme => ({ padding: theme.spacing(4, 3) })}>
          <Form
            assets={assets}
            isLoading={assetListQuery.isFetching || eventReportsQuery.isFetching}
            setQuery={setQuery}
            query={query}
            devicesById={devicesById}
            assetGroups={assetGroups}
          />
        </Box>
      </Paper>

      {eventReportsQuery.isInitialLoading && (
        <Paper sx={theme => ({ margin: theme.spacing(1, 0, 4), padding: theme.spacing(3) })}>
          <Typography>{t('loadingTripReports')}</Typography>
        </Paper>
      )}

      {!eventReportsQuery.isLoading && relevantAssets.length ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <FormControlLabel
              control={(
                <Switch checked={displayUTC} onChange={event => setDisplayUTC(event.target.checked)} />
              )}
              label={t('displayTimesInUTC')}
              labelPlacement="start"
              sx={theme => ({ margin: theme.spacing(0, 1, 0, 0) })}
            />
            <FormControlLabel
              control={(
                <Switch checked={displayGeonames} onChange={event => setDisplayGeonames(event.target.checked)} />
              )}
              label={t('displayGeonames')}
              labelPlacement="start"
              sx={theme => ({ margin: theme.spacing(0, 1, 0, 0) })}
            />
            {displayGeonames && geonamesQuery.isFetching && <CircularProgress size="1.5rem" />}
          </Stack>
          <Button onClick={downloadCSV} size="large" variant="outlined" startIcon={<GetApp />} disabled={displayGeonames && geonamesQuery.isFetching}>
            {t('downloadCSV')}
          </Button>
        </Stack>
      ) : null}

      {!!relevantAssets && relevantAssets.map(asset => {
        const legs = legsByAsset[asset.id] ?? [];

        const totalDuration = legs.reduce((duration, leg) => duration + (leg.duration ?? 0), 0);

        const totalFlightDuration = legs.reduce((duration, leg) => duration + (leg.flightDuration ?? 0), 0);

        return (
          <Paper key={asset.id} sx={theme => ({ margin: theme.spacing(3, 0, 3) })}>
            {legs.length ? (
              <Accordion TransitionProps={{ unmountOnExit: true }} disableGutters sx={{ boxShadow: 'none' }}>
                <AccordionSummary
                  expandIcon={legs.length ? <ExpandMoreIcon fontSize="large" /> : undefined}
                // sx={theme => ({ '&:hover': { outline: `1px solid ${theme.palette.primary.mainHover}` } })}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    flex="1"
                    sx={theme => ({ margin: theme.spacing(1) })}
                  >
                    <Box>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <AssetColourMarker assetId={asset.id} />
                        <Typography variant="h3"><AssetLabel asset={asset} /></Typography>
                      </Stack>
                      <Typography variant="h6">{asset.make} {asset.model}</Typography>
                    </Box>
                    <Typography>{t('nTripReports', { count: legs.length })}</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={theme => ({ padding: theme.spacing(0, 0, 2) })}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: '15%' }}>{t('from')}</TableCell>
                        <TableCell sx={{ width: '15%' }}>{t('to')}</TableCell>
                        <TableCell sx={{ width: '12.5%' }}>{t('start')}</TableCell>
                        <TableCell sx={{ width: '12.5%' }}>{t('takeoff')}</TableCell>
                        <TableCell sx={{ width: '12.5%' }}>{t('landing')}</TableCell>
                        <TableCell sx={{ width: '12.5%' }}>{t('end')}</TableCell>
                        <TableCell sx={{ width: '10%' }}>{t('flightDuration')}</TableCell>
                        <TableCell sx={{ width: '10%' }}>{t('duration')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {legsByAsset[asset.id].map(leg => (
                        <TableRow key={leg.id}>
                          <TableCell>
                            {displayGeonames && leg.geonames.start === undefined ? <Skeleton width="100%" sx={{ display: 'inline-block' }} /> : leg.from}<br />
                            {leg.reports.start && (
                              <>{leg.reports.start.latitude.toFixed(5)}, {leg.reports.start.longitude.toFixed(5)}</>
                            )}
                          </TableCell>
                          <TableCell>
                            {displayGeonames && leg.geonames.end === undefined ? <Skeleton width="100%" sx={{ display: 'inline-block' }} /> : leg.to}<br />
                            {leg.reports.end && (
                              <>{leg.reports.end.latitude.toFixed(5)}, {leg.reports.end.longitude.toFixed(5)}</>
                            )}
                          </TableCell>
                          <TableCell>
                            <FormattedDateTime value={leg.reports.start.received} tz={displayTZ} />
                          </TableCell>
                          <TableCell>
                            <FormattedDateTime value={leg.reports.takeoff?.received} tz={displayTZ} />
                          </TableCell>
                          <TableCell>
                            <FormattedDateTime value={leg.reports.landing?.received} tz={displayTZ} />
                          </TableCell>
                          <TableCell>
                            <FormattedDateTime value={leg.reports.end?.received} tz={displayTZ} />
                          </TableCell>
                          <TableCell>{duration.fromMillis(leg.flightDuration)}</TableCell>
                          <TableCell>{duration.fromMillis(leg.duration)}</TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell colSpan={6} align="right">
                          <Typography sx={theme => ({ marginRight: theme.spacing(6) })}>{t('total')}:</Typography>
                        </TableCell>
                        <TableCell>{duration.fromMillis(totalFlightDuration)}</TableCell>
                        <TableCell>{duration.fromMillis(totalDuration)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionDetails>
              </Accordion>
            ) : (
              <Box sx={theme => ({ padding: theme.spacing(1) })}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  flex="1"
                  sx={theme => ({ margin: theme.spacing(2) })}
                >
                  <Box>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <AssetColourMarker assetId={asset.id} />
                      <Typography variant="h3"><AssetLabel asset={asset} /></Typography>
                    </Stack>
                    <Typography variant="h6">{asset.make} {asset.model}</Typography>
                  </Box>
                  <Typography>{t('nTripReports', { count: 0 })}</Typography>
                </Stack>
              </Box>
            )}
          </Paper>
        );
      })}
      <OpenStreetMapCredit />
    </div>
  );
};

export default TripReports;
