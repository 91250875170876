import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import type { FirebaseService } from './firebase-service';
import { FIREBASE_APP_CONFIG } from './config';
import { getMessaging } from 'firebase/messaging';
import { getToken } from 'firebase/messaging';


export default class TpcFirebaseService implements FirebaseService {
  public app;
  public storage;
  public firestore;
  public auth;
  public messaging;

  constructor() {
    if (import.meta.env.TEST === 'true') throw new Error('TpcFirebaseService not allowed in tests');
    this.app = initializeApp(FIREBASE_APP_CONFIG);
    this.storage = getStorage(this.app);
    this.firestore = getFirestore(this.app);
    this.auth = getAuth(this.app);
    this.messaging = getMessaging(this.app);
    navigator.serviceWorker.register('/src/firebase-messaging-custom-sw.js', {
      type: 'module',
      scope: '/src/',
    }).then((registration) => {
      console.log('---------- serviceWorkerRegistration', registration);
      getToken(this.messaging, {
        vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
        serviceWorkerRegistration: registration,
      });
    }).catch((error) => {
      console.error('---------- serviceWorkerRegistration error', error);
    });

  }
}
