import { type UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createBase, deleteBase, getBases, updateBase, updateBaseAssignment } from 'apis/rest/bases/bases-requests';
import type {
  ApiBaseAssetAssignment,
  Base,
  BaseAssetAssignment,
  BaseRequest,
  UpdateBaseAssignmentsRequest,
} from 'apis/rest/bases/bases-types';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { v4 as uuid } from 'uuid';

export const baseQueryKeys = {
  org: (org: string) => [org, 'bases'] as const,
};

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetBases = <T = Base[]>(options?: Options<Base[], T>) => {
  const organisationId = useOrganisationId();
  const queryKey = baseQueryKeys.org(organisationId);

  return useQuery({
    queryKey,
    queryFn: async () => await getBases(organisationId),
    ...options,
  });
};

export const useDeleteBaseMutator = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (baseId: string) => deleteBase(organisationId, baseId),
    onMutate: async baseId => {
      await queryClient.cancelQueries({ queryKey: baseQueryKeys.org(organisationId) });
      const previousData = queryClient.getQueryData(baseQueryKeys.org(organisationId));
      queryClient.setQueryData(baseQueryKeys.org(organisationId), (oldData?: Base[]) =>
        oldData?.filter(base => base.id !== baseId),
      );
      return { previousData };
    },
    onError: (err, groupId, context) => {
      queryClient.setQueryData(baseQueryKeys.org(organisationId), context?.previousData ?? []);
    },
  });
};

const mapBaseRequestToBase = (req: BaseRequest, assignments: BaseAssetAssignment[]): Base => ({
  ...req,
  assignments,
});

export const useUpdateBaseMutator = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (base: BaseRequest) => updateBase(organisationId, base),
    onMutate: async req => {
      await queryClient.cancelQueries({ queryKey: baseQueryKeys.org(organisationId) });
      const previousData = queryClient.getQueryData(baseQueryKeys.org(organisationId));
      queryClient.setQueryData(baseQueryKeys.org(organisationId), (oldData?: Base[]) =>
        (oldData?.filter(base => base.id !== req.id) ?? []).concat(
          mapBaseRequestToBase(req, oldData?.find(base => base.id === req.id)?.assignments ?? []),
        ),
      );
      return { previousData };
    },
    onError: (err, groupId, context) => {
      queryClient.setQueryData(baseQueryKeys.org(organisationId), context?.previousData ?? []);
    },
  });
};

export const useCreateBaseMutator = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationFn: (base: BaseRequest) => createBase(organisationId, base),
    onMutate: async req => {
      await queryClient.cancelQueries({ queryKey: baseQueryKeys.org(organisationId) });
      const previousData = queryClient.getQueryData(baseQueryKeys.org(organisationId));
      queryClient.setQueryData(baseQueryKeys.org(organisationId), (oldData?: Base[]) =>
        (oldData?.filter(base => base.id !== req.id) ?? []).concat(
          mapBaseRequestToBase(req, oldData?.find(base => base.id === req.id)?.assignments ?? []),
        ),
      );
      return { previousData };
    },
    onError: (err, groupId, context) => {
      queryClient.setQueryData(baseQueryKeys.org(organisationId), context?.previousData ?? []);
    },
  });
};

export const useUpdateBaseAssignmentsMutator = () => {
  const queryClient = useQueryClient();
  const organisationId = useOrganisationId();

  return useMutation({
    mutationKey: [organisationId, 'bases', 'assignments'],
    mutationFn: async ({ baseId, assignments }: UpdateBaseAssignmentsRequest) => {
      const requests = assignments.map(
        async assignment =>
          await updateBaseAssignment(organisationId, baseId, {
            id: assignment.id,
            assetId: assignment.assetId,
            startTime: assignment.startTime.toUTC().toISO({ suppressMilliseconds: true }),
            endTime:
              assignment.endTime !== null ? assignment.endTime.toUTC().toISO({ suppressMilliseconds: true }) : null,
          } satisfies ApiBaseAssetAssignment),
      );

      const results = await Promise.allSettled(requests);
      const errors = results.filter(r => r.status === 'rejected');
      if (errors.length > 0) {
        throw new Error(`failed to update ${errors.length}/${assignments.length} assignments`);
      }

      return;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: baseQueryKeys.org(organisationId) });
    },
  });
};
