import { Close } from '@mui/icons-material';
import {
  Box, Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useGetAssetGroupsForOrganisation } from 'apis/rest/assetGroups/hooks';
import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useGetDevicesList } from 'apis/rest/devices/hooks';
import { useGetTripsNoReportsForAssets } from 'apis/rest/trips/hooks';
import { LoadingIcon } from 'components/pages/loading/loadingIcon';
import { selectDevicesById } from 'components/pages/sharing/helpers';
import SelectAssets from 'components/pages/sharing/selectAssets';
import LabsAlert from 'components/shared/labs/Alert';
import OpenStreetMapCredit from 'components/shared/openStreetMapCredit';
import FeaturePageStaffAccessAlert from 'components/shared/pageStaffAccessAlert/feature';
import { useLabs } from 'contexts/labs/labs-context';
import { isAssetWithDevice } from 'helpers/assets';
import { useAssetsByDeviceId } from 'hooks/assets/useAssetsByDeviceId';
import { useDeviceIdByAssets } from 'hooks/assets/useDeviceIdByAssets';
import useOrganisationId from 'hooks/session/useOrganisationId';
import useTimezone from 'hooks/session/useTimezone';
import useSnackbar from 'hooks/useSnackbar';
import { DateTime } from 'luxon';
import type React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { updateSetting } from 'slices/settings.slice';
import { useAppDispatch } from 'store/types';
import { useTranslations } from 'use-intl';
import { DateRangeSelection } from './dateRangeSelection';
import Download from './download';
import TripReportsTable from './table';
import { TripTimelineView } from './timeline/tripTimelineView';
import useFeatureFlag from "../../../../hooks/useFeatureFlag";

const NO_ASSETS: never[] = [];

const selectAssetsWithDevices = (assets: AssetBasic[]) => assets.filter(isAssetWithDevice);

enum Layout {
  Timeline = 0,
  Table = 1,
}

export interface LegAssetTableColumn {
  title: string;
  field: string;
  size: 'small' | 'large';
}

type Query = ReduxState['settings']['tripAnalysis']['query'];

const isQueryValid = (query: Query, assets: AssetBasic[]): query is Required<Query> =>
  !!query.assets?.some(id => assets.find(asset => asset.id === id)) &&
  query.from !== undefined &&
  query.until !== undefined;

const TripAnalysisReport: React.FC = () => {
  const persistedQuery = useSelector<ReduxState, Query>(state => state.settings.tripAnalysis.query);
  const query = useMemo<Query & { from: number; until: number }>(
    () => ({
      ...persistedQuery,
      from: persistedQuery.from ?? DateTime.now().startOf('day').minus({ days: 6 }).toMillis(),
      until: persistedQuery.until ?? DateTime.now().endOf('day').toMillis(),
    }),
    [persistedQuery],
  );

  const newTripAnalysisTableEnabled = useFeatureFlag('newTripAnalysisTable');

  const timezone = useTimezone();
  const dispatch = useAppDispatch();
  const setQuery = useCallback(
    (newQuery: Query) => dispatch(updateSetting({ category: 'tripAnalysis', field: 'query', value: newQuery })),
    [dispatch],
  );
  const [datePickerRange, setDatePickerRange] = useState<{ from: string; until: string }>({
    from: DateTime.fromMillis(query.from).toISODate(),
    until: DateTime.fromMillis(query.until).toISODate(),
  });
  const [displayUTC, setDisplayUTC] = useState(false);
  const [displayEngineUsage, setDisplayEngineUsage] = useState(false);
  const [pendingExcludedColumns, setPendingExcludedColumns] = useState<string[]>([]);
  const t = useTranslations('pages.reporting');

  const devicesByIdQuery = useGetDevicesList({ select: selectDevicesById }).query;
  const assetGroupsQuery = useGetAssetGroupsForOrganisation();

  const organisationId = useOrganisationId();
  const intercom = useIntercom();
  // biome-ignore lint/correctness/useExhaustiveDependencies: only send once
  useEffect(() => {
    intercom.trackEvent('tripAnalysisView', {
      organisation_id: organisationId,
      time_at: Date.now(),
    });
  }, [intercom]);

  const snackbar = useSnackbar();

  const assetListQuery = useGetAssetsList<AssetWithDevice[]>({ select: selectAssetsWithDevices }).query;

  useEffect(() => {
    if (assetListQuery.isError) {
      snackbar.display({
        id: 'getAssetListFailedSnackbar',
        text: t('getAssetListFailed'),
        type: 'error',
      });
    }
  }, [assetListQuery, snackbar, t]);

  const assets = assetListQuery.data ?? NO_ASSETS;
  const deviceIds = useDeviceIdByAssets(assets);
  const assetsByDeviceId = useAssetsByDeviceId(assets);

  const tripsQuery = useGetTripsNoReportsForAssets(query?.assets ?? NO_ASSETS, query.from, query.until);

  const legAssetTableColumns: LegAssetTableColumn[] =
    [
      { title: t('tripAnalysis.tableColumns.tripRefId'), field: 'tripRefId', size: 'small' },
      { title: t('tripAnalysis.tableColumns.engineUsageReferenceId'), field: 'engineUsageReferenceId', size: 'small' },
      { title: t('tripAnalysis.tableColumns.startLocation'), field: 'startLocation', size: 'large' },
      { title: t('tripAnalysis.tableColumns.endLocation'), field: 'endLocation', size: 'large' },
      { title: t('tripAnalysis.tableColumns.startDate'), field: 'startDate', size: 'small' },
      { title: t('tripAnalysis.tableColumns.engineTime'), field: 'engineTime', size: 'small' },
      { title: t('tripAnalysis.tableColumns.distance'), field: 'distance', size: 'small' },
      { title: t('tripAnalysis.tableColumns.movingTime'), field: 'movingTime', size: 'small' },
      { title: t('tripAnalysis.tableColumns.movingStart'), field: 'movingStart', size: 'small' },
      { title: t('tripAnalysis.tableColumns.movingStop'), field: 'movingStop', size: 'small' },
      { title: t('tripAnalysis.tableColumns.airborneTime'), field: 'airborneTime', size: 'small' },
      { title: t('tripAnalysis.tableColumns.takeOff'), field: 'takeOff', size: 'small' },
      { title: t('tripAnalysis.tableColumns.landing'), field: 'landing', size: 'small' },
      { title: t('tripAnalysis.tableColumns.engineOn'), field: 'engineOn', size: 'small' },
      { title: t('tripAnalysis.tableColumns.engineOff'), field: 'engineOff', size: 'small' },
      { title: t('tripAnalysis.tableColumns.engineOnCoordinates'), field: 'engineOnCoordinates', size: 'small' },
      { title: t('tripAnalysis.tableColumns.engineOffCoordinates'), field: 'engineOffCoordinates', size: 'small' },
    ]

  const relevantAssets = useMemo(
    () => assets.filter(asset => query.assets?.includes(asset.id)),
    [assets, query?.assets],
  );

  const availableColumns = useMemo<LegAssetTableColumn[]>( () =>
    legAssetTableColumns.filter(col => displayEngineUsage || !col.field.includes('engine'))
  ,[legAssetTableColumns, displayEngineUsage])

  const filteredColumns = useMemo<LegAssetTableColumn[]>( () => availableColumns.filter(col =>
    !pendingExcludedColumns.includes(col.field)), [pendingExcludedColumns, availableColumns])

  const [layout, setLayout] = useState<Layout>(Layout.Table);

  const displayTZ = displayUTC ? 'Etc/UTC' : timezone;

  const onSetDateRange = useCallback(
    (from: string, until: string) => {
      const fromDate = DateTime.fromISO(from, { zone: timezone }).startOf('day');
      const untilDate = DateTime.fromISO(until, { zone: timezone }).endOf('day');
      setDatePickerRange({ from: fromDate.toISO(), until: untilDate.toISO() });

      setQuery({ ...query, from: fromDate.toMillis(), until: untilDate.toMillis() });
    },
    [setQuery, query, timezone],
  );

  const onColumnSelect = useCallback( (event: SelectChangeEvent<string[]>) => {
    setPendingExcludedColumns(event.target.value)
    console.log(event.target.value);
  }, [])

  const [selectedDeviceIds, setSelectedDeviceIds] = useState<number[]>(() => relevantAssets.map(a => a.deviceId));

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const selectedAssets = assets.filter(a => selectedDeviceIds.includes(a.deviceId)).map(a => a.id);
    setQuery({ ...query, assets: selectedAssets });
  }, [selectedDeviceIds, assets]);

  const queryAssets = useMemo(() => query?.assets ?? [], [query?.assets]);

  const [displayMessage, setDisplayMessage] = useState(() => {
    try {
      return !JSON.parse(sessionStorage.getItem('tripAnalysis.betaMessageDismissed') ?? '');
    } catch (error) {
      return true;
    }
  });

  useEffect(() => {
    sessionStorage.setItem('tripAnalysis.betaMessageDismissed', (!displayMessage).toString());
  }, [displayMessage]);

  const labs = useLabs();

  const devicesById = devicesByIdQuery.data ?? [];
  const assetGroups = assetGroupsQuery.data;
  const maxShownColumnFilter = 3;

  const isLoading = devicesByIdQuery.isFetching || assetGroupsQuery.isFetching || assetListQuery.isLoading;

  if (devicesByIdQuery.isLoading) {
    return <LoadingIcon size={5} />;
  }

  return (
    <>
      <Collapse in={displayMessage && labs?.value}>
        <LabsAlert
          sx={{ mb: 3, p: 3 }}
          action={
            <IconButton
              aria-label={t('tripAnalysis.infoMessage.dismiss')}
              color="inherit"
              onClick={() => setDisplayMessage(false)}
            >
              <Close fontSize="inherit" />
            </IconButton>
          }
        >
          {t.rich('tripAnalysis.infoMessage.content', { break: () => <br /> })}
        </LabsAlert>
      </Collapse>
      <FeaturePageStaffAccessAlert feature="reporting.newTripReports" />
      <Paper sx={{ my: 3 }} elevation={0}>
        <Stack direction="row" spacing={2} p={3}>
          <FormControl variant="outlined" sx={{ flex: 1 }}>
            <SelectAssets
              label={t('selectAssets.label')}
              deviceIds={deviceIds}
              selectedDeviceIds={selectedDeviceIds}
              assetsByDeviceId={assetsByDeviceId}
              devicesById={devicesById}
              setSelectedDeviceIds={value => {
                setSelectedDeviceIds(value);
              }}
              disabled={false}
              assetGroups={assetGroups}
              isLoading={isLoading}
              featureName="reporting.newTripReports"
            />
          </FormControl>
          <FormControl>
            <DateRangeSelection
              assets={assets.filter(a => queryAssets.includes(a.id))}
              from={DateTime.fromISO(datePickerRange.from, { zone: timezone }).toISODate()}
              until={DateTime.fromISO(datePickerRange.until, { zone: timezone }).toISODate()}
              onChange={onSetDateRange}
            />
          </FormControl>
        </Stack>
      </Paper>

      <Stack direction="row" spacing={3} alignItems="center">
        <ToggleButtonGroup
          sx={{ height: 38 }}
          value={layout}
          onChange={(_, value) => {
            if (value !== null) setLayout(value);
          }}
          exclusive
        >
          <ToggleButton value={Layout.Table}>{t('tripAnalysis.toggles.table')}</ToggleButton>
          <ToggleButton value={Layout.Timeline}>{t('tripAnalysis.toggles.timeline')}</ToggleButton>
        </ToggleButtonGroup>
        <FormControlLabel
          control={
            <Switch checked={displayEngineUsage} onChange={event => setDisplayEngineUsage(event.target.checked)} />
          }
          label={t('displayEngineUsage')}
          labelPlacement="start"
          sx={{ mr: 1 }}
        />
        {timezone !== 'Etc/UTC' && (
          <FormControlLabel
            control={<Switch checked={displayUTC} onChange={event => setDisplayUTC(event.target.checked)} />}
            label={t('displayTimesInUTC')}
            labelPlacement="start"
            sx={{ mr: 1 }}
          />
        )}
        <Box flex={1} />
        {newTripAnalysisTableEnabled && (
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel id="column-select-label">{t('tripAnalysis.tableColumns.title')}</InputLabel>
            <Select
              multiple
              labelId="column-select-label"
              id="column-select"
              value={pendingExcludedColumns}
              onChange={onColumnSelect}
              onClose={() => {}}
              label={t('tripAnalysis.tableColumns.title')}
              renderValue={(selectedCols => {
                  const filteredColumns = availableColumns.filter(col => !selectedCols.includes(col.field))
                  const displayNames = filteredColumns.reduce<string[]>((acc, col, index) => {
                    if (index < maxShownColumnFilter) {
                      acc.push(col.title)
                    }
                    return acc;
                  }, []).join(', ')
                  return filteredColumns.length > maxShownColumnFilter
                    ? displayNames.concat(` ${t('tripAnalysis.tableColumns.nMore', { n: filteredColumns.length - maxShownColumnFilter})}`)
                    : displayNames
                }
              )}
              sx={{
                height: '3rem',
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                PaperProps: {
                  style: {
                    minWidth: 'auto',
                    width: 'auto',
                  },
                },
              }}
            >
              {availableColumns.map(column => (
                <MenuItem key={column.field} value={column.field} selected={!pendingExcludedColumns.includes(column.field)}>
                  <Checkbox checked={!pendingExcludedColumns.includes(column.field)} />
                  <ListItemText primary={column.title} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Download
          timezone={displayTZ}
          query={query}
          assets={relevantAssets}
          disabled={assetListQuery.isLoading || tripsQuery.isLoading || !isQueryValid(query, assets)}
        />
      </Stack>

      {tripsQuery.isLoading && isQueryValid(query, assets) && (
        <Paper sx={{ my: 3, p: 6 }} elevation={0}>
          <Typography align="center">{t('loadingTrips')}</Typography>
        </Paper>
      )}

      {!tripsQuery.isLoading && tripsQuery.data && isQueryValid(query, assets) && (
        <>
          {layout === Layout.Timeline && (
            <TripTimelineView
              assets={relevantAssets}
              trips={tripsQuery.data}
              displayEngineUsage={displayEngineUsage}
              query={query}
              timezone={displayTZ}
            />
          )}
          {layout === Layout.Table && (
            <TripReportsTable
              assets={relevantAssets}
              trips={tripsQuery.data}
              displayEngineUsage={displayEngineUsage}
              query={query}
              timezone={displayTZ}
              columns={filteredColumns}
            />
          )}
          <OpenStreetMapCredit />
        </>
      )}
    </>
  );
};

export default TripAnalysisReport;
