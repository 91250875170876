import { useGetAssetsList } from 'apis/rest/assets/hooks';
import { useNotification } from 'contexts/notification/useNotification';
import useFeature from 'hooks/features/useFeature';
import useSnackbar from 'hooks/useSnackbar';

const getCrossingType = (type: number): string => {
  switch (type) {
    case 0:
      return 'N/A';
    case 1:
      return 'Crossed';
    case 2:
      return 'Entered';
    case 3:
      return 'Left';
    default:
      return 'N/A';
  }
};


export const GeofenceNotificationView = () => {
  const enabled = useFeature('manage.geofencing');
  const snackbar = useSnackbar();
  const assets = useGetAssetsList();

  const handleCrossingMessage = (message: TPCNotification) => {
    snackbar.display({
      type: 'info',
      text: `${assets.query.data?.find(x => x.id === message.metadata.AssetId)?.name} ${getCrossingType(message.metadata.CrossType)} Geofence ${message.metadata.GeofenceName}`,
      id: `geofenceCrossing.${message.metadata.ReportId}`,
    });
  };

  useNotification('geofenceCrossed', handleCrossingMessage, enabled);
  return null;
};
