import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import type { UseQueryResult } from '@tanstack/react-query';
import type { EngineUsage } from 'apis/rest/engineUsage/types';
import { useGetInferredEventsByReportIdForAsset } from 'apis/rest/inferredEvents/hooks';
import AssetColourMarker from 'components/shared/assetColourMarker';
import AssetLabel from 'components/shared/assetLabel';
import type { HttpResponseError } from 'helpers/api';
import useDistance from 'hooks/units/useDistance';
import useDuration from 'hooks/units/useDuration';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useSize } from 'hooks/useSize';
import { DateTime } from 'luxon';
import React, {useEffect, useMemo, useReducer, useRef, useState} from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslations } from 'use-intl';
import { formatDateTime } from 'utils/time';
import EngineCycles from '../engineCycles';
import Score from '../score';
import { reducer } from '../timeline/tripTimelineView';
import { LoadTrip, TripDetail } from '../tripDetails/tripDetail';
import {LegAssetTableColumn} from "../report";

interface LegReportsAssetTableProps {
  query: { from: number; until: number };
  trips: Trip[];
  asset: AssetWithDevice;
  timezone: string;
  engineUsageQuery: UseQueryResult<EngineUsage[], HttpResponseError>;
  displayEngineUsage: boolean;
  isExpanded: boolean;
  setExpanded: (assetId: number | undefined) => void;
  columns: LegAssetTableColumn[];
}

export interface LegAssetTableRow {
  cells: Record<string, string | number | null>;
  data: Record<string, string | number | null>;
  order: number;
  id: string;
}

const getSummaryGridProps = (displayDurationMetrics: boolean, displayEngineUsage: boolean) => {
  return {
    gridTemplateColumns: `minmax(max-content, 1fr) 12rem 12rem 12rem ${displayDurationMetrics ? '12rem 12rem' : ''} ${displayEngineUsage ? '12rem 12rem' : ''} max-content`,
    gridTemplateAreas: {
      xs: `"asset count duration distance ${displayDurationMetrics ? 'airborneDuration movementDuration' : ''} ${displayEngineUsage ? 'engineStarts engineDuration' : ''} action"`,
    },
  };
};

interface SummaryProps {
  asset: AssetWithDevice;
  trips: Trip[];
  displayEngineUsage: boolean;
  engineUsages: EngineUsage[] | undefined;
  isLoadingEngineUsages: boolean;
}

const Summary = ({ asset, trips, displayEngineUsage, engineUsages = [], isLoadingEngineUsages }: SummaryProps) => {
  const t = useTranslations('pages.reporting.tripAnalysis.assetTable.summary');
  const distance = useDistance();
  const duration = useDuration();

  const containerRef = useRef<HTMLDivElement>(null);
  const size = useSize(containerRef);

  const enableDurationMetrics = useFeatureFlag('frontendAirborneMovementTime');
  const displayDurationMetrics = useMemo(
    () => enableDurationMetrics ? ((!displayEngineUsage && size.width > 1200) || size.width > 1500) : false,
    [enableDurationMetrics, size.width, displayEngineUsage],
  );

  const totals = useMemo(
    () =>
      trips.reduce(
        (sum, trip) => ({
          duration: sum.duration + (trip.duration ?? 0),
          distance: sum.distance + (trip.distance ?? 0),
          airborneDuration: sum.airborneDuration + (trip.airborneDuration ?? 0),
          movementDuration: sum.movementDuration + (trip.movementDuration ?? 0),
        }),
        {
          duration: 0,
          distance: 0,
          airborneDuration: 0,
          movementDuration: 0,
        },
      ),
    [trips],
  );

  const totalEngineDuration = useMemo(
    () => engineUsages.reduce((sum, item) => sum + (item.duration ?? 0), 0),
    [engineUsages],
  );

  return (
    <Box
      mx={1}
      my={2}
      flex={1}
      display="grid"
      {...getSummaryGridProps(displayDurationMetrics, displayEngineUsage)}
      gap={3}
      alignItems="center"
      ref={containerRef}
    >
      <Box gridArea="asset">
        <Stack direction="row" alignItems="center" spacing={1}>
          <AssetColourMarker assetId={asset.id} />
          <Typography variant="h3">
            <AssetLabel asset={asset} />
          </Typography>
        </Stack>
        <Typography variant="h6">
          {asset.make} {asset.model}
        </Typography>
      </Box>
      <Box gridArea="count">
        <Score label={t('count')} value={trips.length} />
      </Box>
      <Box gridArea="duration">
        <Score label={t('duration')} value={duration.fromMillis(totals.duration)} />
      </Box>
      <Box gridArea="distance">
        <Score label={t('distance')} value={distance.create(totals.distance * 1000).format()} />
      </Box>
      {displayDurationMetrics && (
        <>
          <Box gridArea="airborneDuration">
            <Score label={t('airborneDuration')} value={duration.fromMillis(totals.airborneDuration)} />
          </Box>
          <Box gridArea="movementDuration">
            <Score label={t('movementDuration')} value={duration.fromMillis(totals.movementDuration)} />
          </Box>
        </>
      )}
      {displayEngineUsage && (
        <Box gridArea="engineStarts">
          <Score
            label={t('engineCycles')}
            value={<EngineCycles count={engineUsages.length} />}
            loading={isLoadingEngineUsages}
          />
        </Box>
      )}
      {displayEngineUsage && (isLoadingEngineUsages || engineUsages.length > 0) && (
        <Box gridArea="engineDuration">
          <Score
            label={t('engineDuration')}
            value={duration.fromMillis(totalEngineDuration)}
            loading={isLoadingEngineUsages}
          />
        </Box>
      )}
    </Box>
  );
};

export const LegReportsAssetTable = ({
  trips: allTrips,
  asset,
  query,
  engineUsageQuery,
  timezone,
  displayEngineUsage,
  isExpanded,
  setExpanded,
  columns,
}: LegReportsAssetTableProps) => {
  const t = useTranslations('pages.reporting.tripAnalysis.assetTable');
  const distance = useDistance();
  const duration = useDuration();
  const tableContainerRef = useRef(null)
  const tableHeaderRef = useRef(null)
  const [visibleWidth, setVisibleWidth] = useState(0);

  const cellDefaultString = '—';

  const trips = useMemo(
    () => allTrips.filter(trip => trip.assetId === asset.id).sort((a, b) => a.startTime - b.startTime),
    [asset, allTrips],
  );

  const inferredEventsQuery = useGetInferredEventsByReportIdForAsset(
    asset.id,
    DateTime.fromMillis(query.from),
    DateTime.fromMillis(query.until),
  );

  const filteredColumns = columns.filter(column => !(column.field.includes('engine') && !displayEngineUsage))

  useEffect( () => {
    const handleResize = () => {
      if (tableContainerRef.current) {
        setVisibleWidth(tableContainerRef.current.offsetWidth);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  const tableRows = useMemo<LegAssetTableRow[]>(() => {
    const tripRows = trips.map<LegAssetTableRow>((trip, index) => ({
      cells: {
        tripRefId: trip.referenceId ?? null,
        startLocation: trip.start ?? null,
        endLocation: trip.end ?? null,
        startDate: formatDateTime(trip.startTime, timezone, true),
        movingTime: duration.fromMillis(trip.movementDuration),
        airborneTime: duration.fromMillis(trip.airborneDuration),
        distance: trip.distance === undefined ? null : distance.create(trip.distance * 1000).format(),
        movingStart: formatDateTime(trip.movementStart, timezone, true),
        movingStop: formatDateTime(trip.movementEnd, timezone, true),
        takeOff: formatDateTime(trip.airborneStart, timezone, true),
        landing: formatDateTime(trip.airborneEnd, timezone, true),
      },
      data: {
        tripId: trip.id ?? null,
        reportCount: trip.reports.length,
        assetId: trip.assetId,
      },
      order: trip.startTime,
      id: trip.id,
    }))

    const engineUsages = engineUsageQuery.data?.filter(engineUsage => engineUsage.assetId === asset.id) ?? [];

    if (displayEngineUsage) {
      let tripEngineRows = tripRows;
      for (const item of engineUsages) {
        const startTripIndex = tripRows.findIndex(r => r.order > item.startTime);
        const endTripIndex = tripRows.findLastIndex(r => r.order < (item.endTime ?? Infinity));

        if (endTripIndex >= startTripIndex && startTripIndex >= 0) {
          tripEngineRows[startTripIndex].cells['engineUsageReferenceId'] = item.referenceId ?? null;
          tripEngineRows[startTripIndex].cells['engineOn'] = formatDateTime(item.startTime, timezone, true) ?? null;
          tripEngineRows[startTripIndex].cells['engineOnCoordinates'] = (item.startLatitude && item.startLongitude) ? `${item.startLatitude?.toFixed(3)}, ${item.startLongitude?.toFixed(3)}` : null;
          tripEngineRows[endTripIndex].cells['engineTime'] = duration.fromMillis(item.duration) ?? null;
          tripEngineRows[endTripIndex].cells['engineOff'] = formatDateTime(item.endTime, timezone, true) ?? null;
          tripEngineRows[endTripIndex].cells['engineOffCoordinates'] = (item.endLatitude && item.endLongitude) ? `${item.endLatitude?.toFixed(3)}, ${item.endLongitude?.toFixed(3)}` : null;
        } else {
          // if end < start then it is a tripless engine operation - generate a sparse row
          tripEngineRows.push({
            cells: {
              engineEventId: item.referenceId ?? null,
              engineOn: formatDateTime(item.startTime, timezone, true) ?? null,
              engineOnCoordinates: (item.startLatitude && item.startLongitude) ? `${item.startLatitude?.toFixed(3)}, ${item.startLongitude?.toFixed(3)}` : null,
              engineTime: duration.fromMillis(item.duration) ?? null,
              engineOff: formatDateTime(item.endTime, timezone, true) ?? null,
              engineOffCoordinates: (item.endLatitude && item.endLongitude) ? `${item.endLatitude?.toFixed(3)}, ${item.endLongitude?.toFixed(3)}` : null,
            },
            data: {
              assetId: item.assetId,
            },
            order: item.startTime,
            id: item.referenceId ?? item.startTime.toString(),
          })
        }
      }
      return tripEngineRows.sort((a, b) => a.order - b.order);
    }
    return tripRows;
  }, [trips, engineUsageQuery, duration])

  const theme = useTheme();
  const selectedBgColor = alpha(theme.palette.primary.main, 0.05);

  const { state } = useLocation();
  const [tripState, dispatch] = useReducer(reducer, { trips: [], findTripAround: state?.findTripAround });
  useEffect(() => dispatch({ type: 'SET_TRIPS', trips }), [trips]);

  const navigate = useNavigate();
  useEffect(() => {
    if (state) {
      navigate('.', { state: undefined });
    }
  }, [state, navigate]);


  return (
    <Paper sx={{ my: 3 }} elevation={0} ref={tableContainerRef}>
      <Accordion
        slotProps={{ transition: { unmountOnExit: true, mountOnEnter: true } }}
        disableGutters
        sx={{ boxShadow: 'none', '&.Mui-disabled': { bgcolor: 'common.white' } }}
        expanded={isExpanded}
        onChange={(event, value) => setExpanded(value ? asset.id : undefined)}
        disabled={trips.length === 0}
      >
        <AccordionSummary expandIcon={<ExpandMore fontSize="large" />}>
          <Summary
            asset={asset}
            trips={trips}
            displayEngineUsage={displayEngineUsage}
            engineUsages={engineUsageQuery.data?.filter(engineUsage => engineUsage.assetId === asset.id)}
            isLoadingEngineUsages={engineUsageQuery.isLoading}
          />
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <Box pb={2} style={{overflowX: 'auto'}} ref={tableHeaderRef}>
            <Table>
              <TableHead>
                <TableRow>
                  {filteredColumns.map(column => {
                    const width = (column.size === 'small') ? 150 : 250;
                    return (
                    <TableCell sx={{
                      minWidth: width
                    }}>{column.title}</TableCell>
                  )}
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows.map((row, index) => {
                  const isSelectedRow = row.id === tripState.selectedId;
                  return (
                    <>
                      <TableRow
                        sx={{
                          cursor: row.cells['startLocation'] ? 'pointer' : 'default',
                          bgcolor: isSelectedRow ? selectedBgColor : undefined,
                          ':hover': { bgcolor: selectedBgColor },
                          '& > .MuiTableCell-root': { border: 0 },
                        }}
                        onClick={() => {
                          if(row.cells['startLocation']) {
                            dispatch({ type: 'SET_SELECTED_ID', tripId: isSelectedRow ? undefined : row.id})
                          }}
                        }
                      >
                        {filteredColumns.map(column => (
                          <TableCell>
                            <Typography>{row.cells[column.field] ?? cellDefaultString}</Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow
                        sx={{
                          bgcolor: selectedBgColor,
                          '& > .MuiTableCell-root': { p: '0 !important' },
                        }}
                      >
                        <TableCell
                          colSpan={100}
                        >
                          <div style={{
                            width: visibleWidth,
                            position: "sticky",
                            left: 0,
                            transition: "margin 0.05s",
                          }}>
                            <Collapse in={isSelectedRow} unmountOnExit mountOnEnter>
                              {row.data['tripId'] && (
                                <LoadTrip
                                  tripId={row.data['tripId'].toString()}
                                  assetId={row.data['assetId']?.toString() ?? ''}
                                  loaded={innerTrip => (
                                    <TripDetail
                                      trip={innerTrip}
                                      inferredEvents={inferredEventsQuery.data}
                                      dispatch={dispatch}
                                      hideHeader
                                      timezone={timezone}
                                    />
                                  )}
                                />
                              )}
                            </Collapse>
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

