import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import type { HttpResponseError } from 'helpers/api';
import useOrganisationId from 'hooks/session/useOrganisationId';
import { insightsQueryKeys } from './queryKeys';
import { getActiveDays, getInsightsSummary, getInsightsSummaryOld, getInsightsDimensions } from './requests';
import type { Insight, InsightDimensions, InsightDimensionsRequest, InsightsRequest } from './types';

type Options<QueryData, SelectedData> = Omit<
  UseQueryOptions<QueryData, HttpResponseError | Error, SelectedData>,
  'queryKey' | 'queryFn'
>;

export const useGetInsightsSummary = <T = Insight>(request: InsightsRequest, options?: Options<Insight, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = insightsQueryKeys.summary(organisationId, request);
  return useQuery<Insight, HttpResponseError | Error, T>({
    queryKey,
    queryFn: () => getInsightsSummary(organisationId, request),
    ...options,
  });
};

export const useGetInsightsSummaryOld = <T = Insight>(request: InsightsRequest, options?: Options<Insight, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = insightsQueryKeys.summary(organisationId, request);
  return useQuery<Insight, HttpResponseError | Error, T>({
    queryKey,
    queryFn: () => getInsightsSummaryOld(organisationId, request),
    ...options,
  });
};

export const useGetActiveDays = (request: Pick<InsightsRequest, 'from' | 'to' | 'dimensions'>) => {
  const organisationId = useOrganisationId();
  return useQuery({
    queryKey: insightsQueryKeys.activeDays(organisationId, request),
    queryFn: () => getActiveDays(organisationId, request)
  })
};

  export const useGetInsightsDimensions = <T = InsightDimensions>(request: InsightDimensionsRequest, options?: Options<InsightDimensions, T>) => {
  const organisationId = useOrganisationId();
  const queryKey = insightsQueryKeys.dimensions(organisationId, request);
  return useQuery<InsightDimensions, HttpResponseError | Error, T>({
    queryKey,
    queryFn: () => getInsightsDimensions(organisationId, request),
    ...options,
  });
};
