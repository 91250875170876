import type { InferredEventId } from 'apis/rest/inferredEvents/types';
import type { Input } from 'csv-stringify/dist/esm/sync';
import { getMostSignificantEvent } from 'helpers/events';
import { DateTime } from 'luxon';

type BareTrip = Pick<Trip, 'assetId' | 'start' | 'end' | 'startTime' | 'endTime' | 'airborneStart' | 'airborneEnd' | 'movementStart' | 'movementEnd'> & {
  reports: Pick<TripSlimReport, 'id' | 'events' | 'coords'>[];
};
type BareAsset = Pick<AssetBasic, 'id'>;

const getEventFromReport = (
  report: Pick<TripSlimReport, 'id' | 'events'> | undefined,
  inferredEvents: Record<number, InferredEventId[]> | undefined,
) => {
  if (report === undefined) return undefined;
  return getMostSignificantEvent(
    {
      events: report.events,
      inferredEvents: inferredEvents?.[report.id],
    },
    '',
  ).eventId;
};

export const transformTripsToCsvRecords = <T extends BareTrip = BareTrip, A extends BareAsset = BareAsset>(
  trips: T[],
  assets: A[],
  inferredEvents: Record<number, InferredEventId[]> | undefined,
  timezone: string,
  format: {
    assetLabel: (asset: A) => string;
    duration: (trip: T) => string;
    distance: (trip: T) => string;
    airborneDuration: (trip: T) => string;
    movementDuration: (trip: T) => string;
  },
): Input[] =>
  assets.reduce<Input>((acc, asset) => {
    const tripsForAssets: T[] = trips.filter(trip => trip.assetId === asset.id);
    if (!tripsForAssets.length) return acc;
    return acc.concat(
      tripsForAssets.map(trip => {
        const startReport = trip.reports.at(0);
        const endReport = trip.reports.at(-1);
        return {
          asset,
          assetLabel: format.assetLabel(asset),
          from: trip.start,
          to: trip.end,
          start: {
            event: getEventFromReport(startReport, inferredEvents),
            time: DateTime.fromMillis(trip.startTime).setZone(timezone).toISO({ suppressMilliseconds: true }),
            coordinates: startReport
              ? `${startReport?.coords[1].toFixed(3)}, ${startReport?.coords[0].toFixed(3)}`
              : undefined,
          },
          end: {
            event: trip.endTime ? getEventFromReport(endReport, inferredEvents) : undefined,
            time: trip.endTime
              ? DateTime.fromMillis(trip.endTime).setZone(timezone).toISO({ suppressMilliseconds: true })
              : undefined,
            coordinates:
              trip.endTime && endReport
                ? `${endReport.coords[1].toFixed(3)}, ${endReport.coords[0].toFixed(3)}`
                : undefined,
          },
          duration: format.duration(trip),
          distance: format.distance(trip),
          airborneDuration: format.airborneDuration(trip),
          movementDuration: format.movementDuration(trip),
          airborneStart: trip.airborneStart ? DateTime.fromMillis(trip.airborneStart).setZone(timezone).toISO({ suppressMilliseconds: true }) : null,
          airborneEnd: trip.airborneEnd ? DateTime.fromMillis(trip.airborneEnd).setZone(timezone).toISO({ suppressMilliseconds: true }) : null,
          movementStart: trip.movementStart ? DateTime.fromMillis(trip.movementStart).setZone(timezone).toISO({ suppressMilliseconds: true }) : null,
          movementEnd: trip.movementEnd ? DateTime.fromMillis(trip.movementEnd).setZone(timezone).toISO({ suppressMilliseconds: true }) : null,
        };
      }),
    );
  }, []);
