import { getActiveDays } from './requests';
import type { InsightDimensionsRequest, InsightsRequest } from './types';

export const insightsQueryKeys = {
  org: (organisationId: string) => [organisationId, 'insights'] as const,
  dimensions: (organisationId: string, request: InsightDimensionsRequest) => 
    [...insightsQueryKeys.org(organisationId), 'dimensions', request] as const,
  summary: (organisationId: string, request: InsightsRequest) =>
    [...insightsQueryKeys.org(organisationId), 'summary', request] as const,
  activeDays: (organisationId: string, request: Pick<InsightsRequest, 'from' | 'to' | 'dimensions'>) =>
    [
      ...insightsQueryKeys.org(organisationId),
      'activeDays',
      request.from.toISODate(),
      request.to.toISODate(),
      request.dimensions.join(','),
    ] as const,
};

