import React from 'react';
import { Link, useParams } from 'react-router';
import Page from 'components/pages/page';
import {
  Container,
  Button,
  Typography,
  Grid,
} from '@mui/material';
import { useTranslations } from 'use-intl';
import { useGetAssetBasicAssignedToDevice } from 'apis/rest/assets/hooks';
import { useGetDeviceBasic } from 'apis/rest/devices/hooks';
import LinkAbove from 'components/shared/linkAbove';
import { SettingsMenuPageWrapper } from 'components/shared/settingsMenu';
import LoadingPage from 'components/pages/loading';
import { useAssetLabel } from 'components/shared/assetLabel';
import { useUiSettings } from 'hooks/settings/useUiSettings';
import { DeviceConfiguration } from './device-configuration';
import { DeviceGeneral } from './device-general';
import { DeviceAsset } from './device-asset';
import { DeviceRates } from './device-rates';
import { DeviceConfigurationHistory } from './device-configuration-history';
import { DeviceActivation } from './device-activation';

type SerialType = ReduxState['uiSettings']['ui']['serialType'];
enum SerialTypeKey {
  TP_SERIAL = 'tracPlusSerial',

  IMEI = 'imei',
  MANUFACTURER_SERIAL = 'manufacturerSerial',
}

const serialTypeKeys: Record<SerialType, SerialTypeKey> = {
  tpSerial: SerialTypeKey.TP_SERIAL,
  imei: SerialTypeKey.IMEI,
  manufacturerSerial: SerialTypeKey.MANUFACTURER_SERIAL,
};

const DevicePage = (): JSX.Element => {
  const t = useTranslations('pages.deviceView');

  const params = useParams<{ id: string }>();
  const deviceId = parseInt(params.id ?? '', 10);

  const { serialType } = useUiSettings();
  const assetLabel = useAssetLabel();

  const deviceQuery = useGetDeviceBasic(deviceId).query;
  const assetQuery = useGetAssetBasicAssignedToDevice(deviceId).query;

  if (deviceQuery.isPending) return <LoadingPage />;

  const device = deviceQuery.data;
  const asset = assetQuery.data;

  if (!device || deviceQuery.isError) {
    return (
      <Page title={t('missingDevice')}>
        <Grid
          justifyContent="center"
          direction="column"
          container
          sx={{
            backgroundColor: 'common.lightGrey',
            flex: 1,
            overflowY: 'auto',
            py: 8
          }}
        >
          <Grid item>
            <Typography sx={theme => ({ margin: theme.spacing(0, 0, 2) })}>
              {t('missingDevice')}
            </Typography>
            <Button variant="contained" component={Link} to="/manage/devices">{t('returnToDeviceList')}</Button>
          </Grid>
        </Grid>
      </Page>
    );
  }

  const title = `${device[serialTypeKeys[serialType]] ?? ''}${asset ? ` (${assetLabel(asset)})` : ''}` || t('unnamed');

  return (
    <Page title={title}>
      <SettingsMenuPageWrapper>
        <Container maxWidth="md">
          <LinkAbove />

          <Typography variant="h1" gutterBottom>{title}</Typography>

          <DeviceGeneral device={device} />
          <DeviceActivation device={device} />
          <DeviceAsset deviceId={device.id} />
          <DeviceRates deviceId={device.id} />
          <DeviceConfiguration device={device} />
          <DeviceConfigurationHistory device={device} />

        </Container>
      </SettingsMenuPageWrapper>
    </Page>
  );
};

export default DevicePage;
