import { HttpResponseError, insightsFetch, serenityFetch } from 'helpers/api';
import { DateTime } from 'luxon';
import type {
  ActiveDaysResponse,
  ApiInsightsRequestBody,
  ApiInsightsRequestQuery,
  ApiInsightsResponse,
  Insight,
  InsightsRequest,
  InsightDimensions,
  InsightDimensionsRequest
} from './types';

export const getInsightsSummaryOld = async (organisationId: string, request: InsightsRequest): Promise<Insight> => {
  if (!request.from.isValid || !request.to.isValid) {
    throw new Error('Invalid date range');
  }

  const params = new URLSearchParams({
    from: request.from.toUTC().startOf('minute').toISO({ suppressSeconds: true }),
    to: request.to.toUTC().startOf('minute').toISO({ suppressSeconds: true }),
  } satisfies ApiInsightsRequestQuery);

  if (request.dimensions.length === 0 || request.metrics.length === 0) {
    throw new Error('Must provide at least one dimension and one metric');
  }

  const body: ApiInsightsRequestBody = {
    dimensions: request.dimensions,
    metrics: request.metrics,
    filter: request.filter,
    isStaff: request.isStaff,
  };

  const response = await serenityFetch('POST', `/${organisationId}/insights/summary?${params}`, body);
  HttpResponseError.detect(response);
  const { result } = (await response.json()) as ApiInsightsResponse;

  return {
    start: DateTime.fromISO(result.dateRange.start, { zone: 'utc' }),
    end: DateTime.fromISO(result.dateRange.end, { zone: 'utc' }),
    rows: result.rows,
  };
};

export const getInsightsSummary = async (organisationId: string, request: InsightsRequest): Promise<Insight> => {
  if (!request.from.isValid || !request.to.isValid) {
    throw new Error('Invalid date range');
  }

  const params = new URLSearchParams({
    from: request.from.toUTC().startOf('minute').toISO({ suppressSeconds: true }),
    to: request.to.toUTC().startOf('minute').toISO({ suppressSeconds: true }),
  } satisfies ApiInsightsRequestQuery);

  if (request.dimensions.length === 0 || request.metrics.length === 0) {
    throw new Error('Must provide at least one dimension and one metric');
  }

  const body: ApiInsightsRequestBody = {
    dimensions: request.dimensions,
    metrics: request.metrics,
    filter: request.filter,
    isStaff: request.isStaff,
  };

  const response = await serenityFetch('POST', `/${organisationId}/insights/summary?${params}`, body);
  HttpResponseError.detect(response);
  const { result } = (await response.json()) as ApiInsightsResponse;

  return {
    start: DateTime.fromISO(result.dateRange.start, { zone: 'utc' }),
    end: DateTime.fromISO(result.dateRange.end, { zone: 'utc' }),
    rows: result.rows,
  };
};

export const getInsightsDimensions = async (organisationId: string, request: InsightDimensionsRequest): Promise<InsightDimensions> => {
  if (!request.from.isValid || !request.to.isValid) {
    throw new Error('Invalid date range');
  }

  const params = new URLSearchParams({
    from: request.from.toUTC().startOf('minute').toISO({ suppressSeconds: true }),
    to: request.to.toUTC().startOf('minute').toISO({ suppressSeconds: true }),
  } satisfies ApiInsightsRequestQuery);

  if (request.dimensions.length === 0) {
    throw new Error('Must provide at least one dimension');
  }

  const body = {
    dimensions: request.dimensions,
  };

  const response = await serenityFetch('POST', `/${organisationId}/insights/dimensions?${params}`, body);
  HttpResponseError.detect(response);
  const { dimensionsAndValues } = (await response.json()) as InsightDimensions;

  return {
    dimensionsAndValues: dimensionsAndValues
  }
}


export const getActiveDays = async (
  organisationId: string,
  request: Pick<InsightsRequest, 'from' | 'to' | 'dimensions'>,
): Promise<ActiveDaysResponse> => {
  const params = new URLSearchParams({
    from: request.from.toUTC().startOf('minute').toISO({ suppressSeconds: true }),
    to: request.to.toUTC().startOf('minute').toISO({ suppressSeconds: true }),
  } satisfies ApiInsightsRequestQuery);

  const response = await insightsFetch('POST', `/summary/${organisationId}/activeDays?${params}`, {
    dimensions: request.dimensions,
  });
  HttpResponseError.detect(response);
  const result = (await response.json()) as ActiveDaysResponse;

  return result;
};
