import React, { useCallback, useMemo, useState } from 'react';
import { useTranslations } from 'use-intl';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';
import DateRangePicker from 'components/shared/dateRangePicker';
import type { ActivitySummaryDateRange } from './types';

interface ActivitySummaryDateRangeProps {
  dateRange: ActivitySummaryDateRange
  onChange: (dateRange: ActivitySummaryDateRange) => void
  zone: string
  maxRangeLength?: number
}

const ActivitySummaryDateRangeView = ({ dateRange, onChange, zone, maxRangeLength = 183 }: ActivitySummaryDateRangeProps) => {
  const t = useTranslations('pages.reporting');
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const setDateRange = useCallback((value: { start: string, end: string }) => {
    onChange({
      from: DateTime.fromISO(value.start, { zone }).toMillis(),
      until: DateTime.fromISO(value.end, { zone }).toMillis()
    });
  }, [onChange, zone]);

  const allowedDateRange = useMemo(() => ({
    end: DateTime.now().setZone(zone).toISODate()
  }), [zone]);

  let dateRangeText = DateTime.fromMillis(dateRange.from, { zone }).toFormat('d LLL y');
  if (dateRange.from !== dateRange.until) {
    dateRangeText = t('dateRange', {
      from: dateRangeText,
      until: DateTime.fromMillis(dateRange.until, { zone }).toFormat('d LLL y'),
    });
  }

  return (
    <>
      <TextField
        label={t('date')}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        onClick={() => setIsDatePickerOpen(true)}
        value={dateRangeText}
        inputProps={{ readOnly: true }}
        InputProps={{ sx: { height: '56px' } }}
        sx={{ width: '30ch' }}
      />
      <DateRangePicker
        open={isDatePickerOpen}
        setOpen={setIsDatePickerOpen}
        value={{
          start: DateTime.fromMillis(dateRange.from, { zone }).toISODate(),
          end: DateTime.fromMillis(dateRange.until, { zone }).toISODate(),
        }}
        onChange={setDateRange}
        allowedRange={allowedDateRange}
        maxRangeLength={maxRangeLength}
        renderTitle={({ pendingValue }) => {
          if (pendingValue.end && pendingValue.end !== pendingValue.start) {
            return t('dateRange', {
              from: DateTime.fromISO(pendingValue.start, { zone }).toFormat('d LLL y'),
              until: DateTime.fromISO(pendingValue.end, { zone }).toFormat('d LLL y'),
            });
          }
          if (pendingValue.start) {
            return DateTime.fromISO(pendingValue.start, { zone }).toFormat('d LLL y');
          }
          return null;
        }}
      />
    </>
  );
};

export default ActivitySummaryDateRangeView;
