import type { InsightDimensionKey, InsightMetricKey, InsightRow } from 'apis/rest/insights/types';
import { groupBy, uniq } from 'lodash/fp';
import type { ActivitySummaryMetricKey, ActivitySummaryRow } from './types';

export interface BaseAssetGroup {
  id: number;
  assets: { id: number }[];
}

const getDimensionsKey = (row: InsightRow) =>
  Object.entries(row.dimensions)
    .filter(([key]) => key !== 'assetId')
    .sort(([a], [b]) => (a > b ? 1 : -1))
    .toString();

const createAssetGroupRow = (rows: InsightRow[], assetGroup: BaseAssetGroup): ActivitySummaryRow => {
  const dimensions: ActivitySummaryRow['dimensions'] = {
    ...rows[0].dimensions,
    assetGroupId: assetGroup.id.toString(),
  };
  // biome-ignore lint/performance/noDelete: we want to completely remove the key
  delete dimensions.assetId;

  const metrics = rows.reduce<ActivitySummaryRow['metrics']>((acc, row) => {
    for (const [key, value] of Object.entries(row.metrics) as [ActivitySummaryMetricKey, number][]) {
      if (key in acc) {
        // biome-ignore lint/style/noNonNullAssertion: checked above
        acc[key]! += value;
      } else {
        acc[key] = value;
      }
    }

    return acc;
  }, {});

  const assetIds = uniq(rows.flatMap(row => row.assetIds));

  return {
    dimensions,
    metrics,
    assetIds
  } as ActivitySummaryRow;
};

export const transformAssetRowsToGroups = (rows: InsightRow[], assetGroups: BaseAssetGroup[]): InsightRow[] => {
  const rowsByAssetId = groupBy(row => row.dimensions.assetId, rows);

  return assetGroups.flatMap<InsightRow>(group => {
    const groupAssetIds = group.assets.map(asset => asset.id);
    const groupAssetRows = groupAssetIds.flatMap(id => rowsByAssetId[id] ?? []);
    const rowsByOtherDimensions = groupBy(getDimensionsKey, groupAssetRows);

    return Object.values(rowsByOtherDimensions).map(dimensionRows => createAssetGroupRow(dimensionRows, group));
  });
};

interface TotalItem {
  dimensions: PartialRecord<InsightDimensionKey, string>;
  metrics: PartialRecord<InsightMetricKey, number>;
}

export const transformRowsToTotals = (
  rows: InsightRow[],
  dimensionKeys: InsightDimensionKey[],
  metrics: InsightMetricKey[],
) =>
  rows.reduce<Record<string, TotalItem>>((acc, row) => {
    const id = dimensionKeys.map(key => row.dimensions[key]).join();

    if (!(id in acc)) {
      acc[id] = {
        dimensions: dimensionKeys.reduce<TotalItem['dimensions']>((dims, key) => {
          dims[key] = row.dimensions[key];
          return dims;
        }, {}),
        metrics: {},
      };
    }
    // biome-ignore lint/style/noNonNullAssertion: checked above
    const item = acc[id]!;

    for (const metric of metrics) {
      if (!(metric in item.metrics)) item.metrics[metric] = 0;
      if (metric === 'activityCount') continue;
      // biome-ignore lint/style/noNonNullAssertion: checked above
      item.metrics[metric]! += row.metrics[metric] ?? 0;
    }

    return acc;
  }, {});
