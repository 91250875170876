import { Button, FormControl, Paper, Stack, TextField, Tooltip } from '@mui/material';
import {
  useCreateBaseMutator,
  useDeleteBaseMutator,
  useUpdateBaseAssignmentsMutator,
  useUpdateBaseMutator,
} from 'apis/rest/bases/bases-hooks';
import type { Base, BaseAssetAssignment } from 'apis/rest/bases/bases-types';
import type { BasesOutletContextValue } from 'components/pages/manage/bases/bases-view';
import { BasesAssetList } from 'components/pages/manage/bases/components/bases-asset-list';
import GeofenceBlocker from 'components/pages/manage/geofences/create/GeofenceBlocker';
import { LatitudeInput, LongitudeInput } from 'components/shared/CoordinatesInput';
import RemoveMapListEntry from 'components/shared/manageMapList/removeEntry';
import ReturnToListButton from 'components/shared/manageMapList/returnToListButton';
import { useUnitSettings } from 'hooks/settings/useUnitSettings';
import useSnackbar from 'hooks/useSnackbar';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router';
import { useNavigate } from 'react-router';
import { type BasesFormData, manageBasesSlice } from 'slices/manageBases.slice';
import { useAppDispatch } from 'store/useAppDispatch';
import { useTranslations } from 'use-intl';
import { v4 as uuid } from 'uuid';

const { actions, selectors } = manageBasesSlice;

export const BasesProperties = () => {
  const t = useTranslations('pages.manage.bases.properties');
  const dispatch = useAppDispatch();
  const context = useOutletContext<BasesOutletContextValue>();
  const baseFormData = useSelector(selectors.selectFormData);
  const baseFormChanged = useSelector(selectors.selectHasChanges) ?? false;
  const { coordinate } = useUnitSettings();

  baseFormData.coordinates.latitude;

  const updateMutation = useUpdateBaseMutator();
  const createMutation = useCreateBaseMutator();
  const deleteMutation = useDeleteBaseMutator();
  const updateAssignmentsMutation = useUpdateBaseAssignmentsMutator();

  const snackbar = useSnackbar();

  const navigate = useNavigate();

  const coordFormat = () => {
    switch (coordinate) {
      case 'coordinatesDMS':
        return 'dms';
      case 'coordinatesDDM':
        return 'dm';
      default:
        return 'n';
    }
  };

  // on component load - find the ID from the url and load the form for that base, or if it is under the create URL, then blank form
  useEffect(() => {
    if (!context.selectedBase) {
      dispatch(actions.createBase());
    } else {
      dispatch(actions.editBase(context.selectedBase));
    }
  }, [dispatch, context.selectedBase]);

  const handleOnSubmit = useCallback(
    (baseForm: BasesFormData) => {
      const base: Base = {
        id: baseForm.id,
        name: baseForm.name,
        description: baseForm.description,
        colour: '#000000',
        icon: 'house',
        longitude: baseForm.coordinates.longitude ?? 0,
        latitude: baseForm.coordinates.latitude ?? 0,
        assignments: baseForm.assignments,
        notes: baseForm.notes,
      };
      dispatch(actions.updateInitialForm());

      if (!context.selectedBase) {
        createMutation.mutate(
          { ...base, id: uuid() },
          {
            onSuccess: () => {
              snackbar.display({
                type: 'success',
                text: t('snackbar.createSuccess', { name: base.name }),
                id: `base.create.${base.id}`,
              });
              navigate('..');
            },
          },
        );
      } else {
        updateMutation.mutate(base, {
          onSuccess: () => {
            snackbar.display({
              type: 'success',
              text: t('snackbar.updateSuccess', { name: base.name }),
              id: `base.update.${base.id}`,
            });
            navigate('..');
          },
        });
      }
    },
    [updateMutation, createMutation, snackbar, navigate, context.selectedBase, t, dispatch],
  );

  const handleOnDelete = useCallback(
    (baseId: string) => {
      navigate('..');
      deleteMutation.mutate(baseId, {
        onSuccess: () => {
          snackbar.display({
            type: 'success',
            text: t('snackbar.deleteSuccess', { name: context.selectedBase?.name }),
            id: `base.delete.${baseId}`,
          });
        },
        onError: () => {
          snackbar.display({
            type: 'error',
            text: t('snackbar.deleteError', { name: context.selectedBase?.name }),
            id: `base.failed.delete.${baseId}`,
          });
        },
      });
    },
    [navigate, context, deleteMutation, snackbar, t],
  );

  const handleUpdateAssignments = useCallback(
    (assignments: BaseAssetAssignment[]) => {
      if (context?.selectedBase) {
        updateAssignmentsMutation.mutate(
          {
            baseId: context.selectedBase.id,
            assignments: assignments,
          },
          {
            onSuccess: () => {
              snackbar.display({
                type: 'success',
                text: t('snackbar.updateAssignmentSuccess', { name: context.selectedBase?.name }),
                id: `base.delete.${context.selectedBase?.id}`,
              });
            },
            onError: () => {
              snackbar.display({
                type: 'error',
                text: t('snackbar.updateAssignmentError', { name: context.selectedBase?.name }),
                id: `base.failed.delete.${context.selectedBase?.id}`,
              });
            },
          },
        );
      }
    },
    [context, snackbar, t, updateAssignmentsMutation.mutate],
  );

  const mutationPending = deleteMutation.isPending || updateMutation.isPending || createMutation.isPending;

  const validations = useSelector(selectors.selectValidations);

  const validationSummary = useMemo(
    () =>
      [
        !validations.name ? t('validationMessages.name') : undefined,
        !validations.latitude ? t('validationMessages.latitude') : undefined,
        !validations.longitude ? t('validationMessages.longitude') : undefined,
      ].filter(x => x),
    [validations, t],
  );

  return (
    <Stack direction="column" spacing={3}>
      <Paper elevation={0}>
        <ReturnToListButton label={t('allBases')} />
      </Paper>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <TextField
              label={t('fields.name')}
              variant="outlined"
              onChange={event => dispatch(actions.setBaseName(event.target.value))}
              value={baseFormData.name ?? ''}
              inputProps={{ maxLength: 100 }}
              required
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label={t('fields.description')}
              variant="outlined"
              onChange={event => dispatch(actions.setBaseDescription(event.target.value))}
              value={baseFormData.description ?? ''}
              inputProps={{ maxLength: 2000 }}
              multiline
              maxRows={5}
            />
          </FormControl>
          <Stack direction="row" spacing={2}>
            <FormControl>
              <LatitudeInput
                label={t('fields.latitude')}
                value={baseFormData.coordinates?.latitude}
                onChangeValue={value => dispatch(actions.setBaseLatitude(value))}
                displayFormat={coordFormat()}
              />
            </FormControl>
            <FormControl>
              <LongitudeInput
                label={t('fields.longitude')}
                value={baseFormData.coordinates?.longitude}
                onChangeValue={value => dispatch(actions.setBaseLongitude(value))}
                displayFormat={coordFormat()}
              />
            </FormControl>
            {/* TODO: Add "click on map to place base" functionality */}
          </Stack>
          <FormControl fullWidth>
            <TextField
              label={t('fields.notes')}
              variant="outlined"
              onChange={event => dispatch(actions.setBaseNotes(event.target.value))}
              value={baseFormData.notes.at(0)?.note ?? ''}
              inputProps={{ maxLength: 2000 }}
              multiline={true}
              maxRows={5}
              minRows={3}
            />
          </FormControl>
          <Stack direction="row" spacing={3} sx={{ justifyContent: 'flex-end' }}>
            <Tooltip
              placement="top"
              title={
                validations.all || mutationPending
                  ? ''
                  : validationSummary.map((x, i) => <div key={`bases-validation-${i}`}>{x}</div>)
              }
            >
              <span>
                <Button
                  size="large"
                  variant="contained"
                  sx={{ minWidth: '10rem', height: '4rem' }}
                  onClick={() => handleOnSubmit(baseFormData)}
                  disabled={!validations.all || mutationPending || !baseFormChanged}
                >
                  {t('fields.save')}
                </Button>
              </span>
            </Tooltip>
          </Stack>
        </Stack>
      </Paper>
      {context.selectedBase && (
        <RemoveMapListEntry isLoading={false} onDelete={() => handleOnDelete(baseFormData.id)} entryType={t('base')} />
      )}
      <BasesAssetList onSubmit={handleUpdateAssignments} />
      <GeofenceBlocker enabled={baseFormChanged} />
    </Stack>
  );
};
