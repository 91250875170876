import { alpha } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  materialTable: {
    '& .MuiTableSortLabel-icon': {
      fontSize: '18px'
    },
    '& input.MuiInputBase-input': {
      // NOTE: this is an ugly workaround because there isn't a more specific selector for the search input
      padding: theme.spacing(1.8, 0),
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.border.color
    },
    '& > div': {
      boxShadow: 'none',
    },
    '& .MuiToolbar-regular': {
      marginTop: '8px',
      paddingRight: '20px'
    },
    '& .MuiInput-formControl': {
      border: theme.border.default
    },
    '& .MuiInput-formControl::before, & .MuiInput-formControl::after': {
      display: 'none'
    },
    '& table td, & table th': {
      borderBottom: theme.border.default
    },
    '& table tr:last-of-type td': {
      borderBottom: 'none'
    },
    '& .MuiTableRow-root': {
      fontSize: '0.95rem'
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(8 / 3),
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiTableCell-root.MuiTableCell-paddingNone.MuiTableCell-body': {
      color: 'rgba(0, 0, 0, 0.54)'
    }
  },
  inputButton: {
    padding: theme.spacing(2, 6),
    fontSize: '1rem',
    textTransform: 'capitalize',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: 'none',
    transition: 'all 0.15s',
    '&:hover': {
      backgroundColor: theme.palette.primary.darkBlue
    },
    '&:focus': {
      backgroundColor: theme.palette.primary.darkBlue,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`
    }
  }
}));
