import { serenityFetch } from "helpers/api";

export const organisationsBeaconSeatsGet = async (organisationId: string): Promise<number> => {
  const url = `/organisations/${organisationId}/beacon/seats`
  const response = await serenityFetch("GET", url, null)
  return (await response.json()) as number
}

export const organisationsBeaconSeatsAssignedGet = async (organisationId: string): Promise<string[]> => {
  const url = `/organisations/${organisationId}/beacon/seats/assigned`
  const response = await serenityFetch("GET", url, null)
  return (await response.json()) as string[]
}

export const organisationsBeaconSeatsPut = async (organisationId: string, numSeats: number) => {
  const url = `/organisations/${organisationId}/beacon/seats`
  if (!numSeats) throw new Error("The parameter 'numSeats' must be defined.")
  return await serenityFetch("PUT", url, numSeats)
}

export const organisationsBeaconSeatsAssignedDelete = async (organisationId: string, userIds: string[]) => {
  const url = `/organisations/${organisationId}/beacon/seats/assigned`
  return await serenityFetch("DELETE", url, userIds)
}

export const organisationsBeaconSeatsAssignedPost = async (organisationId: string, userIds: string[]) => {
  const url = `/organisations/${organisationId}/beacon/seats/assigned`
  return await serenityFetch("POST", url, userIds)
}