import { Box, Stack, SvgIcon, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { notUndefined, useVirtualizer } from '@tanstack/react-virtual';
import React, { type FC, type ReactNode, useEffect, useRef } from 'react';
import { type AnalysisBoxColumn, SortDirection } from './types';

interface SortIndicatorProps {
  sortDirection: SortDirection;
}

const SortIndicator: FC<SortIndicatorProps> = ({ sortDirection }) => (
  <SvgIcon
    sx={{
      transform: sortDirection === SortDirection.DESC ? 'rotate(0deg)' : 'rotate(180deg)',
    }}
    width={18}
    height={18}
    viewBox="0 0 24 24"
    focusable={false}
    aria-hidden={true}
  >
    <path
      d="M 20 12 l -1.41 -1.41 L 13 16.17 V 4 h -2 v 12.17 l -5.58 -5.59 L 4 12 l 8 8 l 8 -8 Z"
      fill="currentColor"
    />
  </SvgIcon>
);

interface ColumnHeaderProps {
  dataKey: string;
  label: ReactNode;
  sortBy: string;
  sortDirection: SortDirection;
}

const ColumnHeader: FC<ColumnHeaderProps> = ({ dataKey, label, sortBy, sortDirection }) => {
  const showSortIndicator = sortBy === dataKey;

  return (
    <Stack direction="row" spacing={0.5}>
      <Typography title={typeof label === 'string' ? label : undefined} fontWeight="bold">
        {label}
      </Typography>
      {showSortIndicator && <SortIndicator sortDirection={sortDirection} />}
    </Stack>
  );
};

interface AnalysisboxTableProps {
  sort: { sortBy: string; sortDirection: SortDirection };
  onSort: (sortBy: string) => void;
  selectedReportId?: number;
  rows: AnalysisBoxReport[];
  columns: Readonly<[keyof AnalysisBoxReport, AnalysisBoxColumn]>[];
  onRowClick: (row: AnalysisBoxReport) => void;
  onRowMouseOver: (row: AnalysisBoxReport) => void;
}

const AnalysisBoxTable: FC<AnalysisboxTableProps> = ({
  onRowClick,
  onRowMouseOver,
  sort,
  onSort,
  columns,
  selectedReportId,
  rows,
}) => {
  const tableRef = useRef(null);

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => tableRef.current,
    estimateSize: () => 40,
    overscan: 10,
  });

  const mouseInRef = useRef<boolean>(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Set selected row ID on change of selected report
  useEffect(() => {
    if (selectedReportId === undefined || mouseInRef.current) return;

    virtualizer.scrollToIndex(
      rows.findIndex(r => r.id === selectedReportId),
      { align: 'auto' },
    );
  }, [selectedReportId]);

  const virtualRows = virtualizer.getVirtualItems();
  const [before, after] =
    virtualRows.length > 0
      ? [
          notUndefined(virtualRows[0]).start - virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() - notUndefined(virtualRows[virtualRows.length - 1]).end,
        ]
      : [0, 0];

  return (
    <Box
      ref={tableRef}
      sx={{
        position: 'relative',
        overflow: 'auto',
        flex: '1 1 auto',
        gridColumn: '1 / 5',
        bgcolor: 'background.paper',
        border: '1px solid',
        borderColor: 'common.midGrey',
        borderRadius: 1,
      }}
      onMouseEnter={() => {
        mouseInRef.current = true;
      }}
      onMouseLeave={() => {
        mouseInRef.current = false;
      }}
    >
      <div style={{ height: '100%' }}>
        <Table size="small" sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }}>
          <TableHead
            sx={{
              th: {
                position: 'sticky',
                top: 0,
                bgcolor: 'common.white',
                borderBottom: '1px solid',
                borderColor: 'common.midGrey',
              },
            }}
          >
            <TableRow style={{ height: 40 }}>
              {columns.map(([key, field]) => (
                <TableCell
                  key={key}
                  style={{ width: `${field.width()}px` }}
                  sx={{ width: field.width() }}
                  onClick={() => onSort(field.dataKey)}
                >
                  <ColumnHeader
                    dataKey={field.dataKey}
                    label={field.label}
                    sortBy={sort.sortBy}
                    sortDirection={sort.sortDirection}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {before > 0 && (
              <tr>
                <td colSpan={columns.length} style={{ height: before }} />
              </tr>
            )}
            {virtualRows.map((virtualRow, index) => {
              const row = rows[virtualRow.index];
              return (
                <TableRow
                  key={virtualRow.index}
                  sx={{
                    height: `${virtualRow.size}px`,
                    cursor: 'pointer',
                    bgcolor: row.id === selectedReportId ? 'common.midGrey' : undefined,
                  }}
                  onClick={() => onRowClick(row)}
                  onMouseOver={() => onRowMouseOver(row)}
                >
                  {columns.map(([key, field]) => (
                    <TableCell key={key} sx={{ whiteSpace: 'nowrap' }}>
                      {field.render(row)}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
            {after > 0 && (
              <tr>
                <td colSpan={columns.length} style={{ height: after }} />
              </tr>
            )}
          </TableBody>
        </Table>
      </div>
    </Box>
  );
};

export default AnalysisBoxTable;
